.dropdown-native {
  appearance: none;
  border: none;
  background: none;
  outline: none;
  padding: 8px;
  width: 100%;
  color: white;
}

.dropdown-native::-ms-expand {
  display: none;
}

.dropdown-native:focus {
  outline: none;
}

.field-cont {
  cursor: pointer;
}

.dropdown-list {
  background-color: #64596b;
  border: 1px solid #fff;
  border-radius: 4px;
  padding: 12px;
  color: white;
  width: 200px;
  max-height: 200px;
  overflow-y: auto;
  list-style: none;
  border-radius: 12px;
}

.dropdown-button:focus {
  outline: none;
}

.dropdown-button:hover {
  color: #fff;
}

.profile .custom_select {
  background-color: unset;
}

.profile.rdrop_down_wrapper {
  margin-bottom: 0px;
  margin-top: 2px;
}

.profile.rdrop_down_wrapper .custom_select {
  height: 28px;
}

.profile.rdrop_down_wrapper .custom_select select {
  color: #fff;
  height: 28px;
}

.profile.rdrop_down_wrapper .custom_select::after {
  border-top: none;
}

.select-label {
  margin-top: 12px;
}

.team-cont {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #41c0d3;
  border-radius: 30px;
  padding: 30px;
  color: #fff;
}

.team-heading {
  font-family: "futura-pt-medium";
  font-size: 25px;
}

.team-body {
  display: flex;
  flex-direction: column;
  width: 76%;
  margin-right: 1em;
}

.team-text {
  font-family: "futura-pt-book";
  font-size: 22px;
}

.team-slider {
  display: contents;
}

.team-switch {
  display: inline-block;
  position: relative;
  width: 200px;
  height: 36px;
  background-color: #000;
  border-radius: 40px;
  transition: background-color 0.4s ease;
  cursor: pointer;
  border: solid 4px #000;
}

/* .ind-tracker .team-switch.on {
    background-color: #5ec091;
    border: solid 2px #5ec091;
  } */

.team-switch.off .team-slider-button {
  transform: translateX(0);
}

.team-switch.on .team-slider-button {
  transform: translateX(129px);
}

.team-slider-button {
  position: absolute;
  width: 36px;
  height: 36px;
  background-color: #f1ebf4;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s;
}

.team-label {
  font-size: 15px;
  text-align: center;
  text-transform: uppercase;
  line-height: 36px;
  color: #f1ebf4;
  font-family: "futura-pt-medium";
}

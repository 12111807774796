.tracker-cont {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.water-drop {
  height: 300px;
}

.water {
  display: flex;
  flex-direction: column;
}

.water.minus {
  align-items: start;
}

.water.plus {
  align-items: end;
}

.water .button {
  width: 25px;
  height: 25px;
  line-height: 25px;
  border-radius: 50%;
  background-color: #d4ccd9;
  color: #fff;
  padding: 22.5px;
  cursor: pointer;
}

.water .button svg.svg-inline--fa {
  height: 28px;
  width: 28px;
  vertical-align: 0;
}

.water .value {
  margin: 30px 0 10px 0;
  font-family: "futura-pt-demi";
  font-size: 30px;
}

.water .measurment {
  font-family: "futura-pt-book";
  font-size: 22.5px;
}

.glass-cont {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 40px;
}
.glass-cont .glass {
  height: 45px;
}

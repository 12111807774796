.profile-photo {
  position: relative;
  width: 140px;
  height: 140px;
}
.profile-photo #preview {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  object-fit: cover; /* maintain aspect ratio */
  overflow: hidden;
}

.menu-left .profile-footer {
  margin-top: 16px;
  line-height: 20px;
  color: #ddcedb;
  font-size: 14px;
  font-family: "futura-pt-medium";
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.menu-left .profile-footer a {
  color: #ddcedb;
}

.menu-left .privacy {
  display: flex;
  padding: 2px 4px;
  width: fit-content;
}

.menu-left button.privacy:focus {
  outline: var(--dark-outline);
}

.profile-btn,
.profile-btn:hover {
  height: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  color: #fff !important;
  padding: 16px 12px !important;
  margin-bottom: 4px;
  cursor: pointer;
  width: fit-content;
}

.profile-btn:hover {
  background-color: transparent;
  box-shadow: none;
}

.profile-btn:focus {
  outline: var(--dark-outline);
  outline-offset: 2px;
}

.profile-btn .smaller-icon {
  height: 20px;
  margin-right: 22px;
}

.profile-action-btn-container {
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
  margin-top: 12px;
}

.tracker-heading .popup {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tracker-heading .popup .popup-heading {
  font-family: "futura-pt-medium";
  font-size: 25px;
  text-transform: none;
  letter-spacing: normal;
  padding: 0 0 10px 0;
}

.tracker-heading .popup .popup-text {
  font-family: "futura-pt-book";
  font-size: 22px;
  text-transform: none;
  letter-spacing: normal;
  padding: 0;
}

.tracker-heading .popup .btn-cont {
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.tracker-heading .popup .btn-cont .darkButton.submit {
  margin-bottom: 0;
}

.question_container .msk_container {
    text-align:center;
}

.question_container #msk_bg {
    -webkit-transition: 0.8s;
    -moz-transition: 0.8s;
    -o-transition: 0.8s;
    transition: 0.8s;  
    opacity:1;    
}

.question_container .msk_body {
    width: 250px;
    height:500px;
    background-size:contain;
    background-repeat: no-repeat;
    background-position: left;
    position:relative;
    display:inline-block;  
    vertical-align: top; 
}
.question_container .msk_options{
    display:inline-block;
    width:120px;
    text-align:left;
    vertical-align:top;  
}
.question_container .msk_body.male.front {
    background-image: url('./images/male-front.svg');
}
.question_container .msk_body.male.back {
    background-image: url('./images/male-back.svg');
}
.question_container .msk_body.female.front {
    background-image: url('./images/female-front.svg');
}
.question_container .msk_body.female.back {
    background-image: url('./images/female-back.svg');
}
.question_container .msk_body.neutral.front {
    background-image: url('./images/neutral-front.svg');
}
.question_container .msk_body.neutral.back {
    background-image: url('./images/neutral-back.svg');
}
.msk_body .body_marker {
    position:absolute;
    width: 12px;
    height: 12px;
    background-color: #aaa2af;
    border-radius: 50%;
    cursor:pointer;
    transition: all 0.5s ease;
}
.msk_body .body_marker.active {
    background-color: #E92263;
    width: 14px;
    height: 14px;
    margin-top:-1px;
    margin-left:-1px;
}

.question_container .msk_container .checkbox {
    background-color:inherit;
}
.question_container .msk_container button {
    margin-top:20px;
}
.question_container .msk_container .checkbox input[type="checkbox"]:checked+label{
    color: #281d2d;
}
.question_container .msk_container .checkbox input[type="checkbox"]+label .icon{
    color: #ccc;
}
.question_container .msk_container .checkbox input[type="checkbox"]:checked+label .icon{
    color: #E92263;
}
.msk_qbox h3{
    padding: 20px;
    border-radius: 15px;
    background-color: #f6f5f7;
    display:block;
    color: #7e658c;
    ;
}
.tracker-challenge {
  background-color: #41c0d3;
  border-radius: 30px;
  padding: 30px;
  color: #fff;
}

.tracker-challenge-heading {
  font-family: "futura-pt-medium";
  font-size: 22.5px;
}

.tracker-challenge-body {
  font-family: "futura-pt-book";
  font-size: 20px;
  margin-top: 15px;
}

:root {
  --textOnImageBackground: rgba(40, 29, 45, 0.85);
}

.promoContent {
  padding: 36px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  position: relative;
}

.promoContent_fade {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
}

.promoContent_companyLogo {
  height: 33px;
  width: fit-content;
  position: absolute;
}

.promoContent_outerLabelContainer {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 3.75px;
  padding: 3px;
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
}

.promoContent_labelContainer {
  margin-top: 77px;
  margin-bottom: 18px;
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 450;
  line-height: 25px;
  letter-spacing: 1.125px;
  text-transform: uppercase;
  border-radius: 3.75px;
  padding: 10px 25px;
  position: relative;
  background: var(--textOnImageBackground);
  border: none;
  z-index: 1;
  width: fit-content;
  word-break: break-word;
}

.promoContent_titleContainer {
  font-size: 30px;
  max-width: 260px;
  line-height: 38px;
  font-family: "futura-pt-medium";
  background: var(--textOnImageBackground);
  border-radius: 3.75px;
  padding: 10px 25px;
  z-index: 1;
  word-break: break-word;
}

.promoContent_subtitle {
  font-size: 18px;
  font-family: "futura-pt-medium";
  margin-top: 20px;
  margin-right: 4px;
}

.promoContent_subtitleContainer {
  display: flex;
  align-items: flex-end;
}

.promoContent_subtitleContainer .svg-inline--fa {
  height: 18px;
}
@media (max-width: 1700px) {
  .promoContent_labelContainer {
    margin-top: 50px;
    margin-bottom: 14px;
    font-size: 18px;
    padding: 8px 20px;
  }
  .promoContent_titleContainer {
    font-size: 26px;
    max-width: 180px;
    line-height: normal;
  }
  .promoContent_subtitle {
    font-size: 16px;
    margin-top: 20px;
    margin-right: 4px;
  }
  .promoContent_subtitleContainer .svg-inline--fa {
    height: 16px;
  }
}

@media (max-width: 1200px) {
  .promoContent {
    padding: 26px;
  }
  .promoContent_labelContainer {
    margin-bottom: 14px;
    font-size: 12px;
    padding: 4px 15px;
  }
  .promoContent_titleContainer {
    font-size: 20px;
    max-width: 160px;
  }
  .promoContent_subtitle {
    font-size: 14px;
  }
  .promoContent_subtitleContainer .svg-inline--fa {
    height: 14px;
  }
}

@media (max-width: 1100px) {
  .promoContent_labelContainer {
    margin-top: 40px;
    font-size: 10px;
  }
  .promoContent_titleContainer {
    font-size: 18px;
    max-width: 140px;
  }
  .promoContent_subtitle {
    font-size: 12px;
  }
  .promoContent_subtitleContainer .svg-inline--fa {
    height: 12px;
  }
}

.dialSlider {
  margin: 40px 0;
  position: relative;
  display: block;
  max-width: 400px;
}

.dialSlider._wide.center {
  margin-left: auto;
  margin-right: auto;
}

.dialSlider .noUi-handle {
  background: url("./images/fullSliderHandle.svg") no-repeat;
  background-size: contain;
  width: 36px;
  height: 40px;
  background-color: transparent;
  border: none;
  margin-top: -12px;
  box-shadow: none;
  outline: none;
  cursor: pointer;
}
.dialSlider .noUi-connects {
  cursor: pointer;
}
.dialSlider .noUi-handle::before,
.dialSlider .noUi-handle::after {
  display: none;
}
.dialSlider .noUi-connect {
  background-color: #e92263;
}
.dialSlider .noUi-horizontal {
  height: 6px;
  margin: 40px 0;
}
.center .dialSlider .noUi-horizontal {
  margin: 40px auto;
}
.dialSlider .noUi-target {
  background-color: #a9a3ad;
  box-shadow: none;
  border: none;
}

.dialSlider .dial {
  background: url("./images/dial-min.png") center bottom no-repeat;
  height: 200px;
  background-size: contain;
  position: relative;
  margin: 0 auto;
}
.dialSlider .needle {
  background: url("./images/needle.svg") no-repeat;
  width: 30px;
  height: 220px;
  background-size: contain;
  position: absolute;
  bottom: -100px;
  left: 50%;
  margin-left: -10px;
  transform: rotate(-90deg);
  transition: transform 0.5s ease;
  -webkit-transition: -webkit-transform 0.5s ease;
}

.dialSlider .valueHandle {
  color: #e92263;
  text-align: center;
  font-family: "futura-pt-medium";
  font-weight: 700;
  font-size: 30px;
  transition: all 0.5s ease;
  position: absolute;
  width: 100%;
}

.dialSlider #stress_at_moment_value_handle.valueHandle {
  font-weight: 500;
  font-size: 18px;
}

.dialSlider .sliderValues {
  color: #e92263;
  font-family: "futura-pt-medium";
  font-weight: 500;
  font-size: 18px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  align-items: flex-end;
}

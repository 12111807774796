.boardList {
  height: 242px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

.boardList-wrapper {
  position: relative;
  width: 50%;
  margin-top: 47px;
}

.boardList-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.boardList-user {
  display: flex;
  background-color: #64596b;
  border-radius: 40px;
  height: 40px;
  width: 100%;
  margin-top: 5px;
  align-items: center;
  justify-content: space-between;
}

.boardList-user-empty {
  margin-left: 41px;
}

.boardList-user.boardList-user-active {
  background-color: #a76d92;
}

.boardList-user-1,
.boardList-user-2 {
  display: flex;
  align-items: center;
}

.boardList-user-2 {
  font-size: 12px;
  font-weight: 450;
  line-height: 30px;
}

.boardList-number {
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  margin-right: 10px;
  width: 36px;
  text-align: right;
}

.boardList::-webkit-scrollbar {
  width: 4px;
}

.boardList::-webkit-scrollbar-track {
  border-radius: 2.5px;
  width: 4px;
  background: transparent;
}

.boardList::-webkit-scrollbar-thumb {
  width: 4px;
  border-radius: 2.5px;
  background: #291e2e;
}

.boardList-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #403546;
  margin-right: 10px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}

.boardList-name {
  font-size: 16px;
  font-weight: 450;
  line-height: 30px;
  text-align: left;
}

.boardList-progress-unit {
  margin-left: 4px;
  margin-right: 20px;
}
.boardList-overlay-top {
  position: absolute;
  top: 0;
  width: 100%;
  height: 34px;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 0%,
    rgba(40, 29, 45, 1) 100%
  );
}

.boardList-overlay-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 34px;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(40, 29, 45, 1) 100%
  );
}

.boardList-row:last-child {
  margin-bottom: 30px;
}

.boardList-row-leader:last-child {
  margin-bottom: 0;
}

.boardList-row:first-child {
  margin-top: 16px;
}

.boardList-dots {
  text-align: center;
  font-size: 26px;
  font-weight: 500;
  line-height: 30px;
  margin-top: -6px;
  margin-bottom: 4px;
  margin-left: 46px;
  opacity: 0.5;
}

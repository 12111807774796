#masterclass-container {
  background-color: #281d2d;
  padding: 160px 80px 150px 85px;
  display: flex;
  flex-direction: row;
}

#masterclass-container .back-button {
  z-index: 5;
}
#masterclass-container .column-1 {
  margin-right: 15px;
  flex-grow: 1;
}
/* mcplayer */
#masterclass-container .meadia-cont .mcplayer {
  width: 100%;
  position: relative;
}

#masterclass-container .mcplayer video {
  display: block;
  width: 100%;
  align-self: center;
}

#masterclass-container .mcplayer .controls {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-image: linear-gradient(
    to top,
    rgb(41, 30, 46, 0.8) 20%,
    rgba(41, 30, 46, 0)
  );
  box-sizing: border-box;
  color: #fff;
  padding: 0 75px 45px 75px;
}

#masterclass-container .controls .buttons {
  display: flex;
  flex-direction: column;
}

#masterclass-container .controls .buttons .top-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#masterclass-container .controls .buttons .top-row .captions {
  width: 174px;
  display: flex;
}

#masterclass-container .mcplayer .controls .play {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-content: center;
}

/* #masterclass-container .player .controls .volume {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
} */
#masterclass-container .mcplayer .controls h3 {
  font-family: "futura-pt-demi";
  font-size: 22.5px;
  margin-bottom: 0;
}

#masterclass-container .mcplayer .controls p {
  font-family: "futura-pt-book";
  font-size: 12px;
}
#masterclass-container .mcplayer .controls button {
  background-color: transparent;
  box-shadow: none;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 40px;
  padding: 0;
  margin-bottom: 0;
}

#masterclass-container .mcplayer .controls button .icon {
  margin: 0;
  transition: none;
}

#masterclass-container .mcplayer .controls button.play-pause {
  font-size: 80px;
  margin: 0 10px 0 10px;
}

#masterclass-container svg.svg-inline--fa.fa-cirlce-play,
#masterclass-container svg.svg-inline--fa.fa-cirlce-pause {
  vertical-align: text-top;
}

#masterclass-container .mcplayer .controls button:hover {
  color: #e92263;
}

/* #masterclass-container .mcplayer .controls .volume-container {
  position: relative;
  display: flex;
  flex-direction: row;
} */

#masterclass-container .mcplayer .controls .progress {
  display: flex;
  align-items: center;
  width: 100%;
}

#masterclass-container .mcplayer .controls .progress progress[value] {
  appearance: none;
  border: none;
  width: 100%;
  height: 6px;
  background-color: rgba(255, 255, 255, 0.16);
  border-radius: 3px;
  box-shadow: 0 0.5px 1px rgba(0, 0, 0, 0.5) inset;
  color: #e92263;
  position: relative;
}

#masterclass-container progress[value]::-webkit-progress-bar {
  background-color: rgba(255, 255, 255, 0.16);
  border-radius: 3px;
  box-shadow: 0 0.5px 1px rgba(0, 0, 0, 0.5) inset;
}

#masterclass-container progress[value]::-webkit-progress-value {
  position: relative;
  border-radius: 3px;
  background-color: #e92263;
}

#masterclass-container progress[value]::-moz-progress-bar {
  background-color: rgba(255, 255, 255, 0.16);
  border-radius: 3px;
  box-shadow: 0 0.5px 1px rgba(0, 0, 0, 0.5) inset;
}

#masterclass-container progress[value]::-moz-progress-bar {
  position: relative;
  border-radius: 3px;
  background-color: #e92263;
}

#masterclass-container .mcplayer .controls .progress .time-current,
#masterclass-container .mcplayer .controls .progress .time-total {
  color: #fff;
  font-size: 12px;
  margin: 0 5px;
}

/* #masterclass-container .mcplayer .controls .volume-bar {
  visibility: hidden;
  width: 80px;
  margin-right: 10px;
  align-self: center;
} */

/* #masterclass-container .mcplayer .controls .volume-container:hover .volume-bar {
  display: block;
  visibility: visible;
}

#masterclass-container .mcplayer .controls .volume-bar input[type="range"] {
  width: 100%;
  height: 10px;
  -webkit-appearance: none;
  background-color: #555;
  border-radius: 6px;
  outline: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
} */

/* #masterclass-container
  .mcplayer
  .controls
  .volume-bar
  input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 10px;
  height: 10px;
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
} */

#masterclass-container .mcplayer .controls .fullscreen-player {
  margin-left: 20px;
}

#masterclass-container .mcplayer .controls .fullscreen-player:hover {
  color: #e92263;
}

#masterclass-container .mcplayer.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 998;
}

/* chapters */

#masterclass-container .chapters-popup .chapter {
  /* width: 95%; */
}

#masterclass-container .chapters-popup .chapter-progress-container {
  /* width: 99.5%; */
}

#masterclass-container .chapters-popup .scrollable {
  height: 770px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#masterclass-container .chapters-popup {
  padding-top: 0;
  position: relative;
  bottom: 0;
  left: 0;
}

#masterclass-container .below-video {
  flex-direction: row;
  display: flex;
}

/* description */
#masterclass-container .description {
  color: #fff;
  display: flex;
  flex-direction: column;
}
#masterclass-container .description > div {
  margin-bottom: 35px;
}

#masterclass-container .description .title {
  font-family: "futura-pt-demi";
  font-size: 40px;
}

#masterclass-container .description .tag-line {
  width: fit-content;
  display: flex;
  flex-direction: row;
  background-color: #64596b;
  padding: 10px 28px 10px 20px;
  font-family: "futura-pt-medium";
  font-size: 20px;
  border-radius: 26px;
  justify-content: space-between;
  align-items: center;
}

#masterclass-container .description .tag-line > div {
  margin: 0 20px;
}

#masterclass-container .description .tag-line > svg {
  width: 25px;
  height: 25px;
  margin: 0 15px;
}

#masterclass-container .description .tag-line div:first-child {
  text-transform: uppercase;
}

#masterclass-container .description .text {
  font-family: "futura-pt-book";
  color: #ddcedb;
  font-size: 22.5px;
}

/* buttons */
#masterclass-container .below-video .button-container {
  margin: 30px 0 0 30px;
}

#masterclass-container .below-video .button-container button {
  border-radius: 12px;
  width: 320px;
  height: 116px;
  font-family: "futura-pt-demi";
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

#masterclass-container .below-video .button-container button svg {
  height: 50px;
  width: 50px;
  margin-right: 15px;
}

/* chapters */
#masterclass-container .chapters-popup .chapters-heading .expand {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  color: #ddcedb;
  font-family: "futura-pt-demi";
  font-size: 15px;
  padding-right: 0;
  margin-bottom: 0;
}

#masterclass-container
  .chapters-popup
  .chapters-heading
  .expand
  div:first-child {
  margin-right: 8px;
}

#masterclass-container .chapters-popup .chapters-heading .expand .icon.chevron {
  cursor: pointer;
}

#masterclass-container .chapters-popup .scrollable .chapter {
  flex-wrap: wrap;
}

button.expand:hover .icon {
  margin-left: 5px;
  margin-right: 0
}

.media-cont {
  padding-bottom: 30px;
  position: relative;
}

/* fade in and out  */
.mcplayer,
.below-video {
  opacity: 0;
  transition: opacity 0.7s ease;
}

.mcplayer.fade-in,
.below-video.fade-in {
  opacity: 1;
}

.mimicButton {
  text-align: center;
  border: none;
  font-weight: 600;
  margin-bottom: 20px;
  color: #fff;
  background-color: #64596b;
  align-items: center;
  border-radius: 12px;
  display: flex;
  font-size: 20px;
  height: 116px;
  justify-content: flex-start;
  width: 320px;
}

.mimicButtonLink {
  width: 320px;
  display: block;
}

#masterclass-container .below-video .button-container .mimicButton svg {
  height: 50px;
  width: 50px;
  margin-right: 15px;
  margin-left: 5px;
  margin-top: 3px;
  padding: 10px 0 10px 30px;
}

.mimicButton:hover {
  border: 1px solid rgba(100, 89, 107, 0.5);
  background-color: #fff;
  color: #e92263;
}

@media (max-width: 1150px) {
  #masterclass-container {
    padding: 100px 80px 150px 85px;
    flex-direction: column;
  }

  #masterclass-container .column-1 {
    margin-right: 0;
  }
}

@media (max-width: 960px) {
  #masterclass-container .below-video {
    flex-direction: column;
  }

  #masterclass-container .below-video .button-container {
    margin: 0 0 15px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
}

@media (max-width: 850px) {
  #masterclass-container {
    padding: 100px 40px 150px 40px;
  }

  #masterclass-container .mcplayer .controls {
    padding: 0 20px 65px 20px;
  }
}

@media (max-width: 750px) {
  #masterclass-container .mcplayer .controls {
    padding: 0 20px 75px 20px;
  }

  #masterclass-container .below-video .button-container button {
    width: auto;
    height: auto;
    font-size: 16px;
  }

  #masterclass-container .below-video .button-container button svg {
    height: 30px;
    width: 30px;
  }

  #masterclass-container .description .title {
    font-size: 32px;
  }

  #masterclass-container .description .tag-line,
  #masterclass-container .description .text {
    font-size: 16px;
  }

  #masterclass-container .mcplayer .controls button {
    font-size: 30px;
  }

  #masterclass-container .mcplayer .controls button.play-pause {
    font-size: 60px;
  }

  #masterclass-container .mcplayer .controls .volume-container {
    bottom: 3px;
  }

  #masterclass-container .mcplayer .controls .volume-bar {
    bottom: 66px;
    right: 33px;
  }

  #masterclass-container .mcplayer .controls .volume-container {
    padding: 195px 5px 16px 5px;
  }
}

@media (max-width: 650px) {
  #masterclass-container .mcplayer .controls {
    padding: 0 20px 45px 20px;
  }
}
@media (max-width: 600px) {
  #masterclass-container .below-video .button-container {
    flex-direction: column;
  }

  #masterclass-container .below-video .button-container button {
    width: 320px;
  }

  #masterclass-container .description .tag-line {
    width: 80%;
  }
  #masterclass-container .description .text {
    width: 100%;
  }
}

@media (max-width: 550px) {
  #masterclass-container .mcplayer .controls {
    padding: 0 10px 45px 10px;
  }

  #masterclass-container .mcplayer .controls button {
    font-size: 25px;
  }

  #masterclass-container .mcplayer .controls button.play-pause {
    font-size: 35px;
  }

  #masterclass-container .mcplayer .controls .volume-container {
    position: absolute;
    bottom: 13px;
    right: 28px;
  }

  #masterclass-container .mcplayer .controls .volume-container {
    bottom: -3px;
  }

  #masterclass-container .mcplayer .controls .volume-bar {
    bottom: 45px;
    right: 29px;
  }
  #masterclass-container .mcplayer .controls .volume-container {
    padding: 100px 3px 10px 3px;
  }

  #masterclass-container .mcplayer .controls .volume-bar input[type="range"] {
    width: 83px;
  }
}

@media (max-width: 480px) {
  #masterclass-container .description .tag-line {
    font-size: 14px;
    padding: 5px;
    width: 100%;
  }

  #masterclass-container .description .title {
    font-size: 26px;
  }

  #masterclass-container .description > div {
    margin-bottom: 20px;
  }
}

@media (max-width: 380px) {
  #masterclass-container .mcplayer .controls .volume-bar {
    transform: rotate(-180deg);
    bottom: 8px;
    right: 39px;
  }

  #masterclass-container .mcplayer .controls .volume-container {
    right: 26px;
    padding: 3px 8px 3px 100px;
  }

  #masterclass-container .below-video .button-container button {
    width: auto;
  }

  #masterclass-container .description .tag-line {
    flex-wrap: wrap;
    justify-content: center;
  }
}

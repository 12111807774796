.challenge .page-cont .gradient-overlay .hero-contents .hero-subtitle {
  font-size: 24px;
  font-weight: 450;
  font-family: "futura-pt-medium";
  line-height: 45px;
  letter-spacing: 3px;
  text-align: left;
  margin-bottom: 0;
  text-transform: uppercase;
}

.challenge .page-cont .gradient-overlay .hero-contents .challenge-description {
  font-weight: 500;
}

.results-container.challenges {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 75px;
  font-family: "futura-pt-medium";
}
.challenge-description-inactive {
  margin-bottom: 120px;
}

.challenge .page-cont .gradient-overlay .hero-contents h1.hero-title {
  font-family: "futura-pt-medium";
  max-width: 750px;
}

@media (max-width: 768px) {
  .challenge .page-cont .gradient-overlay .hero-contents .hero-title {
    margin-top: 10px;
  }
}

@media (max-width: 1100px) {
  .challenge .chart-container.quarter {
    width: calc(50% - 118px);
  }
}

@media (min-width: 1100px) and (max-width: 1439px) {
  .challenge .results-container .results-row.half {
    width: 100%;
  }

  .challenge .chart-container.half {
    width: calc(66% - 108px);
  }

  .challenge .chart-container.quarter {
    width: calc(33% - 132px);
  }
}

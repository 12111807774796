.challenge .chart-container {
  height: 350px;
  font-family: "futura-pt-medium";
}
.challenge .chart-container .title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.challenge .chart-container .title-container .title {
  margin-bottom: 30px;
  font-weight: 500;
  line-height: 45px;
}

.challenge .chart-container .title-container .subtitle {
  font-size: 20px;
  font-weight: 450;
  line-height: 45px;
  letter-spacing: 3px;
  text-align: center;
  text-transform: uppercase;
}

.challenge .chart-container.circle-challenge .chart-box .circle_chart {
  padding: 10px;
  width: 160px;
  height: 160px;
}

.challenge .circle_chart .unit {
  font-size: 24px;
  font-weight: 500;
  line-height: 45px;
  text-align: center;
  margin: 0;
  text-transform: lowercase;
}

.challenge .circle_chart .ended {
  line-height: 30px;
  text-transform: capitalize;
}

.challenge .circle_chart .value {
  margin-top: 16px;
}

.challenge .circle_chart .value.ended {
  display: none;
}

.challenge .circle_chart .unit.ended {
  margin-top: 16px;
}

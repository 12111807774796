.grid-container {
  display: flex;
  flex-direction: column;
  font-weight: 400;
  flex-wrap: nowrap;
  justify-content: center;
  margin-top: auto;
  margin-bottom: auto;
}

.grid-container .row {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #64596b;
  padding: 0.7rem 0;
}

.grid-container .row .row-heading.empty,
.grid-container .row .empty {
  color: #64596b;
}

.grid-container .row:last-of-type {
  border-bottom: none;
}
.grid-container .row div {
  flex: 0 0 50%;
}

.grid-container .row span {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  display: block;
  margin-right: 13px;
  float: left;
}

.grid-container .column {
  display: flex;
  flex-direction: column;
  font-size: 15px;
  font-family: "futura-pt-book";
  font-weight: 400;
  flex: 0 0 50%;
}

.grid-container .column div {
  font-family: "futura-pt-demi";
  color: #fff;
  font-size: 1.25rem;
  text-transform: capitalize;
}

.grid-container .column .row-heading {
  color: #ddcedb;
  font-family: "futura-pt-medium";
  font-size: 0.9rem;
  display: flex;
  align-items: flex-end;
}

.grid-container .row .locations {
  position: relative;
  cursor: pointer;
}

.grid-container .row .locations .pain-tooltip {
  width: 120px;
  height: fit-content;
  border-radius: 6.5px;
  position: absolute;
  z-index: 1;
  background-color: #64596b;
  padding: 8px;
  top: 35px;
  left: 0;
  &::before {
    content: "";
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent #64596b transparent;
  }
}

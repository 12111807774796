.finishedChallenge {
  width: 420px;
}

.finishedChallenge-subtitile {
  margin: 30px 0px -10px 50px;
  font-size: 30px;
  text-align: center;
}

.finishedChallenge .finishedChallenge-header-nosuccess {
  color: #41c0d3;
}

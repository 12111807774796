.assessment #scroll_fade_bottom {
  width: 60%;
  height: 25px;
  /*background: url('./images/grey_fade_bottom.png') repeat-x;*/
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  position: fixed;
  bottom: 140px;
  right: 0;
  z-index: 4;
}
.assessment #scroll_fade_bottom #down_icon {
  width: 30px;
  height: 30px;
  margin: 10px 0 0 -40px;
  position: absolute;
  background-color: #e92263;
  border-radius: 50%;
  left: 50%;
  z-index: 6;
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: auto;
  padding: 0;
}
.assessment #scroll_fade_bottom #down_icon .icon {
  height: 25px;
  color: #fff;
  display: inline-block;
  transition: margin 200ms;
  margin-left: 0;
}
.assessment #scroll_fade_bottom #down_icon:hover .icon {
  margin-top: 2px;
  margin-right: 0;
}
.assessment #progress_footer_container,
.register #progress_footer_container {
  position: fixed;
  bottom: 0;
  height: 140px;
  padding-left: 40%;
  z-index: 3;
  width: 100%;
  background-color: #f6f5f7;
}
.register #progress_footer_container {
  height: 100px;
}
#back,
#next {
  width: 70px;
  height: 70px;
  background-color: var(--pink-primary-color);
  border-radius: 50%;
  display: inline-block;
  margin-top: 30px;
  color: #fff;
  text-align: center;
  cursor: pointer;
  z-index: 11;
  position: relative;
  margin-right: 50px;
}
#back {
  background-color: #d4ccd9;
}
#next.last_page {
  border-radius: 35px;
  width: 130px;
  margin-left: -60px;
}

#generate_report {
  display: inline-block;
  text-transform: uppercase;
}
#back .icon,
#next .icon {
  height: 40px;
  width: 40px;
  display: inline-block;
  margin-top: 15px;
  transition: margin 250ms;
}

#back .icon, #next .icon {
  margin: 0;
}
#back {
  border: none;
}

#back:hover .icon {
  margin-left: -10px;
}

#next:hover .icon {
  margin-right: -10px;
}

#section_progress {
  display: inline-block;
  margin: 30px 20px;
  text-align: center;

  padding-top: 22px;
  vertical-align: top;
}

.assessment #section_progress {
  width: calc(100% - 230px);
}

.assessment #section_progress .section_bar {
  width: 70px;
  max-width: 20%;
  height: 4px;
  background-color: #d4ccd9;
  border-radius: 2px;
  margin: 0 10px 10px 10px;
  display: inline-block;
}
#section_progress .section_bar.active {
  background-color: #e92263;
}
#section_progress p {
  font-size: 80%;
  margin: 25px 0 0 0;
  text-transform: uppercase;
  color: #6f6776;
}
.register #section_progress p {
  margin-top: 0;
}
.assessment #progress_footer_container button {
  padding: 0;
}

.progress_footer {
  width: 60%;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1300px) {
  .assessment #right_nav,
  .assessment #scroll_fade_bottom {
    width: 60%;
  }
  .assessment #right_container,
  .assessment #progress_footer_container,
  .register #right_container,
  .register #progress_footer_container {
    padding-left: 40%;
  }
}

@media (max-width: 1050px) {
  .assessment #right_nav,
  .assessment #scroll_fade_bottom {
    width: 100%;
  }
  .assessment #right_nav,
  .assessment #right_container,
  .assessment #progress_footer_container,
  .register #right_nav,
  .register #right_container,
  .register #progress_footer_container {
    padding-left: 50px;
  }
  .assessment #scroll_fade_bottom #down_icon {
    margin-left: -15px;
  }
  #section_progress {
    width: calc(100% - 280px);
  }

  .progress_footer {
    width: calc(100% - 50px);
  }
}

@media (max-width: 500px) {
  .assessment #right_nav {
    padding-left: 30px;
  }
}

.foryou #nav_container {
  position: fixed;
  top: 0;
  z-index: 49;
  width: 100%;
  box-sizing: border-box;
  padding: 25px 50px 75px 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#nav_container.no-submenu {
  padding: 25px 50px;
}

.foryou #nav_container img {
  width: 60px;
  height: 60px;
}

.foryou #nav_container .notifications_container {
  display: inline-block;
  text-align: center;
  width: 60px;
  height: 60px;
  vertical-align: top;
  cursor: pointer;
  color: #fff;
  background-color: #463b4c;
  border-radius: 31px;
  font-size: 42px;
}

/* .foryou #nav_container .icon {
  width: 33px;
  height: 33px;
} */

.notifications-list {
  position: absolute;
  right: 0;
  top: 100px;
  width: 390px;
  background-color: #281d2d;
  z-index: 50;
  color: #fff;
}

.notifications-list .empty-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

@keyframes ringing {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(-10deg);
  }
  20% {
    transform: rotate(10deg);
  }
  30% {
    transform: rotate(-10deg);
  }
  40% {
    transform: rotate(10deg);
  }
  50% {
    transform: rotate(-10deg);
  }
  60% {
    transform: rotate(10deg);
  }
  70% {
    transform: rotate(-10deg);
  }
  80% {
    transform: rotate(10deg);
  }
  90% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.notifications-list .empty-list .icons-cont {
  position: relative;
  width: 80px;
  height: 70px;
}

.notifications-list .empty-list .ringing-bell {
  height: 70px;
  width: 70px;
  animation: ringing 0.5s ease-in-out;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.notifications-list .empty-list .delayed-check {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 25px;
  width: 25px;
  /* opacity: 0; */
  /* animation-delay: 2s; */
  animation: fade-in 0.7s linear;
}

.notifications-list .empty-list .empty-title {
  font-family: "futura-pt-demi";
  font-size: 22px;
  margin: 20px;
}

.notifications-list .empty-list .empty-text {
  font-size: 15px;
  margin: 20px;
  text-align: center;
  color: #ddcedb;
}

.notifications-list .notification-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 30px;
  position: relative;
}

.notifications-list .notification-header .cog-icon {
  position: absolute;
  right: 20px;
  color: #fff;
  cursor: pointer;
}

.notifications-list .notification-title {
  font-family: "futura-pt-medium";
  font-size: 15px;
  text-transform: uppercase;
}

.notifications-list .notification-title.wi {
  padding: 25px;
}

.notifications-list .scrollable {
  height: 60vh;
  overflow-y: scroll;
}

.notifications-list .scrollable::-webkit-scrollbar {
  width: 4px;
}

.notifications-list .scrollable::-webkit-scrollbar-track {
  border-radius: 2.5px;
  width: 4px;
  background: transparent;
}

.notifications-list .scrollable::-webkit-scrollbar-thumb {
  width: 4px;
  border-radius: 2.5px;
  background: #291e2e;
}

.notifications-list .notifications-block .notification-item:last-child {
  border-bottom: 1px solid #3a2f3f;
}

.notifications-list .notification-item {
  border-top: 1px solid #3a2f3f;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 25px;
}

.notifications-list .notification-item.unread {
  background-color: #3a2f3f;
}

.notification-cont {
  display: flex;
  flex-direction: row;
}
.notification-cont .circle {
  height: 46px;
  width: 46px;
  border-radius: 50%;
  margin-right: 20px;
  background-color: #463b4c;
  flex: none;
}

.notification-cont .notification-icon {
  height: 45px;
  width: 45px;
  margin-right: 20px;
}

.notification-actions {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}

.content-remind .slider-text {
  font-family: "futura-pt-demi";
  font-size: 15px;
}

.tooltip-toggle {
  position: relative;
}

.tooltip-toggle .notifications-tooltip {
  position: absolute;
  z-index: 1;
  right: -20px;
  top: 26px;
  background-color: #64596b;
  width: 185px;
  border-radius: 12px;
  font-family: "futura-pt-medium";
  font-size: 15px;
  &::before {
    content: "";
    position: absolute;
    top: -26px;
    right: -3px;
    transform: translateX(-50%);
    border-width: 15px;
    border-style: solid;
    border-color: transparent transparent #64596b transparent;
  }
}

.tooltip-toggle .notifications-tooltip .tooltip-item:nth-child(2) {
  border-top: 1px solid rgba(241, 235, 244, 0.05);
  border-bottom: 1px solid rgba(241, 235, 244, 0.05);
}

.tooltip-toggle .notifications-tooltip .tooltip-item {
  display: flex;
  flex-direction: row;
  padding: 15px;
  cursor: pointer;
}

.tooltip-toggle .notifications-tooltip .tooltip-item .item-btn {
  margin-right: 15px;
}
@media (max-width: 650px) {
  .foryou #nav_container img {
    width: 40px;
    height: 40px;
  }
  .foryou #nav_container .notifications_container {
    width: 40px;
    height: 40px;
  }

  .foryou #nav_container .icon {
    width: 20px;
    height: 20px;
    vertical-align: 0.25em;
  }
  .foryou #nav_container #photo_outer {
    width: 40px;
    height: 40px;
  }
}

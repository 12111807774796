.tile-content.motivation {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.tile-content.motivation p {
  margin: 12px 0;
}

.tile-content.motivation div {
  background-color: #64596b;
  font-family: "futura-pt-medium";
  font-size: 15px;
  text-transform: uppercase;
  margin-top: 15px;
  padding: 13px 20px;
  border-radius: 23px;
}

.tile-content.motivation div.empty {
  background-color: #362b3c;
}

@media (max-width: 1636px) {
  .tile-content.motivation p,
  .tile-content.motivation div {
    font-size: 90%;
  }
}

@media (max-width: 1500px) {
  .tile-content.motivation p,
  .tile-content.motivation div {
    font-size: 80%;
  }
}

@media (max-width: 1400px) {
  .tile-content.motivation p,
  .tile-content.motivation div {
    font-size: 70%;
  }
}

@media (max-width: 1250px) {
  .tile-content.motivation p,
  .tile-content.motivation div {
    font-size: 80%;
  }
}

.edit-field .cw .circle-ff {
  padding: 0;
  height: 31px;
  width: 31px;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 30px 8px;
  cursor: pointer;
}

.edit-field .cw .circle-ff:focus {
  outline: var(--dark-outline);
  transition: none;
}

.edit-field .cw #green.circle-ff {
  background-color: #62bf92;
}

.edit-field .cw #blue.circle-ff {
  background-color: #41c0d3;
}

.edit-field .cw #red.circle-ff {
  background-color: #ef545a;
}

.edit-field .cw #orange.circle-ff {
  background-color: #f69358;
}

.edit-field .cw #yellow.circle-ff {
  background-color: #fcc954;
}

.edit-field .cw .circle-ff .inner-crc {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: #fff;
  margin-top: 8px;
  pointer-events: none;
}

.profile-container .slider-text {
  text-align: left;
}

#delete-profile:focus {
  padding-right: 8px;
  outline: var(--dark-outline);
}

/* body.no-scroll {
  overflow-y: hidden;
} */

.trackers-wrap {
  position: fixed;
  width: 100%;
  height: 100%;
  /* overflow-y: hidden; */
  background-color: rgba(40, 29, 45, 0.7);
  z-index: 50;
}

.trackers {
  font-size: 50px;
  z-index: 51;
  position: absolute;
  background-color: white;
  width: 750px;
  height: 90%;
  border-radius: 12px;
  top: 5%;
  left: 50%;
  transform: translate(-50%, 0%);
  overflow: hidden;
}

.tracker-container {
  padding-right: 5px;
  border-radius: 12px 0 0 0;
  height: calc(100% - 84px);
  overflow: hidden;
}

.trackers-inner {
  height: 100%;
  overflow-y: auto;
}

.trackers-inner.narrow {
  height: calc(100% - 20px);
  overflow-y: auto;
}

.trackers-inner::-webkit-scrollbar {
  width: 4px;
}

.trackers-inner::-webkit-scrollbar-track {
  border-radius: 2.5px;
  background: transparent;
}

.trackers-inner::-webkit-scrollbar-thumb {
  border-radius: 2.5px;
  background: #291e2e;
}

.tracker-container .ind-tracker .title,
.tracker-container .buttons,
.tc-badge {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.warning {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 30px;
  padding: 30px;
  color: #fff;
  background: #ef545a;
  font-family: "futura-pt-medium";
  font-size: 25px;
}

.warning-icon {
  margin-right: 20px;
}

.red-arrow {
  color: #ef545a;
}

#snm-main.tracker-container {
  margin-right: 750px;
  margin-left: -750px;
  opacity: 0;
  transition: all 0.5s ease;
}

#snm-main.tracker-container.slide-in {
  margin-right: 0;
  margin-left: 0;
  opacity: 1;
}

#snm-stress.tracker-container,
#snm-mood.tracker-container,
#snm-hydration.tracker-container,
#snm-alcohol.tracker-container {
  margin-right: -750px;
  margin-left: 750px;
  opacity: 0;
  transition: all 0.5s ease;
}

#snm-stress.tracker-container.slide-in,
#snm-mood.tracker-container.slide-in,
#snm-hydration.tracker-container.slide-in,
#snm-alcohol.tracker-container.slide-in {
  margin-right: 0;
  margin-left: 0;
  opacity: 1;
}

#snm-stress.tracker-container .trackers-body {
  padding-top: 0;
}

.tc-badge .fa-trophy {
  color: #41c0d3;
  margin: 0 10px;
}

.tracker-container p {
  font-family: "futura-pt-medium";
  font-size: 15px;
  text-transform: uppercase;
  color: #64596b;
  margin: 0;
}

.tracker-container .back-to-main {
  height: 100px;
  background-color: #f1ebf4;
}

.tracker-container .back-to-main .back-btn {
  padding-left: 40px;
  line-height: 100px;
  color: #aaa2af;
  height: 40px;
  cursor: pointer;
}

.tracker-container .ind-tracker {
  /* height: 100px; */
  display: flex;
  flex-direction: column;
  border-top: 2px solid rgba(100, 89, 107, 0.1);
}

.tracker-container .ind-tracker.mood {
  padding: 0 40px;
}

.tracker-container .ind-tracker.mood .title {
  margin-bottom: 40px;
}

.tracker-container .ind-tracker.mood .mood-area {
  background-color: #f1ebf4;
  padding: 40px;
  border-radius: 34px;
  font-family: "futura-pt-demi";
  font-size: 22.5px;
  color: #281d2d;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
  cursor: pointer;
}

.tracker-container .ind-tracker.mood .mood-area:hover {
  background-color: #f69358;
  color: #fff;
}

.tracker-container .ind-tracker.mood .mood-area .mood-icon {
  margin-right: 25px;
}

.tracker-container .buttons {
  border-top: 2px solid rgba(100, 89, 107, 0.1);
  padding: 30px 40px;
  width: calc(100% - 80px);
  position: fixed;
  bottom: 0;
  background-color: #fff;
  border-radius: 0 0 12px 12px;
}

.tracker-container .buttons.narrow {
  padding: 20px 40px;
}

.tracker-container .buttons.mood {
  position: fixed;
  bottom: 0;
}

.tracker-container .ind-tracker:first-child {
  border-top: none;
}

.tracker-container .tracker-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 2px solid rgba(100, 89, 107, 0.1);
  position: relative;
}

.tracker-container .tracker-heading .popup {
  position: absolute;
  top: 45px;
  left: calc(50% - 190px);
  width: 300px;
  height: 200px;
  z-index: 5;
  background-color: #fff;
  border: 2px solid #ddcedb;
  border-radius: 12px;
  box-shadow: 6px 6px 3px #aaa2af70;
}

.tracker-container .tracker-heading div {
  color: #281d2d;
  padding: 40px;
  font-family: "futura-pt-medium";
  font-size: 17.5px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.tracker-container .tracker-heading .icon {
  height: 40px;
  padding: 30px;
  cursor: pointer;
}

.trackers-body {
  padding: 40px 7%;
}

.buffer {
  width: 100%;
  height: 40px;
}

.tracker-container .ind-tracker .title {
  color: #281d2d;
  font-family: "futura-pt-demi";
  font-size: 30px;
  padding-top: 1.4em;
}

.ind-tracker .title {
  text-align: left;
}

.tracker-container .ind-tracker .week {
  align-self: center;
  display: flex;
  flex-direction: row;
  padding-top: 20px;
}

.ind-tracker .switch {
  display: inline-block;
  position: relative;
  width: 200px;
  height: 40px;
  background-color: #d4ccd9;
  border-radius: 40px;
  transition: background-color 0.2s;
  cursor: pointer;
  border: solid 2px #d4ccd9;
}

.ind-tracker .switch.on {
  background-color: #e92263;
}

.ind-tracker .switch.off .slider {
  transform: translateX(0);
}

.ind-tracker .switch.on .slider {
  transform: translateX(160px);
}

.ind-tracker .slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s;
}

.ind-tracker .label {
  font-size: 18px;
  text-align: center;
  text-transform: uppercase;
  line-height: 40px;
  color: white;
}

.tracker-container .buttons .close-btn {
  color: #281d2d;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
}

.tracker-container .buttons button.submit {
  width: 200px;
  margin-bottom: 0;
  display: block;
  border: 1px solid #281d2d;
}

.plus-minus-cont {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 2px solid #ddcedb;
  align-items: flex-end;
  padding-bottom: 5px;
}

.plus-minus-cont .icon {
  height: 18px;
  padding-bottom: 5px;
  cursor: pointer;
}

.plus-minus-cont .value {
  color: #41c0d3;
  font-size: 40px;
  line-height: 40px;
  font-family: "futura-pt-demi";
  text-align: center;
  padding: 0 45px;
}

.plus-minus-cont .value.sm {
  color: #ef545a;
  user-select: none;
}

.yes-no-btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 15px;
}

.yes-no-btn .alc-btn {
  border: 2px solid #ddcedb;
  color: #281d2d;
  padding: 20px;
  border-radius: 30px;
  flex-grow: 1;
  cursor: pointer;
  text-align: center;
}

.yes-no-btn .alc-btn:hover,
.yes-no-btn .alc-btn.selected {
  border: 2px solid #f69358;
  background-color: #f69358;
  color: #fff;
}

.yes-no-btn .alc-btn.sm:hover,
.yes-no-btn .alc-btn.sm.selected {
  border: 2px solid #41c0d3;
  background-color: #41c0d3;
}

.whc-cont.alc {
  background-color: #62bf92;
  color: #fff;
}

.whc-cont.alc .whc-thumbs-up {
  margin-right: 10px;
}

.whc-cont.alc .icon-cont {
  position: relative;
}

.whc-cont.alc .icon-cont svg {
  height: 83px;
}

.whc-cont.alc .icon-cont .days {
  position: absolute;
  width: 67px;
  text-align: center;
  font-family: "futura-pt-demi";
  font-size: 40px;
  left: 2px;
  bottom: 12px;
}

@media (max-width: 780px) {
  .trackers {
    height: 110%;
    transform: translate(-50%, -10%) scale(0.8);
  }
}

@media (max-width: 630px) {
  .trackers {
    height: 125%;
    transform: translate(-50%, -15%) scale(0.7);
  }
}

@media (max-width: 540px) {
  .trackers {
    height: 150%;
    transform: translate(-50%, -20%) scale(0.6);
  }
}

@media (max-width: 470px) {
  .trackers {
    height: 180%;
    transform: translate(-50%, -25%) scale(0.5);
  }
}

@media (max-width: 380px) {
  .trackers {
    height: 220%;
    transform: translate(-50%, -30%) scale(0.4);
  }
}

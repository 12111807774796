/*radio*/
.question_container .radio .radioItem {
  display: inline-block;
  margin: 0px 5px 5px 0;
}
.question_container .radio input[type="radio"] + label {
  background-color: #f6f5f7;
  border-radius: 25px;
  height: auto;
  padding: 13px 25px;
  font-size: 15px;
  text-align: center;
  color: #7e658c;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0;
  line-height: 160%;
}
.question_container .long_radio .radio input[type="radio"] + label {
  text-align: left;
}
.question_container .radio input[type="radio"]:hover + label {
  background-color: #80738a;
  color: #f6f5f7;
}
.question_container .radio input[type="radio"]:checked + label {
  background-color: #a81758;
  color: #fff;
}

#financial_position .answer_block .radioItem {
  width: 100%;
}
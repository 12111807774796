.image-cont {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 140px;
  width: 140px;
  position: absolute;
  top: 0px;
  left: 0px;
}

.lds-dual-ring {
  display: inline-block;
  width: 90px;
  height: 90px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 80px;
  height: 80px;
  margin: 1px;
  border-radius: 50%;
  border: 3px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

.loading-spinner__overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  /*background: rgba(2, 2, 20, 0.8);*/
  background-color: rgb(62, 53, 68, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 998;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

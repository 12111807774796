#backdrop_holder {
  position: fixed;
  /*background-color: rgb(255, 255, 255, .5);*/
  background-color: rgb(62, 53, 68, 0.7);
  width: 100vw;
  height: 100vh;
  z-index: 85;
  opacity: 0;
  transition: opacity 0.7s ease-in-out;
  -moz-transition: opacity 0.7s ease-in-out;
  -webkit-transition: opacity 0.7s ease-in-out;
  right: -100vw;
}
#side-drawer {
  width: 550px;
  height: 100vh;
  background-color: #413847;
  position: fixed;
  z-index: 90;
  right: -550px;
  color: #fff;
  padding: 70px 20px 70px 70px;
  box-sizing: border-box;
  clip-path: ellipse(100% 150% at 100% 50%);
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
  opacity: 1;
}

#backdrop_holder.open {
  right: 0;
  opacity: 1;
}
#side-drawer.open {
  right: 0;
  display: flex;
  flex-direction: column;
}
#side-drawer a {
  color: #fff;
}

#side-drawer .menu_close {
  font-size: 30px;
  position: absolute;
  right: 50px;
  top: 30px;
  cursor: pointer;
}
#side-drawer .menu_photo {
  width: 100px;
  height: 100px;
  background-size: 100%;
  margin-left: 0;
  float: left;
}
#side-drawer .floated_content {
  display: inline-block;
  justify-content: center;
}
#side-drawer h3,
#side-drawer p {
  font-size: 18px;
}
#side-drawer .floated_content h3,
#side-drawer .floated_content p {
  margin: 0;
}
#side-drawer .floated_content h3.name {
  margin-top: 20px;
}
#side-drawer a .icon,
#side-drawer .large_icon {
  padding: 10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #524a57;
  margin-right: 20px;
}
#side-drawer hr {
  color: #524a57;
  margin-bottom: 20px;
  margin-top: 20px;
}
#side-drawer hr:first-of-type {
  margin-bottom: 20px;
  margin-top: 30px;
}
.side-drawer.scrollable {
  overflow-y: auto;
  padding-right: 20px;
}

.side-drawer.scrollable::-webkit-scrollbar {
  width: 35px;
}
.side-drawer.scrollable::-webkit-scrollbar-thumb {
  border: 12px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  background-color: rgb(252, 249, 249);
}

#side-drawer a.link_row {
  display: block;
  width: 100%;
  position: relative;
  margin-bottom: 5px;
}

#side-drawer a.link_row .icon {
  position: absolute;
  left: 0;
  -webkit-transition: color 300ms linear;
  -ms-transition: color 300ms linear;
  transition: color 300ms linear;
}
#side-drawer a.link_row .icon.right,
#side-drawer a.link_row .icon.down {
  right: 0;
  left: auto;
  margin-right: 0;
}
#side-drawer a.link_row h3 {
  display: inline-block;
  padding: 8px 60px;
  margin-bottom: 20px;
}

#side-drawer a.link_row.page h3 {
  margin-left: 10px;
}
#side-drawer a:hover .icon {
  color: #e92263;
}
#sidebar_footer {
  position: absolute;
  bottom: 30px;
  left: 80px;
}
#sidebar_footer a,
#sidebar_footer span {
  font-size: 14px;
  margin-right: 40px;
}
#side-drawer.open:last-child {
  padding-bottom: 150px;
}
.app_banner_side_drawer {
  padding-left: 60px;
}
.app_banner_side_drawer h3 {
  margin-bottom: 0;
}
.app_banner_side_drawer p {
  margin-top: 3px;
}
img.app_link.google_play {
  width: 130px;
  border-radius: 6px;
  margin-right: 10px;
  border: white solid 1px;
}
img.app_link.app_store {
  width: 118px;
  border-radius: 6px;
  border: white solid 1px;
}
@media (max-width: 800px) {
  #side-drawer {
    width: 380px;
    right: -380px;
    padding: 70px 30px;
  }
  #sidebar_footer a,
  #sidebar_footer span {
    margin-right: 15px;
    font-size: 13px;
  }
}

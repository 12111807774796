.search {
  padding-top: 165px;
  padding-bottom: 50px;
  background-color: rgba(40, 29, 45, 0.7);
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.search-bar-container {
  width: 73vw;
  margin-left: auto;
  margin-right: auto;
}

.search-bar-container .search_field {
  display: flex;
  background-color: #3d3341;
  height: 56px;
  border-radius: 25px;
  border: white;
}

.search-bar-container .search_input::placeholder {
  font-size: 20px;
  text-indent: 20px;
  color: #fff;
}

.search-bar-container input.search_input {
  font-family: "futura-pt-medium", sans-serif;
  color: #fff;
  font-size: 22.5px;
  text-indent: 20px;
  font-weight: 500;
  width: 100%;
  height: 48px;
  border: none;
  outline: none;
  margin-top: 2px;
}

.search-bar-container .icon {
  padding: 0;
  margin: 18px 10px 0 32px;
  height: 22px;
  width: 22px;
  background-color: #3d3341;
  color: #fff;
  display: inline-block;
  vertical-align: middle;
}

.search-bar-container .icon.times {
  margin: 18px 32px 0 32px;
  cursor: pointer;
}

.search .search_suggestions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 73vw;
  margin-left: auto;
  margin-right: auto;
  color: #fff;
  font-family: "futura-pt-medium", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 35px;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
}

.search_suggestions.show {
  opacity: 1;
}

.search .search_suggestions div {
  border: 1px solid #fff;
  border-radius: 23px;
  padding: 3px 20px;
  margin-right: 20px;
  cursor: pointer;
  transition: opacity 0.6s ease-in-out;
  opacity: 0;
}

.search .search_suggestions div.show {
  opacity: 1;
  margin-top: 20px;
  margin-bottom: -20px;
}

.search .search_suggestions div:hover {
  background-color: #fff;
  color: #3d3341;
}

@media (max-width: 650px) {
  .search .search_suggestions {
    font-size: 16px;
  }
}
@media screen and (max-width: 425px) {
  .search-bar-container .icon {
    margin: 18px 0 0 15px;
  }

  .search-bar-container .icon.times {
    margin: 18px 15px 0 10px;
  }

  .search-bar-container input.search_input {
    font-size: 18px;
    text-indent: 10px;
  }

  .search-bar-container .search_input::placeholder {
    text-indent: 10px;
    font-size: 18px;
  }
}

.chart-box .square.wide {
  height: 245px;
}

.chart-container .wtf.icon {
  cursor: pointer;
}

.circle_chart {
  width: 240px;
  height: 240px;
  position: relative;
  border-radius: 50%;
  display: flex;
  border: solid 1.5px #615668;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  overflow: hidden;
  margin: 15px auto 0 auto;
}

.chart-icon {
  height: 50px;
  color: #ddcedb;
}

.circle_chart h3 {
  position: relative;
  font-size: 30px;
  font-family: "futura-pt-demi", sans-serif;
  margin-bottom: 0;
  text-transform: capitalize;
}

.circle_chart span {
  position: relative;
  font-size: 30px;
  font-family: "futura-pt-medium", sans-serif;
  color: #ddcedb;
  letter-spacing: 0.2rem;
}

/* @media (max-width: 1200px) and (min-width: 1050px) {
  .circle_chart {
    width: 130px;
    height: 130px;
    margin-top: 5px auto;
  }
  .circular-chart {
    width: 138px;
    height: 138px;
    top: -4px;
  }
  .chart_energy {
    margin-top: 30px;
  }
} */

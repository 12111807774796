.bar_chart_container {
    display:flex;
    align-items: flex-start;
}
.bar_chart_container .subtitle {
    margin-bottom:10px;
}

.bar-chart {
    display:flex;
    width: 100%;
    flex-direction:column;
    position:relative;
    margin:auto auto;
    font-size: 12px;
    font-family: 'futura-pt-book';
    font-weight:400;
}
.bar-chart .row {
    flex: 0 0 100%;
    position:relative;
    padding: 0px 0 0px 0;
    min-height:40px;
}
.bar-chart .row span {
    position:absolute;
    margin-top:-10px;
    
}
.bar-chart .row::after {
    content: '';
    border-bottom:1px solid #c7becd;  
    position:absolute;
    width: calc(100% - 40px);
    left: 40px;
}
.bar-chart .column-container {
    position:absolute;
    bottom: 0;
    left:40px;
    width: calc(100% - 40px);
    display:flex;
    flex-direction: row;
    height: 100%;
    padding-bottom: 40px;
    box-sizing:border-box;
}
.company_report .bar-chart .column {
    width: 10%;
    max-width: 30px;
    background-image: linear-gradient(to top, rgba(169, 23, 88, 1), rgba(230,35,100,1));
    margin: 0 auto;
    align-self: flex-end;
    border-radius: 40px 40px 0 0;
    transition: height 0.4s linear;
    position:relative;
}
.company_report .bar-chart .column span {
    position:absolute;
    width: 200%;
    max-width: 200%;
    display:inline-block;
    text-align: center;
    bottom: -60px;
    margin-left: -50%;
    height: 50px;    
    text-transform: capitalize;
}
.company_report .bar-chart .column span.hover_box {
    background-color: #290041;
    color:#fff;
    display:none;
    top:-25px;
    border-radius: 3px;
    height:20px;
    width: 150%;
    padding-top:3px;
    margin-left:-25%;
}
.company_report .bar-chart .column:hover span.hover_box {
    display:inline-block;
}
.bar-chart .column.blank {
    background-image: linear-gradient(to top, rgba(100, 89, 107, 0.4), rgba(100, 89, 107, 0.2));
}
.bar-chart .column.blank span{
    color: #64596b
}
.bar-chart.yaxis {
    padding-left:10px;
}
.bar-chart.yaxis .yaxis {
    position: absolute;
    transform: rotate(-90deg);
    left: -65px;
    top: 35%;
}
.report-section .clear .square_content .bar-chart {
    margin-top:20px;
}
.slider-cont {
  width: 100%;
  height: 50px;
  padding: 50px 0;
}

#ths-slider {
  width: 100%;
  height: 2px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
#ths-thumb {
  background-color: #fff;
  border: solid 1.5px #ddcedb;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.thumb-icon {
  color: #ddcedb;
  height: 30px;
}

.ths-values {
  margin-top: 35px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/*dot radio*/
.dot_radio {
    display:flex;
}
.dot_radio input[type="radio"] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}
.dot_radio input[type="radio"]:focus+label {
    transition: none;
}
.dot_radio input[type="radio"]+label {
    width:30px;
    height:30px;
    text-align:center;
    background-color: #d4ccd9;
    color:#fff;
    border-radius:50%;
    margin:5px;
    display:inline-block;
    font-size:12pt;
    cursor:pointer;
    box-sizing:border-box;
    padding-top:4px;
    transition: all 0.5s ease;
}

.dot_radio input[type="radio"]+label:hover{
    background-color: #80738a;
    color:#f6f5f7;
}
.dot_radio input[type="radio"]:checked+label{
    background-color: #E92263;
    color:#f6f5f7;
}

/* Add focus styles */
.dot_radio input[type="radio"]:focus+label {
    outline: 2px solid #007BFF;
    outline-offset: 2px;
}

/*gad 7*/
.question_block_wide.gad h3 {
    font-size: 22px;
}
.gad .gad_row, .gad-header-row {
    display: flex;
    flex: 0 0 100%;
    margin-bottom:10px;
}
.gad .gad_row {
    border: none;
    padding: 0;
}

.question_block_wide.gad .gad_row legend, .gad-header-spacer  {
    display: flex;
    flex: 0 0 50%;
    min-width: 300px;
}

.question_block_wide.gad .gad_row legend {
    margin-right:0;
    margin:8px 0 0 0;
    font-size: 18px;
    font-family: "futura-pt-medium", sans-serif;
    color: #a81758;
    display: inline;
    margin-right: 10px;
    font-weight: 500;
    float: left;
    width: 50%;
}

.gad .dot_radio, .gad_option_container {
    display: flex;
    flex: 0 0 50%;
}
.gad .dot_radio .input_wrap, .gad-header {
    display:flex;
    flex: 0 0 20%;
    justify-content: space-between;
    text-align:center;

}
.gad-header span {
    max-width: 85%;
    font-size:11pt;
    margin: auto;
    padding-bottom: 20px;
    font-family: 'futura-pt-medium';
    font-weight:500;
    font-size:15px;
    color:#64596b;
}
.gad .dot_radio .input_wrap label {
    margin: auto;
}

@media (max-width: 800px) {
    .question_block_wide.gad .gad_row legend, .gad-header-spacer {
        min-width:auto;
    }
}
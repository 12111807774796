/*MNS*/
/*standard fix number inputs*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;

}
.question_container .number_controls {
    width: 25px;
    display: inline-block;
}
.question_container .number_controls .up_btn, .question_container .number_controls .down_btn {
    cursor:pointer;
    background-color: #C8BDCD;
    border-radius:50%;
    height: 20px;
    width:20px;
    text-align:center;
    margin: 0 5px 5px 5px;
    border: none;
    padding: 0;
}
.up_btn .icon, .down_btn .icon {
    margin-left: 0;
    margin-right: 0;
}
.up_btn:hover .icon, .down_btn:hover .icon {
    margin: 0;
}
.question_container .number_controls .up_btn:hover, .question_container .number_controls .down_btn:hover {
    background-color: #6F6776;
}
.question_container .number_controls .up_btn .icon, .question_container .number_controls .down_btn .icon {
    font-size: 14px; 
    color:#fff;   
    position:relative;
}
.question_container .number_controls .up_btn .icon {
    top:-1px;
}

.question_container .number_field .number_container {
    background-color: #f6f5f7;
    border-radius: 25px;
    height: 50px;
    box-sizing: border-box;
    margin-right: 1px;
    display:inline-block;
    padding: 12px 20px;
    color: #a81758;
    vertical-align:top;
    /*min-width: 210px;*/
}
.question_container .number_field .number_container .number {
    outline:0;
    color:#a81758;
    border:none;  
    width:35px;
}
.question_container .number_field {
    margin-bottom:10px;
}

.question_container .multiple_number_select .number_field {
    display: inline-block;
    /*margin-right:20px;*/
}
.modal_trigger {
  color: #e92263;
  cursor: pointer;
  font-size: 20px;
  margin-right: 10px;
}
.modal_trigger_container {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  font: inherit;
  color: inherit;
  cursor: pointer;
}
.modal_trigger_container,
.modal_trigger_container span {
  vertical-align: top;
}

#modal-container {
  width: 480px;
  height: auto;
  background-color: #a81758;
  border-radius: 10px;
  position: fixed;
  z-index: 90;
  left: 50%;
  margin-left: -240px;
  color: #fff;
  padding: 70px 40px 30px 40px;
  box-sizing: border-box;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
  opacity: 0;
  top: 100vh;
  text-align: center;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
}

#modal-container .delete_account {
  margin-top: 15px;
}

#modal-container p,
#modal-container li {
  text-align: left;
}
#modal-container p a {
  color: #fff;
}

#modal-container div ul li a {
  color: #fff;
  text-decoration: underline;
}

#modal-container p a:hover {
  text-decoration: underline;
}
#modal-container.open {
  top: 15vh;
  opacity: 1;
}
#modal-container .icon.modal {
  position: absolute;
  left: 50%;
  margin-left: -40px;
  width: 80px;
  height: 80px;
  border: 10px solid #fff;
  top: -40px;
  color: #e92263;
  background-color: #fff;
  border-radius: 50%;
  box-sizing: border-box;
}

#modal-container button.modal_close {
  background-color: #e92263;
  color: #fff;
  margin-bottom: 0;
  margin-top: 30px;
}
#modal-container button.modal_close:hover,
#modal-container button.modal_close:focus {
  border: 1px solid #fff;
}

#modal-container button.modal_close.cancel_account,
#modal-container button.modal_close.remove_account {
  width: 80%;
  margin-top: 15px;
}
#modal-container button.modal_close.remove_account.disabled {
  background-color: white;
  border: none;
  color: rgba(168, 23, 88);
  opacity: 0.3;
}

/*onboarding*/
#modal-container-onboarding {
  width: 50%;
  min-width: 480px;
  height: auto;
  background-color: #fff;
  border-radius: 6px;
  position: fixed;
  z-index: 90;
  left: 50%;
  margin-left: -25%;
  color: #290041;
  box-sizing: border-box;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
  opacity: 0;
  top: 100vh;
  text-align: center;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

#modal-container-onboarding.open {
  top: 15vh;
  opacity: 1;
}
#modal-container-onboarding .right_side_onboarding button:not(:focus) {
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
  transition: all 0.5s ease;
  outline: none;
}

#modal-container-onboarding .right_side_onboarding button {
  padding: 10px 30px;
  text-align: center;
  border-radius: 25px;
  font-family: "futura-pt-demi", sans-serif;
  cursor: pointer;
  border: 1px solid var(--pink-primary-color);
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 20px;
  background-color: var(--pink-primary-color);
  color: #fff;
}

#modal-container-onboarding .right_side_onboarding #mentalwellbeing-btn {
  height: 60px;
  width: 180px;
  margin-top: 30px;
  border-radius: 31px;
}

.left_side_onboarding {
  width: 35%;
  height: 100%;
  display: inline-block;
  background-color: #c9bece;
  position: absolute;
  left: 0;
  background-size: cover;
  background-position: center center;
}
#modal-container-onboarding .name-circle {
  height: 200px;
  width: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 72px;
  left: 124px;
  color: #fff;
  font-size: 150px;
  line-height: 200px;
  text-transform: capitalize;
  font-family: "futura-pt-medium";
}

.right_side_onboarding {
  padding: 20px 5% 30px 40%;
  display: inline-block;
  box-sizing: border-box;
}
.right_side_onboarding #section_progress .section_bar {
  max-width: 19%;
}
#onboarding_image_1 {
  background-image: url("./images/Onb-1.jpg");
}
#onboarding_image_2 {
  background-image: url("./images/Onb-2.jpg");
}
#onboarding_image_3 {
  background-image: url("./images/Onb-3.jpg");
}
#onboarding_image_4 {
  background-image: url("./images/Onb-1.jpg");
}
#mentalwellbeing_image {
  background-image: url("./images/mentalWellbeing.jpg");
}
#offboarding_image_1 {
  background-image: url("./images/Offb-1.png");
}
#offboarding_image_2 {
  background-image: url("./images/Offb-2.png");
}
#offboarding_image_3 {
  background-image: url("./images/Offb-3.png");
}

#offboarding_image_4 {
  background-image: url("./images/Offb-4.png");
}

.right_side_onboarding.mentalwellbeing h3 {
  padding-top: 35px;
  text-transform: uppercase;
}

.right_side_onboarding h3 {
  color: #64596b;
  font-size: 14px;
  letter-spacing: 1px;
  margin-bottom: 30px;
  text-transform: uppercase;
}

.right_side_onboarding h1 {
  font-size: 40px;
}

.right_side_onboarding.mentalwellbeing p {
  font-family: "futura-pt-demi";
}

.right_side_onboarding #section_progress {
  width: 80%;
  padding-top: 0;
}

.right_side_onboarding #section_progress.foryou {
  width: 100%;
  margin: 30px 0;
}

#report_generator {
  position: fixed;
  z-index: 1001;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  align-content: center;
  background-image: linear-gradient(
    to bottom,
    rgba(2, 2, 20, 1),
    rgba(2, 2, 20, 0.8)
  );
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
  opacity: 1;
  top: 0;
}
#report_generator.close {
  opacity: 0;
  top: 100vh;
}
#report_generator_inner {
  max-width: 500px;
  width: 500px;
  margin: 0 auto;
  color: #fff;
  text-align: center;
}
#report_generator_container {
  width: 100%;
  height: 8px;
  border-radius: 4px;
  background-color: #ccc;
}
#report_generator_content {
  height: 8px;
  border-radius: 4px;
  background-color: #e92263;
  transition: width 10s ease-in-out;
}
.modal_wrapper {
  cursor: pointer;
}
.individual-report .modal_wrapper {
  width: 100%;
  min-height: 50%;
}
.modal_mappedBoxes {
  text-align: center;
  width: 100%;
  min-height: 40px;
  border: solid white 2px;
  border-radius: 20px;
  margin-bottom: 15px;
  /* line-height: 40px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal_mappedBoxes a {
  color: white;
  text-decoration: underline;
}

.modal_mappedBoxes span {
  padding: 0 4px;
}

.modal_mappedBoxes a span {
  padding: 0;
}

#modal-container .modal_explanation {
  margin-top: 20px;
}

#modal-container .modal_explanation p {
  text-align: center;
}

#modal-container .list_item {
  text-align: left;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

#modal-container .main_point p {
  text-align: center;
  margin-top: 20px;
}
#modal-container .main_point:first-of-type {
  font-weight: bold;
}

.icon.modal_list {
  margin-top: 1.2em;
  margin-right: 0.75em;
}
#modal-container textarea {
  margin-top: 30px;
  width: 90%;
  height: 150px;
  background-color: white;
  border-radius: 25px;
  border: none;
  color: #a81758;
  resize: none;
  padding: 5%;
}

@media (max-width: 1050px) {
  #modal-container-onboarding {
    width: 80%;
    margin-left: -40%;
    min-width: 80%;
  }

  .right_side_onboarding h1 {
    font-size: 22px;
  }
  #modal-container div {
    max-height: 70vh;
    /* overflow-y: scroll; */
  }
}

@media (max-width: 500px) {
  .left_side_onboarding {
    display: none;
  }
  .right_side_onboarding {
    padding: 20px 5% 30px 5%;
  }
}

#onboarding:focus {
  outline: 3px solid #007bff;  /* bright blue outline */
  outline-offset: 2px;

}

button:focus,
[href]:focus,
input:focus,
select:focus,
textarea:focus,
[tabindex]:not([tabindex="-1"]):focus {
  outline: 3px solid #007bff;
  outline-offset: 2px;

}

#nav_container {
  position: fixed;
  top: 0;
  background: none;
  z-index: 6;
  width: 100%;
  box-sizing: border-box;
}

#left_nav,
#right_nav {
  display: inline-block;
  vertical-align: top;
  padding-top: 25px;
  padding-bottom: 25px;
  box-sizing: border-box;
}
#left_nav {
  padding-left: 50px;
  width: 40%;
}
.assessment #right_nav,
.register #right_nav {
  width: 60%;
  padding-right: 50px;
  /*background: url('./images/grey_fade_top.png') repeat-x;*/
  background-image: linear-gradient(
    to top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 50%
  );
}
.register #right_nav {
  background: none;
}
#left_nav img {
  width: 40px;
  height: 40px;
  margin-top: 10px;
}
#progress_bar_container {
  display: inline-block;
  margin: 0 auto;
  text-align: center;
  width: calc(100% - 230px);
  padding-top: 30px;
}
.register #progress_bar_container {
  width: calc(100% - 430px);
}
.register #right_nav a {
  display: inline-block;
}
#progress_bar_container p {
  font-size: 10px;
  font-family: "futura-pt-demi";
  font-weight: 600;
  margin: 5px 0 0 0;
  text-transform: uppercase;
  color: #6f6776;
}
#progress_bar_outer {
  width: 100%;
  height: 6px;
  background-color: #c8bdcd;
  border-radius: 3px;
  margin: 0 auto;
}
#progress_bar {
  background-color: #e92263;
  height: 6px;
  border-radius: 3px;
  -webkit-transition: width 0.7s;
  -moz-transition: width 0.7s;
  -o-transition: width 0.7s;
  transition: width 0.7s;
}
#photo_outer {
  float: right;
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-size: 100%;
  background-position: center center;
  margin: 0 20px;
  background-repeat: no-repeat;
  vertical-align: top;
}

.foryou #nav_container #photo_outer {
  margin: 0 20px;
}

#menu_button {
  display: inline-block;
  width: 50px;
  height: 50px;
  margin: 5px 0;
  vertical-align: top;
  text-align: center;
  cursor: pointer;
  position: absolute;
  right: 130px;
}

#menu_button .icon {
  width: 40px;
  margin-top: 5px;
  color: #290041;
  display: inline-block;
  vertical-align: middle;
  height: 40px;
}

.register_page_logo {
  background-image: url("../../pages/images/colour_logo.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 200px;
  height: 40px;
  margin-top: 10px;
}

@media (max-width: 1300px) {
  .assessment #left_nav,
  .assessment #left_container {
    width: 40%;
  }
}

@media (max-width: 1050px) {
  .assessment #left_nav,
  .assessment #left_container,
  .assessment .health_wellbeing {
    display: none;
  }
}

/*individual report*/
.reports #nav_container {
  position: fixed;
  top: 0;
  background: none;
  z-index: 49;
  width: 100%;
  box-sizing: border-box;
  background-image: linear-gradient(
    to bottom,
    rgba(2, 2, 20, 1),
    rgba(255, 0, 0, 0)
  );
}
.reports #left_nav {
  padding-left: 50px;
  width: 40%;
}
.reports #right_nav {
  width: 60%;
  padding-right: 50px;
}
.reports #menu_button,
.reports #search_button {
  display: inline-block;
  width: 50px;
  height: 50px;
  margin: 5px 0;
  vertical-align: top;
  text-align: center;
  cursor: pointer;
  position: absolute;
  z-index: 22;
}
.reports #menu_button .icon,
.reports #search_button .search {
  width: 40px;
  margin-top: 5px;
  color: #fff;
  display: inline-block;
  vertical-align: middle;
  height: 40px;
}

/*company report*/
.reports.company_report #nav_container {
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.5) 80%,
    rgba(255, 255, 255, 0) 100%
  );
}
.reports.company_report #menu_button .icon,
.reports.company_report #search_button .icon {
  color: #290041;
}
@media (max-width: 1050px) {
  .assessment #right_nav,
  .reports #right_nav,
  .register #right_nav {
    width: 100%;
    box-sizing: border-box;
  }
  .reports #left_nav,
  .reports #left_container {
    display: none;
  }
}
@media (max-width: 500px) {
  .assessment #right_nav,
  .reports #right_nav,
  .register #right_nav {
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
  }
  #menu_button {
    right: 75px;
  }

  #right_nav #photo_outer {
    width: 40px;
    height: 40px;
    margin: 10px 5px;
  }
}

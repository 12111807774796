:root {
  --pink-primary-gradient: linear-gradient(
    45deg,
    rgb(232, 35, 99) 0%,
    rgb(168, 23, 88) 100%
  );
  --pink-primary-color: #e3165b;

  --dark-outline: 2px solid #007bff;
  --dark-thick-outline: 3px solid #007bff;
  --light-outline: 2px solid white;
  --outline-offset: 2px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: var(--pink-primary-color);
}

.transparent-button,
.transparent-button:hover,
.transparent-button:not(:hover),
.transparent-button:focus,
.transparent-button:not(:focus) {
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  box-shadow: none;
  transition: none;
  color: inherit;
}

.hide-outline {
  outline: none !important;
}

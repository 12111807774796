.info-box {
  margin-top: 20px;
}

.info-box .info-sub-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: first baseline;
}

.info-box .info-sub-heading .info-left {
  font-family: "futura-pt-medium";
  font-size: 15px;
  color: #fff;
  text-transform: uppercase;
}

.info-box .info-sub-heading p {
  text-transform: uppercase;
  font-family: "futura-pt-medium";
  font-size: 12.5px;
  color: #ddcedb;
  margin: 0;
}

.chart-container .info-text {
  font-family: "futura-pt-book";
  font-size: 17.5px;
  color: #fff;
  margin-top: 50px;
}

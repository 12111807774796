.snooze-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  opacity: 0;
  transition: opacity 0.7s ease-in-out;
}

.snooze-container.visible {
  opacity: 1;
}

.snooze-container .snooze-title {
  font-family: "futura-pt-demi";
  font-size: 18px;
  margin-right: 60px;
  align-self: center;
}

.snooze-button button,
.snooze-button button .icon {
  all: unset;
}

.snooze-button button:focus {
  outline: var(--dark-outline);
}

.snooze-button {
  position: relative;
  display: inline-block;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background-color: rgba(100, 89, 107, 0.5);
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.snooze-button button.alarm {
  padding: 17.5px;
}

.snooze-button button .icon {
  color: white;
  height: 40px;
}

.snooze-button-hovered {
  background-color: #2ecc71;
}

.snooze-options {
  position: absolute;
  top: 50%;
  left: -45px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 70px;
}

.snooze-options button:focus {
  outline-offset: 2px;
}

.snooze-options button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-bottom: 10px;
  background-color: white;
  color: black;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  font-family: "futura-pt-demi";
  font-size: 15px;
  line-height: 35px;
  text-indent: 3px;
}

.snooze-options button.top {
  text-indent: 7px;
}

.snooze-options button.middle {
  margin: 0;
}

.snooze-options button.top,
.snooze-options button.bottom {
  margin: 0 0 0 15px;
}

.media-player .controls .snooze-options button:hover {
  background-color: #41c0d3;
  color: white;
}

#active-snooze,
button.snooze-option-selected {
  background-color: #41c0d3;
  color: white;
}

@media (max-width: 1300px) {
  .snooze-container {
    justify-content: flex-end;
  }
  .snooze-container .snooze-title {
    font-size: 16px;
  }
}

@media (max-width: 670px) {
  .snooze-button {
    width: 60px;
    height: 60px;
  }

  .snooze-button button.alarm {
    padding: 15px;
  }

  .snooze-button button .icon {
    height: 30px;
  }

  .snooze-container {
    margin-bottom: 10px;
  }
}

@media (max-width: 630px) {
  .snooze-container .snooze-title {
    font-size: 14px;
  }
}

#accessDenied_container {
  width: 480px;
  height: 120px;
  background-color: #a81758;
  border-radius: 10px;
  position: fixed;
  position: absolute;
  left: calc(50% - 240px);
  top: calc(50% - 60px);
  color: #fff;
  padding: 50px 40px 30px 40px;
  box-sizing: border-box;

  text-align: center;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
}
.fandf_warningSign_access {
  background-image: url("./exclamation.png");
  height: 92px;
  width: 92px;
  position: absolute;
  float: left;
  margin-left: 65px;
  top: -45px;
  display: inline;
  background-size: 90px 90px;
  background-repeat: no-repeat;
}

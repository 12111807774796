/*CLOCK*/
/*standard fix number inputs*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/*clock*/

.answer_block .clock {
  display: flex;
  flex-direction: row;
}

.question_container .clock .hours,
.question_container .clock .minutes,
.reminder_body .clock .hours,
.reminder_body .clock .minutes {
  background-color: #f6f5f7;
  border-radius: 25px;
  height: 50px;
  box-sizing: border-box;
  margin-right: 1px;
  color: #a81758;
  border: none;
  vertical-align: top;
  text-align: center;
  outline: 0;
  min-width: 100px;
}

.clock_padding {
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.question_container .clock .minutes,
.reminder_body .clock .minutes {
  border-radius: 25px;
}
.question_container .number_controls,
.reminder_body .number_controls {
  width: 25px;
  display: inline-block;
}
.question_container .number_controls .up_btn,
.question_container .number_controls .down_btn,
.reminder_body .number_controls .up_btn,
.reminder_body .number_controls .down_btn {
  cursor: pointer;
  background-color: #fff;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  text-align: center;
  margin: 0 5px 5px 5px;
}

.reminder_body .number_controls .up_btn,
.reminder_body .number_controls .down_btn {
  background-color: #d4ccd9;
  margin: 3px 3px 3px 2px;
}

.reminder_body .answer_block {
  margin-top: 0px;
}

.reminder_body .svg-inline--fa {
  margin-top: 3px;
  vertical-align: top;
}

.question_container .number_controls .up_btn:hover,
.question_container .number_controls .down_btn:hover,
.reminder_body .number_controls .up_btn:hover,
.reminder_body .number_controls .down_btn:hover {
  background-color: #6f6776;
}
.question_container .number_controls .up_btn .icon,
.question_container .number_controls .down_btn .icon,
.reminder_body .number_controls .up_btn .icon,
.reminder_body .number_controls .down_btn .icon {
  font-size: 14px;
  color: #f6f5f7;
  position: relative;
}

.reminder_body .number_controls .up_btn .icon,
.reminder_body .number_controls .down_btn .icon {
  color: white;
}
.question_container .number_controls .up_btn .icon,
.reminder_body .number_controls .up_btn .icon {
  top: -1px;
}

@media (max-width: 500px) {
  .question_container .clock .hours,
  .question_container .clock .minutes {
    min-width: 60px;
    max-width: 90px;
  }
}

/* .reminder-scroll-area {
  height: 350px;
  overflow: auto;
}

.reminder-scroll-area::-webkit-scrollbar {
  width: 4px;
}

.reminder-scroll-area::-webkit-scrollbar-track {
  border-radius: 2px;
  width: 4px;
  background: transparent;
}

.reminder-scroll-area::-webkit-scrollbar-thumb {
  width: 4px;
  border-radius: 2px;
  background: #281d2d;
} */

.top_three_graph .top_three_row {
    border-bottom: none;
    padding: 10px 0px;
    vertical-align:top;
    font-family: 'futura-pt-demi', sans-serif;
    font-weight:600;
    display:flex;
    flex-direction:column;
    align-items: center;
    text-align:left;
}
.top_three_graph h4 {
    display:block;
    margin:0 0 5px 0;
    text-align:left;
    width: 100%;
    font-family: 'futura-pt-book', sans-serif;
    font-weight:500;
    color:#281d2d;
}
.top_three_graph .ttg_container {
    width:100%;
    height:20px;
    border-radius:10px;
    background-color: #dbd8df;
    display:block;
    margin-left:-10px;
}
.top_three_graph .ttg_fill {
    height:20px;
    border-radius:10px;
    background-color: #a81758;
    position: relative;
    width:0px;
    transition: width 1s;
}
.top_three_row:nth-of-type(2) .ttg_fill{
    background-color: #560849;
}
.top_three_row:nth-of-type(3) .ttg_fill{
    background-color: #290041;
}
.top_three_graph .top_three_row span {
    color:#fff;
    font-size:14px;
    position:absolute;
    left:5px;
    margin-top:1px;
}
.top_three.top_three_graph {
    width:100%;
}

.top_three_graph.disabled h4{
  color: #eaeaea;
}
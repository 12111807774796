.painSlider {
    margin: 70px 20px 40px 20px;
    position:relative;
    display:block;
}
.painSlider .noUi-handle {
    background: url('./images/sliderHandle.svg') no-repeat;
    background-size:contain;
    width:40px;
    height: 40px;
    background-color:transparent;
    border:none;
    margin-top:0px;
    box-shadow: none;
    outline:none;
    cursor:pointer;
    top:10px;
}
.painSlider .noUi-horizontal .noUi-handle {
    right:-19px;
}
.painSlider .noUi-connects {
    cursor:pointer;
}
.painSlider .noUi-handle::before, .painSlider .noUi-handle::after {
    display:none;
}
.painSlider .noUi-connect {
	background-color: transparent;
}
.painSlider .noUi-horizontal {
    height:30px;
    margin: 40px 0;
    background-color: transparent;
}
.painSlider .noUi-target {
    box-shadow:none;
    border:none;
}
.painSlider .leftLabel, .painSlider .rightLabel {
    position:absolute;
    top:-50px;
    text-transform:uppercase;
    color: #E3165B;
    font-family: 'futura-pt-demi';
    font-weight: 600;
    font-size: 12px;
}
.painSlider .leftLabel {
    left:-5px;
}
.painSlider .rightLabel {
    right: -5px;
}
.painSlider .valueHandle {
    position:absolute;
    top:60px;
    margin-left:-50px;
    width:100px;
    text-align:center;
    left:50%;
    color: #E3165B;
    font-family: 'futura-pt-medium';
    font-weight: 500;
    font-size: 18px;    
}
.painSlider .noUi-pips-horizontal {
    top:-5px;
}
.painSlider .noUi-value-horizontal {
    display:none;
}
.painSlider .noUi-marker {
    transition: all 0.2s ease-out; 
    background-color:#D4CCD9;
    height:20px;
}
.painSlider .noUi-marker.active {
    margin-top:-15px;
    height:30px;
    background-color:#E92263;    
}
.painSlider .noUi-marker.semiactive {
    margin-top:-10px;
    height:30px;
    background-color:#E92263;    
}
.painSlider .noUi-marker.nearactive {
    background-color:#E92263;    
}

.painSlider .noUi-state-tap .noUi-connect,
.painSlider .noUi-state-tap .noUi-origin {
    -webkit-transition: transform 0.2s; 
    transition: transform 0.2s;
}
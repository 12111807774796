.chart_circle {
  width: 180px;
  height: 180px;
  position: relative;
  border-radius: 50%;
  display: flex;
  background: #615668;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  overflow: hidden;
  margin: 15px auto 0 auto;
}

.chart_circle:after {
  position: absolute;
  content: "";
  background: #2a202f;
  top: 5px;
  left: 5px;
  bottom: 5px;
  right: 5px;
  border-radius: 50%;
}

.chart_circle h3 {
  position: relative;
  z-index: 1;
  font-size: 25px;
  font-family: "futura-pt-demi", sans-serif;
  font-weight: 600;
  margin-bottom: 0;
  text-transform: none;
}
.chart_circle h3.large {
  position: relative;
  z-index: 1;
  font-size: 50px;
  font-family: "futura-pt-demi", sans-serif;
  font-weight: 600;
}
.chart_circle span {
  position: relative;
  z-index: 1;
  font-size: 18px;
  font-family: "futura-pt-light", sans-serif;
  font-weight: 300;
}
.circular-chart {
  display: block;
  position: absolute;
  top: -8px;
  left: -8px;
  width: 196px;
  height: 196px;
}

.circle {
  fill: none;
  stroke-linecap: round;
  /*animation: progress 1s ease-out forwards;*/
  stroke-width: 4;
  transition: stroke-dasharray 1s ease-out;
}

.chart_circle_orange .circle,
.chart_circle_red .circle {
  stroke: #ef545a;
}

.chart_circle_yellow .circle,
.chart_circle_amber .circle {
  stroke: #fcc954;
}

.chart_circle_blue .circle {
  stroke: #290041;
}

.chart_circle_green .circle {
  stroke: #62bf92;
}

.chart_circle_black .circle {
  stroke: #000;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

@media (max-width: 1200px) and (min-width: 1050px) {
  .chart_circle {
    width: 130px;
    height: 130px;
    margin-top: 5px auto;
  }
  .circular-chart {
    width: 138px;
    height: 138px;
    top: -4px;
  }
  .chart_energy {
    margin-top: 30px;
  }
}

.company_report .chart_circle::after {
  background: #faf9fb;
}
.company_report .chart_circle {
  background: #c7becd;
  margin-top: 15px;
  width: 200px;
  height: 200px;
}
.company_report .circular-chart {
  width: 206px;
  height: 206px;
  top: -3px;
  left: -3px;
}
.company_report .switch_icon_container {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 20px;
}
.company_report .square_content .icon.switch_icon {
  position: relative;
  top: auto;
  right: auto;
  color: #2a202f;
  z-index: 4;
  width: 20px;
  height: 20px;
  border: 2px solid #c7becd;
  background-color: #faf9fb;
  margin: 3px;
  padding: 3px;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 50%;
}
.company_report .square_content .icon.switch_icon.active {
  border-color: #c7becd;
  background-color: #c7becd;
  border-radius: 50%;
}
.company_report .info_icon {
  border-radius: 50%;
  background-color: #c7becd;
  width: 16px;
  height: 16px;
  padding: 4px;
  box-sizing: border-box;
  color: #fff;
}
.company_report .chart_circle .h3_holder h3 {
  opacity: 0;
  transition: opacity 0.7s ease;
  z-index: 8;
  position: absolute;
}
.company_report .chart_circle .h3_holder h3.active {
  opacity: 1;
  z-index: 4;
}
.h3_holder {
  margin-top: 60px;
  height: 30px;
  width: 100%;
}
.company_report .inner_circle {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
}
.company_report .inner_circle .h3_holder h3 {
  padding-right: 0;
}
.company_report .below_circle {
  margin-top: 25px;
}
.company_report .chart_circle .units {
  font-size: 20px;
  font-family: "futura-pt-demi", sans-serif;
  font-weight: 600;
}
.company_report .chart_circle .h3_holder h3 {
  font-size: 30px;
}
.company_report .chart_circle .h3_holder h3.large {
  font-size: 50px;
}
.company_report .chart_circle_colour1 .circle {
  stroke: #a81758;
}
.company_report .chart_circle_colour2 .circle {
  stroke: #560849;
}
.company_report .chart_circle_colour3 .circle {
  stroke: #290041;
}

.leaders {
  position: relative;
  min-height: 289px;
}

.leaders-heads {
  position: relative;
  width: 282px;
  min-width: 282px;
  margin-left: -10px;
  margin-top: 17px;
}

.leader {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.leader-0 {
  top: 0;
  left: 50%;
  right: 50%;
  z-index: 2;
}

.leader-1 {
  top: 40px;
  left: 4px;
}

.leader-2 {
  top: 40px;
  right: 4px;
}

.leader-avatar {
  border-radius: 50%;
  background-color: #403546;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 44px;
}

.leader-avatar-outer {
  background: #281d2d;
}

.leader-number {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
}
.leader-avatar-outer-0 {
  width: 133px;
  height: 133px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.leader-avatar-0 {
  width: 125px;
  height: 125px;
}

.leader-avatar-1,
.leader-avatar-2 {
  width: 96px;
  height: 96px;
}
.leader-progress.leader-progress-0 {
  font-size: 20px;
}

.leader-progress,
.leader-progress-unit {
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
}

.leader-progress.inactive,
.leader-progress-unit.inactive {
  color: #463b4c;
}

.leader-progress-unit.other {
  font-size: 12px;
}

.leader-progress-unit {
  margin-top: -10px;
}

.leaders .feature-btn.topLeaders {
  position: absolute;
  margin-bottom: 0;
  bottom: 0;
  left: 46px;
}

@media (min-width: 1250px) and (max-width: 1530px) {
  .leaders {
    margin-left: -20px;
  }
}

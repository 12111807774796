.top_three_row {
    border-bottom: 1px solid #ccc;
    padding: 15px 20px;
    vertical-align:top;
    font-family: 'futura-pt-demi', sans-serif;
    font-weight:600;
    display:flex;
    align-items: center;
    text-transform: capitalize;
}
.top_three_row span {
    font-size: 30px;
    color: #62bf92;
    margin-right:8px;
}
.top_three_row:last-of-type {
    border-bottom:none;
    padding-bottom:0;
}
.top_three .sub_head {
    margin-top:10px;
    display:block;
    color: #332938;
}

.carousel_wrapper{
  position: relative;
}

.carousel{
    height: 650px;

    overflow:hidden;
}

.inner {
    /* position: relative; */
    width: 860px;
    height: 750px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* transition: transform 0.3s; */
    /* animation: scroll linear infinite; */
}

.carousel-item{
    /* display: block; */
    align-items: center;
    /* width: 100%;
    height: 100%; */
    /* border: solid 10px gray;
    background-color: green;
    color: white; */
}

.inner .secondary_text{
    -webkit-animation: fade-in-bottom 0.7s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in-bottom 0.7s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.inner .secondary_text.fadeOut {
  -webkit-animation: fade-out-top 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: fade-out-top 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.inner .tiles_container{
  padding-top: 30px;
  display: grid;
  grid-template-columns: repeat(5, 160px);
  grid-template-rows: repeat(5, 67.5px);
  gap: 25px;
  place-items: center;
}

.inner .tiles_container img{
  height: 160px;
  width: 160px;
  border-radius: 11px;
}

.inner .tiles_container .tile0{
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 3;
  -webkit-animation: fade-in-bottom 0.7s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: fade-in-bottom 0.7s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation-delay: 250ms;
}

.inner .tiles_container .tile1{
  grid-column-start:1;
  grid-column-end: 2;
  grid-row-start: 3;
  grid-row-end: 5;
  -webkit-animation: fade-in-bottom 0.7s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: fade-in-bottom 0.7s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation-delay: 500ms;
}

.inner .tiles_container .tile2{
  grid-column-start:2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 4;
  -webkit-animation: fade-in-bottom 0.7s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: fade-in-bottom 0.7s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation-delay: 750ms;
}

.inner .tiles_container .tile3{
  grid-column-start:2;
  grid-column-end: 3;
  grid-row-start: 4;
  grid-row-end: 6;
  -webkit-animation: fade-in-bottom 0.7s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: fade-in-bottom 0.7s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation-delay: 1000ms;
}

.inner .tiles_container .tile4{
  grid-column-start:3;
  grid-column-end: 4;
  grid-row-start: 3;
  grid-row-end: 5;
  -webkit-animation: fade-in-bottom 0.7s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: fade-in-bottom 0.7s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation-delay: 1250ms;
}

.inner .tiles_container .tile5{
  grid-column-start:4;
  grid-column-end: 5;
  grid-row-start: 2;
  grid-row-end: 4;
  -webkit-animation: fade-in-bottom 0.7s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: fade-in-bottom 0.7s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation-delay: 1500ms;
}

.inner .tiles_container .tile6{
  grid-column-start:4;
  grid-column-end: 5;
  grid-row-start: 4;
  grid-row-end: 6;
  -webkit-animation: fade-in-bottom 0.7s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: fade-in-bottom 0.7s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation-delay: 1750ms;
}

.inner .tiles_container .tile7{
  grid-column-start: 5;
  grid-column-end: 6;
  grid-row-start: 1;
  grid-row-end: 3;
  -webkit-animation: fade-in-bottom 0.7s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: fade-in-bottom 0.7s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation-delay: 2000ms;
}

.inner .tiles_container .tile8{
  grid-column-start:5;
  grid-column-end: 6;
  grid-row-start: 3;
  grid-row-end: 5;
  -webkit-animation: fade-in-bottom 0.7s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: fade-in-bottom 0.7s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation-delay: 2250ms;
}

.inner .tiles_container .tile0.fadeOut{
  -webkit-animation: fade-out-top 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: fade-out-top 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation-delay: 250ms;
}

.inner .tiles_container .tile1.fadeOut{
  -webkit-animation: fade-out-top 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: fade-out-top 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation-delay: 500ms;
}

.inner .tiles_container .tile2.fadeOut{
  -webkit-animation: fade-out-top 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: fade-out-top 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation-delay: 750ms;
}

.inner .tiles_container .tile3.fadeOut{
  -webkit-animation: fade-out-top 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: fade-out-top 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation-delay: 1000ms;
}

.inner .tiles_container .tile4.fadeOut{
  -webkit-animation: fade-out-top 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: fade-out-top 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation-delay: 1250ms;
}

.inner .tiles_container .tile5.fadeOut{
  -webkit-animation: fade-out-top 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: fade-out-top 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation-delay: 1500ms;
}

.inner .tiles_container .tile6.fadeOut{
  -webkit-animation: fade-out-top 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: fade-out-top 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation-delay: 1750ms;
}

.inner .tiles_container .tile7.fadeOut{
  -webkit-animation: fade-out-top 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: fade-out-top 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation-delay: 2000ms;
}

.inner .tiles_container .tile8.fadeOut{
  -webkit-animation: fade-out-top 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: fade-out-top 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation-delay: 2250ms;
}

@-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes fade-out-top {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
    opacity: 0;
  }
}

@keyframes fade-out-top {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
    opacity: 0;
  }
}



.indicators {
  position:absolute;
  top: 160px;
    display: flex;
    justify-content: center;
}

.indicators div {
    margin: 7.5px;
    height: 10px;
    width: 10px;
    border-radius: 5.5px;
    background-color: #544b58;
    cursor: pointer;
}

.indicators div.active{
    background-color: white;
}


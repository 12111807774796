#scroll_fade_bottom {
  width: 100%;
  height: 130px;
  background-image: linear-gradient(
    to top,
    rgba(38, 28, 44, 1) 20%,
    rgba(38, 28, 44, 0)
  );
  position: fixed;
  bottom: 20px;
  right: 0;
  z-index: 1;
  pointer-events: none;
}

#progress_navbar_container {
  position: fixed;
  right: 0;
  bottom: 0px;
  height: 21px;
  z-index: 3;
  width: 100%;
  background-color: rgba(38, 28, 44, 1);
  box-sizing: border-box;
}
#progress_navbar_container .icon_navbar {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  position: absolute;
  top: -120px;
  text-align: center;
  width: 100%;
  z-index: 2;
  box-sizing: border-box;
  pointer-events: none;
}

#progress_navbar_container .icon_navbar_item {
  display: inline-block;
  margin: 0 20px;
  text-align: center;
  width: 60px;
  vertical-align: top;
  cursor: pointer;
}

#progress_navbar_container .icon_navbar_item a {
  color: #fff;
  font-family: "futura-pt-medium", sans-serif;
  font-weight: 500;
  font-size: 16px;
}

#progress_navbar_container .icon_navbar_item .icon-cont {
  color: #fff;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid #e92263;
  background-color: #64596b;
  margin-bottom: 5px;
  padding: 13px;
  box-sizing: border-box;
  transition: all 0.5s ease;
}

#progress_navbar_container .icon_navbar_item .icon-cont .icon {
  color: #fff;
  width: 30px;
  height: 30px;
  transition: all 0.1s ease;
}

#progress_navbar_container .icon_navbar_item:hover .icon-cont,
#progress_navbar_container .icon_navbar_item.active .icon-cont,
#progress_navbar_container .icon_navbar_item .active.icon-cont {
  border: none;
  padding: 14px;
  background-image: -moz-linear-gradient(
    45deg,
    rgb(232, 35, 99) 0%,
    rgb(168, 23, 88) 100%
  );
  background-image: -webkit-linear-gradient(
    45deg,
    rgb(232, 35, 99) 0%,
    rgb(168, 23, 88) 100%
  );
  background-image: linear-gradient(
    45deg,
    rgb(232, 35, 99) 0%,
    rgb(168, 23, 88) 100%
  );
  .icon {
    width: 32px;
    height: 32px;
  }
}

.icon_navbar .svg_icon {
  display: inline-block;
  background-size: 28px;
  background-repeat: no-repeat;
  background-position: center center;
}

@media (max-width: 1050px) {
  #progress_navbar_container .icon_navbar_item {
    margin: 0 10px;
  }
}
@media (max-width: 500px) {
  #progress_navbar_container .icon_navbar {
    top: -95px;
  }

  #scroll_fade_bottom {
    height: 70px;
    width: 100%;
    background-image: linear-gradient(
      to top,
      rgba(38, 28, 44, 1) 70%,
      rgba(38, 28, 44, 0)
    );
  }
  #progress_navbar_container .icon_navbar {
    top: -75px;
  }

  #progress_navbar_container .icon_navbar_item {
    margin: 0 5px;
  }
}

@media (max-width: 425px) {
  #progress_navbar_container .icon_navbar_item {
    width: 54px;
    font-size: 14px;
  }

  #progress_navbar_container .icon_navbar_item .icon {
    width: 54px;
    height: 54px;
  }

  #progress_navbar_container .icon_navbar {
    scale: 0.8;
  }
}

@media (max-width: 380px) {
  #progress_navbar_container .icon_navbar {
    scale: 0.7;
  }
}
@media (max-width: 350px) {
  #scroll_fade_bottom {
    height: 70px;
  }

  #progress_navbar_container .icon_navbar {
    top: -65px;
  }

  #progress_navbar_container .icon_navbar_item {
    width: 48px;
    font-size: 12px;
  }

  #progress_navbar_container .icon_navbar_item .icon {
    width: 48px;
    height: 48px;
    padding: 12px;
  }
}

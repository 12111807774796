.autoplay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  color: #fff;
  background-color: #281d2dba;
}

.timer {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  font-family: "futura-pt-demi";
}

.countdown-cont {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: auto;
  cursor: pointer;
}

.countdown-crcl {
  position: absolute;
  top: -11px;
  left: -6px;
  width: 100%;
  height: 100%;
  transform: rotateY(-180deg) rotateZ(-90deg);
  overflow: visible;
}

.start-btn {
  font-size: 25px;
  margin: 10px auto 15px;
  display: block;
  color: #fff;
}

.countdown-sec {
  color: #fff;
  font-size: 40px;
  z-index: 50;
}

.countdown-sec .play-icon {
  margin-left: 10px;
}

.countdown-sec .play-icon.hidden {
  visibility: hidden;
}
.autoplay .cancel {
  font-family: "futura-pt-medium";
  letter-spacing: 1px;
  margin-top: 15px;
  font-size: 15px;
  text-transform: uppercase;
  cursor: pointer;
}

.top-three-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 15px;
}

/* .top-three-list .row {
  width: 100%;
} */

.top-three-list .row .row-title {
  color: #ddcedb;
  font-family: "futura-pt-medium";
  font-size: 15px;
  text-transform: uppercase;
  margin: 15px 0 0 0;
}

.top-three-list .row .row-content {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}

.top-three-list .row .row-content .progress-container {
  width: 75%;
  height: 15px;
  background-color: #64596b;
  border-radius: 8px;
}

.top-three-list .row .row-content .progress {
  background-color: #e92364;
  height: 15px;
  border-radius: 8px;
}

.top-three-list .row .row-content .values {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-end;
}

.top-three-list .row .row-content .value,
.top-three-list .row .row-content .units {
  color: #fff;
}

.top-three-list .row .row-content .units {
  margin-left: 7.5px;
}

.charts-box.water {
  justify-content: space-evenly;
}

.chart-container.double-row {
  position: relative;
}

.chart-container.double-row .title-container.fin {
  justify-content: center;
}

.chart-container.double-row .title-container.fin .wtf {
  position: absolute;
  right: 30px;
}

.glasses-cont {
  width: 188px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "futura-pt-demi";
}

.glasses-cont .glass-no {
  font-size: 74px;
  color: #41c0d3;
}

.glasses-cont .glass-descr {
  font-size: 31px;
  color: #fff;
  text-align: center;
}

@media (max-width: 820px) {
  .chart-container.double-row .title-container.fin .wtf {
    right: 10px;
  }
}

@media (max-width: 620px) {
  .charts-box.water {
    flex-direction: column;
  }
}

.alc-line {
  display: flex;
  flex-direction: row;
  width: 100%;
  column-gap: 30px;
  align-items: flex-start;
}

.alc-line .part-line {
  position: relative;
}

.alc-line .part-line:first-child {
  flex-grow: 1;
}

.alc-line .part-line:last-child {
  display: flex;
  padding-top: 15px;
}

.alc-line .part-line .bottom-row {
  display: flex;
  flex-direction: row;
  align-items: self-end;
  margin-top: 60px;
  column-gap: 15px;
}

.alc-line .part-line .bottom-row .column {
  border-bottom: 2px solid #ddcedb;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  padding-bottom: 18px;
  justify-content: space-between;
  height: 20px;
  position: relative;
}

.alc-line .custom-select {
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0;
  background-color: #fff;
  border: 2px solid #ddcedb;
  font-family: "futura-pt-demi";
  font-size: 20px;
  border-radius: 27px;
  height: fit-content;
}

.alc-line .custom-select.bev {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
  border: none;
  color: #f69358;
  height: fit-content;
}

.alc-line .custom-select .selected {
  color: #281d2d;
  padding: 13px 25px 13px 30px;
  cursor: pointer;
  list-style: none;
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.alc-line .custom-select.bev .selected {
  color: #f69358;
  padding: 0;
  justify-content: center;
  position: relative;
}

.alc-line .custom-select.bev .selected .column-bev-icon {
  color: #281d2d;
  position: absolute;
  right: 0;
}

.alc-line .custom-select .list-cont.hide {
  display: none;
}

.alc-line .custom-select .list-cont {
  padding: 0.5rem 0;
}

.alc-line ul {
  list-style-type: none;
  width: 570px;
  margin: 0;
  padding-left: 30px;
  box-sizing: border-box;
  border-radius: 5px;
  max-height: 200px;
  overflow-y: auto;
}

.alc-line ul.small {
  list-style-type: none;
  width: 176px;
  padding: 0;
}

.alc-line ul::-webkit-scrollbar {
  width: 7px;
}

.alc-line ul::-webkit-scrollbar-track {
  background: #f1ebf4;
  border-radius: 4px;
}

.alc-line ul::-webkit-scrollbar-thumb {
  background-color: #64596b;
  border-radius: 4px;
}

.alc-line li {
  margin: 0;
  padding: 0.2rem 0;
  font-family: "futura-pt-book";
  cursor: pointer;
}

.alc-line .custom-select.bev li {
  text-align: center;
  cursor: pointer;
}

.alc-line li:first-child {
  padding-top: 0;
}

.alc-line li:last-child {
  padding-bottom: 0;
  border-bottom: none;
}
.alc-line .icon {
  height: 18px;
  align-self: self-end;
  cursor: pointer;
}
.alc-line .part-line p {
  padding-bottom: 3px;
}
/* .alc-line .icon.clock {
  padding: 0 10px 5px 0;
} */

.alc-line .part-line .number {
  border: none;
  color: #f69358;
  font-size: 20px;
  font-family: "futura-pt-demi";
  /* text-align: center; */
  height: 42px;
  outline-style: none;
}

.alc-line .icon.times {
  color: #938e96;
  height: 22.5px;
}
.add-line .icon-cont {
  height: 46px;
  width: 46px;
  border-radius: 50%;
  border: 2px solid #ddcedb;
  line-height: 22.5px;
  text-align: center;
  display: inline-block;
  margin: 0 15px 15px 0;
}

.add-line .icon-cont .icon {
  height: 22.5px;
}

.add-line div:last-child {
  display: inline-block;
  font-size: 20px;
}

button.no-background-btn {
  padding: 20px 0;
  text-align: center;
  text-transform: uppercase;
  border-radius: 30px;
  font-family: "futura-pt-book", sans-serif;
  cursor: pointer;
  border: none;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.15em;
  margin-bottom: 20px;
  transition: all 0.5s ease;
  outline: none;
  background-color: white;
  color: #290140;
  box-shadow: none;
}

button.no-background-btn .svg-inline--fa {
  margin-top: 0;
}

button.no-background-btn .icon.left {
  margin: 3px 10px 0 0;
  transition: all 0.5s ease;
}
button.no-background-btn:hover .icon.left {
  margin-right: 15px;
  margin-left: -5px;
}

@media (max-width: 750px) {
  button.no-background-btn {
    padding: 0;
    height: 40px;
    border-radius: 30px;
    font-size: 12px;
    letter-spacing: 0.12em;
  }
}

/*dhydration*/
.dot_radio.dehydration {
    display:flex;
}
.dot_radio.dehydration .input_wrap {
    text-align: center;
    flex: auto;
    color: #80738a;  
    position:relative;
    cursor: pointer;
}
.dot_radio.dehydration input[type="radio"]:checked+label, .dot_radio.dehydration .input_wrap:hover label {
    width: 38px;
    height:38px;
    margin: 1px;   
}
.dot_radio.dehydration input[type="radio"]:checked+label::after {
    content: "";
    width: 12px;
    border-bottom:1px solid #80738a;
    position:absolute;
    top:67px;
    margin-left:-6px;
}
#dehydration1+label {
    background-color:#fdffec;
}
#dehydration2+label {
    background-color:#fdfcc5;
}
#dehydration3+label {
    background-color:#f6f886;
}
#dehydration4+label {
    background-color:#fbf000;
}
#dehydration5+label {
    background-color:#eff300;
}
#dehydration6+label {
    background-color:#dcde00;
}
#dehydration7+label {
    background-color:#ffd400;
}
#dehydration8+label {
    background-color:#d6a500;
}
.chapters-popup {
  background-color: #281d2d;
  position: absolute;
  padding: 20px 18px 0 20px;
  border-radius: 12px;
  bottom: 110px;
  right: -125px;
  z-index: 53;
}

.chapters-popup .chapters-heading {
  width: 320px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}

.chapters-popup .chapters-heading .title {
  font-family: "futura-pt-demi";
  font-size: 25px;
  color: #fff;
}

.chapters-popup .chapters-heading .times {
  color: #ddcedb;
  height: 25px;
  width: 25px;
  cursor: pointer;
}

.chapters-popup .scrollable {
  overflow-y: scroll;
  height: 365px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  margin-bottom: 20px;
}

.chapters-popup .scrollable::-webkit-scrollbar {
  display: none;
}

#cover-page .chapters-popup button.chapter {
  background-color: #372c3d;
  font-family: "futura-pt-demi";
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  margin: 8px 0 8px 0;
  align-items: center;
  border:none;
}

.chapters-popup .chapter:hover {
  background-color: #64596b;
}

.chapters-popup .chapter .top-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.chapters-popup .chapter .top-row .constant-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.chapters-popup .chapter .top-row .icon.chevron {
  color: #fff;
  cursor: pointer;
}

.chapters-popup .chapter .number {
  color: #ddcedb;
  background-color: #281d2d;
  font-size: 17.5px;
  height: 28px;
  width: 28px;
  border-radius: 50%;
  margin-right: 10px;
  line-height: 28px;
  text-align: center;
}

.chapters-popup .chapter .title {
  color: #fff;
  font-size: 15px;
}

.chapters-popup .chapter-progress-container {
  width: 320px;
  height: 6px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 3px;
}

.chapters-popup .chapter-progress-container .progress-bar {
  background-color: #ea2364;
  height: 6px;
  border-radius: 3px;
  width: 0%;
  max-width: 100%;
}

.chapters-popup .chapter + .see-more {
  display: none;
}

.chapters-popup .chapter.expanded + .see-more {
  display: block;
}

#cover-page .chapters-popup button.chapter.expanded {
  margin-bottom: 0;
  border-radius: 12px 12px 0 0;
}

#cover-page .chapters-popup button.chapter:hover{
  background-color: #64596b;
}

.chapters-popup .chapter + .see-more {
  background-color: #372c3d;
  margin-bottom: 8px;
  border-radius: 0 0 12px 12px;
  padding: 10px;
  width: calc(100% - 20px);
}
.chapters-popup .chapter:hover + .see-more {
  background-color: #64596b;
}

.chapters-popup .chapter + .see-more p {
  font-family: "futura-pt-book";
  font-size: 17px;
  color: #ddcedb;
}

.see-more .time {
  margin-top:0;
}

.chapters-popup .chapter + .see-more button {
  width: 100%;
}

#cover-page .chapters-popup button.see-more-button{
  padding: 10px 30px;
  text-align: center;
  border-radius: 25px;
  font-family: "futura-pt-demi", sans-serif;
  cursor: pointer;
  border: 1px solid #E3165B;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 20px;
  background-color: #e92263;
  color: #fff;
}

#cover-page .chapters-popup button.see-more-button:hover {
  background-color: #fff;
  border: 1px solid #e92263;
  color: #e92263;
}

.top-row .number.prog {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 28px;
}

/* .top-row .number.prog .icon-cont {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
} */

.top-row .number.prog .prog-icon {
  vertical-align: unset;
}

@media (min-width: 1150px) {
  #masterclass-container .chapters-popup.player {
    max-width: 320px;
  }
}

@media (max-width: 1150px) {
  #masterclass-container .chapters-popup.player {
    width: 45%;
    align-self: center;
  }

  #masterclass-container .chapters-popup .chapter-progress-container {
    width: 100%;
  }
 #masterclass-container .chapters-popup .see-more {
    width: calc(100% - 20px);
  }
}

@media (max-width: 700px) {
  .chapters-popup {
    left: -210px;
    right: 0;
    z-index: 5;
    bottom: 46px;
    padding: 20px 10px 0 10px;
  }
  .chapters-popup .chapters-heading .title {
    font-size: 20px;
  }
  .chapters-popup .chapter {
    width: 200px;
  }

  .chapters-popup .chapter .number,
  .chapters-popup .chapter .title {
    font-size: 12px;
  }
}

@media (max-width: 600px) {
  #masterclass-container .chapters-popup.player {
    width: 100%;
  }
}

.circular-slider {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 45px;
}
.slider-labels {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.slider-labels div {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.slider-labels div p {
  padding: 0 20px;
}

.slider-labels div .icon {
  height: 35px;
  width: 35px;
  color: rgb(221, 206, 219);
}
.slider-labels div .sub.title {
  padding-top: 0;
  padding-bottom: 0;
}

.challenge .chart-container .title-container.leader-board {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.chart-container .leader-board {
  flex-direction: row;
}

.switcher-container .subtitle {
  margin-right: 22px;
}

.challenge .chart-container .leader-board.title-container .title {
  font-size: 37px;
  font-weight: 500;
  line-height: 25px;
  margin-bottom: 0;
}

.switcher-container {
  display: flex;
  align-items: center;
}

.leader-board .whc-switch {
  width: 54px;
  height: 27px;
  background-color: #64596b;
  border: solid 4px #64596b;
}

.leader-board .whc-switch.on {
  background-color: #41c0d3;
  border: solid 4px #41c0d3;
}

.leader-board .whc-switch.on .whc-slider-button {
  transform: translateX(27px);
}

.leader-board .whc-slider-button {
  width: 27px;
  height: 27px;
}

.leaders-content-wrapper {
  display: flex;
  justify-content: space-between;
}

.leaders-noleaders {
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
  max-width: 320px;
  margin-top: 86px;
}

.chart-container.leaderboard.inactive {
  order: 5;
}

@media (max-width: 1250px) and (max-width: 1500px) {
  .challenge .page-cont .gradient-overlay .hero-contents .hero-title {
    margin-top: 10px;
  }
}

@media (min-width: 1250px) and (max-width: 1500px) {
  .challenge .chart-container .title-container .subtitle {
    font-size: 18px;
    margin-right: 14px;
  }
}

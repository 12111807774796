button.darkButton {
  /* padding: 1px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s;
  box-sizing: border-box;
  cursor: pointer; */
  /* text-align: center;
  border-radius: 25px;
  font-family: "futura-pt-demi", sans-serif;
  cursor: pointer;
  border: 1px solid #281d2d;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 20px;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
  transition: all 0.5s ease;
  outline: none;
  background-color: #281d2d;
  color: #fff; */
}

.toggle {
  border-radius: 50%;
  transition: transform 0.3s;
  margin: 4px;
}

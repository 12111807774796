@font-face {
  font-family: "futura-pt-demi";
  src: url("../../assets/fonts/FuturaPT/FuturaPT-Demi.ttf") format("truetype");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "futura-pt-medium";
  src: url("../../assets/fonts/FuturaPT/FuturaPT-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "futura-pt-book";
  src: url("../../assets/fonts/FuturaPT/FuturaPT-Book.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "futura-pt-light";
  src: url("../../assets/fonts/FuturaPT/FuturaPT-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
}
html,
body {
  padding: 0;
  margin: 0;
  background-color: #fff;
  color: #290140;
  /*font-family: 'futura-pt-medium', sans-serif;*/
  font-family: "futura-pt-book";
}
h1,
h2,
h3,
span {
  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
}
h1,
h2,
h3 {
  font-family: "futura-pt-demi";
  margin-top: 0;
  font-weight: 600;
}
a {
  cursor: pointer;
  text-decoration: none;
  outline: none;
  pointer-events: auto;
}
p a {
  text-decoration: underline;
}
::placeholder {
  opacity: 1;
}

.assessment #left_container,
.register.profile #left_container {
  width: 40%;
  overflow: hidden;
  clip-path: ellipse(100% 150% at 0 50%);
  margin-left: -70px;
  background-color: #a81758;
}

.register #left_container,
.signin #left_container {
  width: 50%;
  background-color: #261d2d;
}

.assessment #left_container,
.register #left_container,
.signin #left_container {
  text-align: center;
  position: fixed;
  top: 0;
  height: 100vh;
  z-index: 5;
}

.assessment #left_container .video {
  position: absolute;
  left: 50%; /* % of surrounding element */
  top: 50%;
  transform: translate(-50%, -50%); /* % of current element */
  -webkit-transition: 0.8s;
  -moz-transition: 0.8s;
  -o-transition: 0.8s;
  transition: 0.8s;
  visibility: hidden;
  opacity: 0;
  min-height: 100%;
  min-width: 100%;
}
.assessment #left_container .video.active {
  visibility: visible;
  opacity: 1;
}

.assessment #right_container,
.register.profile #right_container {
  padding-left: 40%;
  padding-right: 50px;
}
.register #right_container,
.signin #right_container {
  padding-left: 50%;
}
.assessment #right_container,
.register #right_container,
.signin #right_container {
  vertical-align: top;
  position: relative;
  padding-bottom: 0px;
}
.question_container {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 50px;
  display: flex;
  flex-wrap: wrap;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  visibility: hidden;
  opacity: 0;
  height: 0;
  position: absolute;
}
.question_container.active {
  visibility: visible;
  opacity: 1;
  height: auto;
  padding-top: 110px;
  padding-bottom: 350px;
  position: relative;
}
.question_container h2 {
  font-size: 50px;
  line-height: 110%;
  margin: 10px 0 30px 0;
  font-family: "futura-pt-demi";
  font-weight: 600;
  width: 100%;
}
.question_container p {
  font-size: 18px;
  margin: 10px 0 50px 0;
  font-family: "futura-pt-demi", sans-serif;
  font-weight: 600;
  width: 100%;
}
.question_container .suffixLabel {
  background-color: #f6f5f7;
  border-radius: 12px;
  color: #7e658c;
  cursor: pointer;
  display: inline-block;
  font-family: futura-pt-book, sans-serif;
  font-size: 14px;
  font-weight: 400;
  margin-left: 10px;
  padding: 2px 10px;
  vertical-align: top;
  border: none;
  box-shadow: none;
}
.question_container .suffixLabel_hours {
  margin: 0 10px;
}
.question_container .suffixLabel.active,
.question_container .suffixLabel:hover {
  color: #fff;
  background-color: #a81758;
}

.question_block,
.question_block_wide {
  padding: 0 15px 50px 5px;
  box-sizing: border-box;
  opacity: 1;
  -webkit-transition: opacity 300ms ease-in;
  -ms-transition: opacity 300ms ease-in;
  transition: opacity 300ms ease-in;
}
.question_block {
  flex: 0 0 50%;
}
.question_block_wide {
  flex: 0 0 100%;
}
.question_block.center,
.question_block_wide.center {
  text-align: center;
}
.question_block h3,
.question_block_wide h3,
.assessment h3 {
  font-family: "futura-pt-medium", sans-serif;
  color: #a81758;
  font-size: 18px;
  display: inline;
  margin-right: 10px;
  font-weight: 500;
}

.question_block label.alcoholLabel {
  display: block;
  margin-top: 1.33em;
  margin-bottom: 1.33em;
  margin-right: 10px;
  font-weight: 700;
  color: #a81758;
}

label.titleSuffix,
span.titleSuffix {
  color: #7c7283;
  margin-bottom: 5px;
  /*display:inline;*/
  display: inline-block;
  font-size: 14px;
  font-family: "futura-pt-book", sans-serif;
  font-weight: 400;
  margin-right: 10px;
}
input[type="text"],
select,
input[type="number"],
input[type="radio"] + label,
input[type="checkbox"] + label {
  font-family: "futura-pt-medium", sans-serif;
  font-size: 15px;
  font-weight: 500;
  background-color: transparent;
}
/*admin search fix*/
.MuiFormControl-root input[type="text"],
.MuiFormControl-root input[type="text"]::placeholder,
.MuiFormControl-root label,
.MuiFormControl-root textarea,
.MuiFormControl-root textarea::placeholder,
.MuiFormControl-root input,
.MuiSelect-selectMenu.MuiInputBase-input,
span.MuiCheckbox-colorPrimary.Mui-checked {
  color: #fff;
}
.answer_block {
  display: block;
  margin-top: 20px;
}
.assessment hr {
  border: none;
  margin: 0;
  width: 100%;
}

button:not(:focus) {
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
  transition: all 0.5s ease;
  outline: none;
}
button {
  padding: 10px 30px;
  text-align: center;
  border-radius: 25px;
  font-family: "futura-pt-demi", sans-serif;
  cursor: pointer;
  border: 1px solid #e3165b;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 20px;
  background-color: #e92263;
  color: #fff;
}

button#onboarding button#onboarding:focus {
  outline: 3px solid #007bff;
  outline-offset: 2px;
  background-color: #e3165b;
  color: #fff;
}
button#onboarding:hover {
  background-color: #fff;
  color: #e3165b;
  border: solid 1px #e3165b;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}
button:hover {
  background-color: #fff;
  color: #e92263;
  border: 1px solid #e92263;
}
button.modal_trigger_container:hover {
  border: none;
}
button.modal_trigger_container:not(:focus) {
  box-shadow: none;
  transition: none;
  outline: none;
}

.question_block.login button:focus {
  background-color: #fff;
  color: #e92263;
  border: 1px solid #e92263;
}
button.large {
  font-size: 20px;
}
button .icon {
  margin-right: 0;
  transition: all 0.5s ease;
}

button.up_btn .icon,
button.down_btn .icon,
button.modal_trigger_container .icon {
  margin-left: 0;
  margin-right: 0;
  transition: none;
}
button.up_btn:hover .icon,
button.down_btn:hover .icon,
button.modal_trigger_container:hover .icon {
  margin-left: 0;
  margin-right: 0;
}
.assessment .health_wellbeing {
  background-color: #d4ccd9;
  position: fixed;
  top: 0;
  width: 40%;
  height: 100vh;
  z-index: 6;
  opacity: 0;
  -webkit-transition: 0.8s;
  -moz-transition: 0.8s;
  -o-transition: 0.8s;
  transition: 0.8s;
}
/*alcohol*/

.alcohol_info {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
}
.alcohol_info .modal_trigger_container {
  margin-bottom: 18px;
}
.assessment #Alcohol1 #alcohol_fade h3 {
  display: flex;
  justify-content: center;
  color: #a81758;
  width: 100%;
  flex: 0 0 100%;
}
.assessment #Alcohol1 h3 .modal_trigger_container {
  margin-left: 10px;
}
.assessment #Alcohol1 h4 {
  color: #a81758;
}
.assessment #Alcohol1 .third_width {
  flex: 0 0 33%;
}
.assessment #Alcohol1 .third_width .question_block {
  padding-bottom: 0px;
}
#alcohol_fade {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
  display: flex;
  width: 100%;
  flex: 0 0 100%;
  flex-wrap: wrap;
}
/*Registration pages*/
#RegisterUser .question_block {
  padding-bottom: 30px;
}
#RegisterUser .answer_block {
  margin-top: 15px;
}
.question_container p.account_link {
  margin-bottom: 20px;
  margin-top: 0;
  font-family: "futura-pt-book";
  font-weight: 300;
  width: 100%;
}
/*general fixes*/
.assessment label,
.assessment div {
  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
}
.app-banner-mobile {
  display: none;
}

#right_container .question_block_container {
  flex: 0 0 100%;
}

#right_container .question_block_container.stacked {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

#right_container .question_block_container .introduction {
  line-height: 60px;
  padding-left: 5px;
}

#right_container .question_block_container.stacked .introduction {
  width: 100%;
}

#right_container .question_block_container .req.question_block {
  width: 50%;
}

@media (max-width: 1050px) {
  .question_container {
    padding-right: 0;
  }
  .question_block,
  .question_block_wide {
    flex: 0 0 100%;
    padding-left: 0;
    padding-right: 0;
  }
  #left_container,
  #left_nav,
  img.health_wellbeing_image {
    display: none;
  }

  .assessment #left_container .video.active {
    visibility: hidden;
    display: none;
  }

  .register #right_container {
    padding-left: 2px;
  }
  #right_container,
  .assessment #right_container,
  .signin #right_container,
  .assessment #progress_footer_container {
    padding-left: 50px;
  }
}
@media (max-width: 600px) {
  .app-banner-mobile {
    display: block;
    background-color: #a81758;
    border-radius: 30px;
    padding: 20px;
    color: #fff;
  }
  .app-banner-mobile p {
    margin: 10px 0 10px 0;
  }
  img.app_link.google_play {
    width: 130px;
    border-radius: 6px;
    margin-right: 10px;
    border: white solid 1px;
  }
  img.app_link.app_store {
    width: 118px;
    border-radius: 6px;
    border: white solid 1px;
  }
}
@media (max-width: 500px) {
  #right_container,
  .assessment #right_container {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.headerSettingsLink {
  float: right;
  width: 100px;
  height: 60px;
}

button.greyButton {
  padding: 10px 30px;
  text-align: center;
  border-radius: 25px;
  font-family: "futura-pt-demi", sans-serif;
  cursor: pointer;
  border: none;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 20px;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
  transition: all 0.5s ease;
  outline: none;
  background-color: rgba(100, 89, 107, 0.5);
  color: #fff;
}

button.greyButton.download,
button.greyButton.support {
  background-color: #64596b;
}

button.greyButton.caps {
  text-transform: uppercase;
}

button.greyButton .svg-inline--fa {
  margin-top: 0;
}

button.greyButton:hover {
  border: 1px solid rgba(100, 89, 107, 0.5);
  background-color: #fff;
  color: #64596b;
}

button.greyButton.download:hover,
button.greyButton.support:hover {
  border: none;
  color: #e92263;
}

button.greyButton .icon {
  margin-left: 5px;
  margin-right: 0;
  margin-top: 3px;
  transition: all 0.5s ease;
}
button.greyButton:hover .icon {
  margin-left: 10px;
  margin-right: -5px;
}

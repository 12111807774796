.title-container .lines-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 60px;
  align-items: center;
}

.title-container .lines-container .mw-subt {
  width: 100%;
  font-size: 15px;
  margin-bottom: -20px;
  text-transform: capitalize;
}

.title-container .lines-container .line {
  width: 50px;
  height: 7.5px;
  border-radius: 4px;
  margin-right: 10px;
  position: relative;
}

.title-container .lines-container .line .journal-tooltip {
  width: 150px;
  height: fit-content;
  border-radius: 6.5px;
  position: absolute;
  z-index: 1;
  background-color: #64596b;
  padding: 8px;
  top: 20px;
  left: -15px;
  &::before {
    content: "";
    position: absolute;
    top: -15px;
    left: 25%;
    transform: translateX(-50%);
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent #64596b transparent;
  }
}

.title-container .lines-container .line .journal-tooltip p {
  font-size: 10px;
  font-family: "futura-pt-book";
  text-transform: uppercase;
  margin: 0;
}

.title-container .lines-container .line .journal-tooltip div {
  font-size: 15px;
  font-family: "futura-pt-medium";
  text-transform: capitalize;
}

.title-container .date-box {
  align-self: center;
  height: 30px;
  width: 30px;
  border: solid rgba(98, 90, 106, 0.95) 1px;
  border-radius: 5px;
  font-family: "futura-pt-medium";
  font-size: 15px;
  line-height: 15px;
  text-transform: uppercase;
  padding: 12px;
  text-align: center;
}

.chart-container.tile .journal-content {
  font-family: "futura-pt-book";
  font-size: 20px;
  color: #ddcedb;
  overflow: hidden;
  text-overflow: clip;
}

.chart-container.tile .journal-content.clipped {
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
}

.chart-container.tile span {
  color: #fff;
  font-family: "futura-pt-book";
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
}

.journal-button-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

button.turquoiseButton {
  padding: 18px 38px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 30px;
  font-family: "futura-pt-book", sans-serif;
  cursor: pointer;
  border: none;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.15em;
  margin-bottom: 25px;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
  transition: all 0.5s ease;
  outline: none;
  background-color: #41c0d3;
  color: #fff;
  border: 1px solid #41c0d3;
}

button.turquoiseButton .svg-inline--fa {
  margin-top: 0;
}

button.turquoiseButton:hover {
  border: 1px solid #41c0d3;
  background-color: #fff;
  color: #41c0d3;
}
button.turquoiseButton .icon {
  margin-left: 5px;
  margin-right: 0;
  margin-top: 3px;
  transition: all 0.5s ease;
}
button.turquoiseButton:hover .icon {
  margin-left: 10px;
  margin-right: -5px;
}

@media (max-width: 750px) {
  button.turquoiseButton {
    padding: 0 30px;
    height: 40px;
    border-radius: 30px;
    font-size: 12px;
    letter-spacing: 0.12em;
  }

  button.turquoiseButton:hover {
    padding: 13px 28px;
  }
}

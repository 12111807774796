.line {
  display: flex;
  flex-direction: row;
  width: 100%;
  column-gap: 30px;
  height: 70px;
  align-items: flex-end;
}

.line .part-line:first-child {
  position: relative;
  width: 290px;
}

.line .part-line:nth-child(2) {
  border-bottom: 2px solid #ddcedb;
  display: flex;
  flex-direction: row;
  align-items: self-end;
  width: 280px;
}

.line .custom-select {
  width: 290px;
  position: absolute;
  top: -50px;
  left: 0;
  background-color: #fff;
  border: 2px solid #ddcedb;
  font-family: "futura-pt-demi";
  font-size: 20px;
  border-radius: 27px;
  height: fit-content;
}

.line .custom-select .selected {
  color: #281d2d;
  padding: 13px 25px 13px 30px;
  cursor: pointer;
  list-style: none;
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.line .custom-select .list-cont.hide {
  display: none;
}

.line .custom-select .list-cont {
  padding: 0.5rem 0;
}

.line ul {
  list-style-type: none;
  width: 259px;
  margin: 0;
  padding-left: 30px;
  box-sizing: border-box;
  border-radius: 5px;
  max-height: 200px;
  overflow-y: auto;
}

.line ul::-webkit-scrollbar {
  width: 7px;
}

.line ul::-webkit-scrollbar-track {
  background: #f1ebf4;
  border-radius: 4px;
}

.line ul::-webkit-scrollbar-thumb {
  background-color: #64596b;
  border-radius: 4px;
}

.line li {
  margin: 0;
  padding: 0.2rem 0;
  font-family: "futura-pt-book";
  cursor: pointer;
}

.line li:first-child {
  padding-top: 0;
}

.line li:last-child {
  padding-bottom: 0;
  border-bottom: none;
}
.line .icon {
  height: 30px;
}
.line .part-line p {
  padding-bottom: 3px;
}
.line .icon.clock {
  padding: 0 10px 5px 0;
}

.line .part-line input[type="number"] {
  border: none;
  color: #41c0d3;
  font-size: 40px;
  font-family: "futura-pt-demi";
  text-align: center;
  width: 200px;
  height: 42px;
  outline-style: none;
}

.line .icon.times {
  color: #938e96;
  height: 22.5px;
}
.add-line .icon-cont {
  height: 46px;
  width: 46px;
  border-radius: 50%;
  border: 2px solid #ddcedb;
  line-height: 22.5px;
  text-align: center;
  display: inline-block;
  margin: 0 15px 15px 0;
}

.add-line .icon-cont .icon {
  height: 22.5px;
}

.add-line div:last-child {
  display: inline-block;
  font-size: 20px;
}

.ind-tracker textarea.journal-input {
  width: calc(100% - 40px);
  height: 120px;
  border: solid 2px #ddcedb;
  border-radius: 2em;
  font-family: "futura-pt-book";
  font-size: 20px;
  color: #281d2d;
  padding: 20px;
  overflow-y: scroll;
  margin-top: 40px;
  resize: none;
  outline: none;
}

.ind-tracker textarea.journal-input::-webkit-scrollbar {
  width: 4px;
}

.ind-tracker textarea.journal-input::-webkit-scrollbar-track {
  border-radius: 2.5px;
  width: 4px;
  background: transparent;
}

.ind-tracker textarea.journal-input::-webkit-scrollbar-thumb {
  width: 4px;
  border-radius: 2.5px;
  background: #291e2e;
}

.ind-tracker textarea.journal-input::placeholder {
  color: #64596b;
}

.ind-tracker textarea.journal-input:focus {
  border: solid 2px #41c0d3;
  outline: none;
}

.ind-tracker textarea.journal-input.alc:focus {
  border: solid 2px #f69358;
}

/*HS*/
/*standard fix number inputs*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/*clock*/

.question_container .clock_counter .hours, .question_container .clock_counter .minutes {
    background-color: #fff;
    border-radius:25px;
    height: 50px;
    box-sizing: border-box;
    margin-right: 1px;
    color:#a81758;
    font-size:12pt;
    border:none;    
    vertical-align:top;
    text-align:center;
    outline:0;
}

.question_container .clock_counter .minutes {
    border-radius:25px ;
}
.question_container .number_controls {
    width: 25px;
    display: inline-block;
    margin-right:20px;
}
.question_container .number_controls .up_btn, .question_container .number_controls .down_btn {
    cursor:pointer;
    background-color: #C8BDCD;
    border-radius:50%;
    height: 20px;
    width:20px;
    text-align:center;
    margin: 0 5px 5px 5px;;
}
.question_container .number_controls .up_btn:hover, .question_container .number_controls .down_btn:hover {
    background-color: #6F6776;
}
.question_container .number_controls .up_btn .icon, .question_container .number_controls .down_btn .icon {
    font-size: 14px; 
    color:#f6f5f7;   
    position:relative;
}
.question_container .number_controls .up_btn .icon {
    top:-1px;
}

.question_container .number_field.hour_select .number_container {
    background-color: #f6f5f7;
    border-radius: 25px;
    height: 50px;
    box-sizing: border-box;
    margin-right: 3px;
    font-size:12pt;
    display:inline-block;
    padding: 12px 20px;
    color: #a81758;
    vertical-align:top;
    font-family: 'futura-pt-medium';
    font-weight:500;
    font-size:15px;
}
.question_container .number_field.hour_select .number_container.right {
    border-radius: 25px;

}
.question_container .number_field .number_container .number {
    outline:0;
    color:#a81758;
    border:none;  
    width: 32px;
    font-size: 20px;
    margin-right: 0px;
    text-align: center;
}


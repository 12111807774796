.whc-cont {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #f1ebf4;
  border-radius: 30px;
  padding: 30px;
  color: #64596b;
  margin-bottom: 40px;
  position: relative;
}

.whc-cont .quit {
  position: absolute;
  right: 0;
  top: 0;
  background-color: #000;
  color: #fff;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  border: solid 2px #fff;
  padding: 4px;
  cursor: pointer;
}

.whc-cont .quit .times-icon {
  height: 14px;
}

.whc-heading {
  font-family: "futura-pt-medium";
  font-size: 22.5px;
}

.whc-body {
  display: flex;
  flex-direction: column;
  width: 95%;
  margin-right: 1em;
}

.whc-text {
  font-family: "futura-pt-book";
  font-size: 20px;
  margin-top: 15px;
}

.whc-slider {
  display: contents;
}

.whc-switch {
  display: inline-block;
  position: relative;
  width: 200px;
  height: 36px;
  background-color: #000;
  border-radius: 40px;
  transition: background-color 0.2s;
  cursor: pointer;
  border: solid 4px #000;
}

.ind-tracker .whc-switch.on {
  background-color: #62bf92;
  border: solid 4px #62bf92;
}

.whc-switch.off .whc-slider-button {
  transform: translateX(0);
}

.whc-switch.on .whc-slider-button {
  transform: translateX(102px);
}

.whc-slider-button {
  position: absolute;
  width: 36px;
  height: 36px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.4s ease;
}

.whc-label {
  font-size: 15px;
  text-align: center;
  text-transform: uppercase;
  line-height: 36px;
  color: #f1ebf4;
  font-family: "futura-pt-medium";
}

.App {
  text-align: center;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

#mobile-device-overlay {
  height: 100vh;
  width: 100vw;
  background-color: #281d2d;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  align-items: center;
  overflow: hidden;
  background-image: url("./assets/images/phones.png");
  background-size: contain;
  background-position: bottom center;
  background-repeat: no-repeat;
}

.store-btn {
  height: 60px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.mobile-device-txt {
  padding-top: 30%;
  width: 80%;
  color: #fff;
  text-align: center;
  font-family: "futura-pt-demi";
  font-size: 25px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 3s ease-in-out;
  }
}

.App-header {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #282c34;
}

.App-link {
  color: #61dafb;
}

a {
  text-decoration: none;
  cursor: pointer;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

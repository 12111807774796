.chart-container.fin-well {
  height: 185px;
}

.chart-container.fin-well .rainbow-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.rainbow-box .rainbow-text {
  font-family: "futura-pt-book";
  font-size: 20px;
  color: #fff;
  width: 60%;
}

.rainbow-bars-cont {
  display: flex;
  flex-direction: row;
}

.rainbow-box .rainbow-bars {
  width: 10px;
  height: 50px;
  margin-left: 5px;
  border-radius: 5.5px;
}

@media (max-width: 1650px) {
  .chart-container.fin-well {
    height: auto;
  }
}

@media (max-width: 1250px) {
  .results-container .financial-grid {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 780px) {
  .chart-container.fin-well .rainbow-box {
    flex-direction: column;
  }
  .rainbow-box .rainbow-text {
    width: 100%;
    margin-bottom: 15px;
  }
}

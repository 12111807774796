/*NS*/
/*standard fix number inputs*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.question_container .number_controls {
    width: 25px;
    display: inline-block;
}
.question_container .number_controls .up_btn, .question_container .number_controls .down_btn {
    cursor:pointer;
    background-color: #C8BDCD;
    border-radius:50%;
    height: 20px;
    width:20px;
    text-align:center;
    margin: 0 5px 5px 5px;;
}
.question_container .number_controls .up_btn:hover, .question_container .number_controls .down_btn:hover {
    background-color: #6F6776;
}
.question_container .number_controls .up_btn .icon, .question_container .number_controls .down_btn .icon {
    font-size: 14px; 
    color:#fff;   
    position:relative;
}
.question_container .number_controls .up_btn .icon {
    top:-1px;
}
.question_container .number_field.disabled {
    opacity: .3;
}
.question_container .number_field.disabled .number_container .number{
    color: #80738a;;
}
.question_container .number_field.disabled .number_controls .up_btn, .question_container .number_field.disabled .number_controls .down_btn {
    cursor: auto;
}
.question_container .number_field.disabled .number_controls .up_btn:hover, .question_container .number_field.disabled .number_controls .down_btn:hover {
    background-color: #C8BDCD;
}
.question_container .number_field .number_container {
    background-color: #f6f5f7;
    border-radius: 25px;
    height: 50px;
    box-sizing: border-box;
    margin-right: 1px;
    font-size:15px;
    display:inline-block;
    padding: 12px 20px;
    color: #a81758;
    vertical-align:top;
}
.question_container .number_field .number_container .number {
    outline:0;
    color:#a81758;
    font-size:15px;
    border:none;  
    width: 32px;
    margin-right: 0px;
    text-align: center;
}

/*alcohol*/
.question_container .third_width .number_field .number_container, #Nutrition1 .number_field .number_container {
    width: 80%;
}
@media (max-width: 1050px) {

    .question_container .third_width .number_field .number_container {
        width:auto;
    }
}
@media (max-width: 500px) {
    .question_container .number_field .number_container {
        max-width: 60px;
        padding: 12px 10px;
    }
}

.number_field_numberSelect {
    display: flex;
    flex-direction: column
}

.number_wrapper {
    display:flex;
}
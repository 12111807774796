.rdrop_down_wrapper{
    width: 315px;
    margin-bottom: 10px;
    height: 40px;
    position: relative;
}

#right_container .frame .rdrop_down_wrapper button {
  position: absolute;
  width: 40px;
  height: 40px;
  margin-bottom: 0;
  right: -40px;
  top: -5px;
}

.frame .rdrop_down_wtf{
  height: 20px;
  width: 20px;
  color: #7e658c;
  top: 10px;
  right: -30px;
  cursor: pointer;
}

.frame div.rdrop_down.profile {
    font-family: "futura-pt-medium", sans-serif;
    font-size: 17px;
    cursor: pointer;
    transition: all 0.5s ease 0s;
}

span.rdd_title.grey{
    color: #7e658c;
}

span.rdd_title{
    width:200px;
    margin-left: 20px;
    text-align: left;
}

.frame div.rdrop_down.profile.closed .dd_suggestions {
    display: none;
  }

.rdrop_down_title_wrapper{
    background-color:#f1f1f1;
    border-radius: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

 
  .rdrop_down.profile.open {
    position: relative;
  }

  .dd.icon {
    padding: 12px 25px;
    color: #e92263;
  }
 
  .rdrop_down.profile.open .dd_suggestions{
    position: absolute;
    margin-top: 8px;
    right: 0px;
    max-height: 160px;
    width: 295px;
    background-color: #f1f1f1;
    border-radius: 20px;
    text-align: left;
    padding: 15px 0 20px 20px;
    overflow-y: scroll;
    z-index: 10;
  }
  
  .rdrop_down.profile.open .dd_suggestions::-webkit-scrollbar {   width: 35px; } 
  .rdrop_down.profile.open .dd_suggestions::-webkit-scrollbar-thumb {   border: 12px solid rgba(0, 0, 0, 0);   background-clip: padding-box;   -webkit-border-radius: 20px; border-radius: 20px;  background-color: rgba(0, 0, 0, 0.15);;  }
  
  .dd_suggestions .r_drop_down_content {
    color: #7e658c;
    margin-top: 5px;
  }
  
  .dd_suggestions .r_drop_down_content:hover {
    color: #291e2e;
  }

   .rdrop_down_wrapper .custom_select {
    width: 315px;
    background-color: #f1f1f1;
    height: 40px;
    border: none;
    border-radius: 20px;
  }

   .rdrop_down_wrapper .custom_select.focus {
    border: solid 1px #e92263
   }

  .rdrop_down_wrapper select {
    width: 275px;
    height: 40px;
    border: none;
    border-radius: 20px;
    outline: none;
    font-family: "futura-pt-medium", sans-serif;
    color: #291e2e;
    font-size: 17px;
    padding-right: 15px;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .custom_select:after {
    content: " ";
    position: absolute;
    top: 50%;
    margin-top: -2px;
    right: 18px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #291e2e;
  }

  .rdrop_down_wrapper select.disabled{
    color: #7e658c;
  }
  
  
  .rdrop_down_wrapper select option{
    background-color: #f1f1f1 !important;
    font-family: "futura-pt-medium", sans-serif;
    color: #291e2e;
    font-size: 17px;
    border-radius: 20px;
  }

  @media (max-width: 750px) or ( (min-width: 1050px) and (max-width: 1300px)) {
    .rdrop_down_wrapper,   div.rdrop_down.profile.closed, .rdrop_down_title_wrapper {
      height: 32px;
    }
   .rdrop_down_wrapper .custom_select {
    height: 32px;
  }
    .frame div.rdrop_down.profile {
       font-size: 14px;
    }
    .frame .rdrop_down_wtf {
     top: 5px;
    }

    .rdrop_down_wrapper select {
      height: 32px;
      font-size: 15px;
    }

    .rdrop_down_wrapper select option {
       font-size: 15px;
}

}
@media (max-width: 370px) {
  .rdrop_down_wrapper{
    width: 250px;
  }

  span.rdd_title{
    width: 150px;
  }

  .rdrop_down.profile.open .dd_suggestions{
    width: 230px;
  }

  .frame div.rdrop_down.profile {
    font-size: 14px;
  }

  .frame .rdrop_down_wtf {
    right: 0px;
  }

  .rdrop_down_wrapper .custom_select {
    height: 32px;
    width: 210px;
}

  .rdrop_down_wrapper select {
    height: 32px;
    font-size: 14px;
    width: 210px;
    text-indent: 20px;
}

    .custom_select:after {
      right: 55px;
    }
}
.measures {
    position:relative;
}
.measures .rightLabel {
    position:absolute;
    top:0px;
    right: 0px;
}
.measures .measureSwitch {
    border-radius: 15px;
    height:30px;
    text-align:center;
    background-color:#f6f5f7;
    color:#6F6776;
    display:inline-block;
    padding:5px 20px;
    margin: 5px;
    box-sizing:border-box;
    cursor:pointer;
    font-family: 'futura-pt-medium';
    font-weight: 500;
    font-size: 13px;     
    line-height:150%;   
    user-select: none;
    border: none;
}
.measures .measureSwitch:hover {
    background-color: #D4CCD9;
    color:#f6f5f7;
}    
.measures .measureSwitch.active {
    background-color:#a81758;
    color:#fff;
}

@media (max-width: 1050px) {
    .measures .rightLabel {
        top:30px;
    }
}
.one-2-ten-cont {
  padding: 1em 0;
}

.one-2-ten-cont .scale,
.one-2-ten-cont .picker {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.one-2-ten-cont .scale {
  padding-bottom: 0.5em;
}

.one-2-ten-cont .picker p {
  color: #281d2d;
  width: 44px;
  height: 44px;
  border: solid 2px #ddcedb;
  border-radius: 50%;
  text-align: center;
  line-height: 44px;
  cursor: pointer;
}
.one-2-ten-cont .picker p.active,
.one-2-ten-cont .picker p:hover {
  background-color: #e92364;
  color: #fff;
  border-color: #e92364;
}

.company_report .report-section {
  color: #332938;
}
#main_container.reports.company_report {
  background-image: none;
  background-color: #fff;
  color: #332938;
}
.reports.company_report #left_container {
  background-image: none;
  background-color: #faf9fb;
}
.company_report #side_nav a,
.company_report .scrollLinks li,
.company_report .report-section h2 {
  color: #332938;
}
#main_container.reports.company_report h2.main_title {
  margin-bottom: 40px;
}

.company_report #side_nav .active a,
.company_report #side_nav a:hover,
.company_report .report-section .glowrm_slider h2 {
  color: #fff;
}
.company_report .scrollLinks li.active {
  color: #e92364;
}
.noreport,
.engagement,
.community,
.employer,
.campaigns,
.hw_strategy,
.partners {
  background-image: url("./images/noreport.png");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}
.engagement {
  background-image: url("./images/engagement.png");
}
#main_container.reports.company_report.community {
  background-image: url("./images/community.png");
}
.employer {
  background-image: url("./images/employer.png");
}
.campaigns {
  background-image: url("./images/campaigns.png");
}
.hw_strategy {
  background-image: url("./images/hw_strategy.png");
}
.partners {
  background-image: url("./images/partners.png");
}
.company_report .report-section .narrow {
  position: relative;
  border-radius: 25px;
  background-color: #faf9fb;
}
.company_report .report-section .narrow:after {
  content: "";
  display: block;
  /*padding-bottom: 55%;*/
}
.company_report .report-section .narrow .square_content {
  padding: 20px 30px;
}
.company_report .report-section .square_content,
.company_report .report-section .square_content h3 {
  color: #332938;
}
.company_report .square,
.company_report .report-section .square.wide {
  background-color: #faf9fb;
}

.health_assessment_closed {
  padding-top: 40px;
  display: block;
}
.health_assessment_closed p,
.health_assessment_closed a,
.leadership_page p {
  color: #332938;
  margin-bottom: 30px;
}
.company_report .report-section .health_assessment_closed h2 {
  color: #a81758;
  font-size: 30px;
  margin-bottom: 20px;
}
.company_report .report-section button {
  border: none;
  font-size: 16px;
  background-color: #a81758;
  transform: background-color 1s ease;
}
.company_report .report-section button:hover {
  background-color: #000;
  color: #fff;
}
.company_report .report-section button.green {
  background-color: #62bf92;
}
.company_report .report-section button.black {
  background-color: #000;
}
.company_report .report-section button .svg-inline--fa {
  margin-right: 10px;
}
.company_report .report-section button.right {
  float: right;
}
.filter-overlay #filter-options {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.filter-overlay .filters-title {
  font-size: 18px;
  border-bottom: solid 1px #c7becd82;
}

.filter-overlay .filters-title.heading {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.filter-overlay .filters-title.refined {
  padding: 10px 24px 10px 40px;
  line-height: 50px;
  display: flex;
  flex-direction: row;
}

.filter-overlay .filters-title.refined .refined-container {
  width: 80%;
}

.filter-overlay .filters-title.refined p {
  display: inline-block;
  font-size: smaller;
  background-color: #342838;
  color: white;
  border-radius: 15px;
  padding: 5px 12px;
  margin: 0 5px;
  line-height: 20px;
}

.filter-overlay .filters-title .icon {
  font-size: 18px;
  position: absolute;
  right: 24px;
  top: 24px;
  cursor: pointer;
}

.filter-overlay .filters-title .clear-filters {
  display: inline;
  font-size: smaller;
  float: right;
}

.filter-overlay .filter_italics {
  font-style: italic;
  /* padding-left: 47.5px; */
  padding-top: 10px;
}

.filter-overlay .filter_group_name {
  /* font-weight: bold; */
  font-size: 19px;
  /* padding: 47.5px 47.5px 0 47.5px; */
}

.filter-overlay .filter-container {
  border-bottom: solid 1px #c7becd82;
  margin: 0 10px 0 40px;
  padding: 20px 30px 38px 0;
}

/* .filter-overlay .options-container{
} */

.filter-overlay .options-container.region-groups {
  border-bottom: none;
  /* margin: 0 10px 0 40px; */
  padding: 20px 30px 10px 0;
}

.filter-overlay .options-container.columns {
  /* display: flex;
flex-direction: column;
flex-wrap: wrap; */
  -webkit-column-count: 2; /* Chrome, Safari, Opera */
  -moz-column-count: 2; /* Firefox */
  column-count: 2;
  width: 100%;
  /* max-height: 320px; */
  padding: 20px 30px 10px 0;
}

.filter-overlay .options-container.columns.expanded {
  max-height: fit-content;
  /* height: auto; */
}

.filter-overlay .filter-container.filters {
  border-bottom: none;
}

.filter-overlay .options-container.columns.filters {
  /* border-bottom: none; */
  height: auto;
}

.filter-overlay button {
  background-color: white;
  color: #342838;
  border: solid 1px #c7becd;
  box-shadow: none;
  margin: 7.5px 15px 7.5px 0;
  font-family: "futura-pt-book";
  font-weight: 200;
}
.filter-overlay button.disabled {
  opacity: 0.5;
}

.tooltip {
  display: none;
}
.filter-overlay .tooltip_container {
  display: block;
  position: relative;
}
.filter-overlay .tooltip_container.button {
  display: inline-block;
  /* position: relative; */
}
.filter-overlay .tooltip_container:hover .tooltip {
  text-align: center;
  display: block;
  position: absolute;
  width: 130px;
  height: 26px;
  line-height: 26px;
  top: 0;
  left: 80px;
  border: solid 1px #c7becd;
  border-radius: 8px;
  background-color: #faf9fb;
  z-index: 10;
}

.filter-overlay button.selected {
  background-color: #342838;
  border: none;
  color: white;
}

.filter-overlay button.disabled:hover {
  opacity: 0.5;
  cursor: auto;
}
.filter-overlay .icon {
  font-size: 22px;
}
.filter-overlay .filter-option {
  display: flex;
  flex-direction: row;
}

.filter-overlay .filter-option .text {
  margin-bottom: 2px;
  margin-left: 10px;
}

.filter-overlay .bottom-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding: 10px 40px 10px 47.5px;
  border-top: solid 1px #c7becd82;
}

.filter-overlay .clear-filters,
.filter-overlay .show-more {
  text-decoration: underline;
  cursor: pointer;
}

.filter-overlay .bottom-container button {
  background-color: #342838;
  color: white;
  border: none;
  font-family: "futura-pt-medium";
}

.company_report #nav {
  display: block;
  width: calc(80% - 200px);
  /* width: 100%; */
}
.company_report .results-content #nav {
  width: 100%;
}

#statistics-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
/* #main_container.reports.company_report .active.analytics h2.main_title {
  margin-bottom: 55px;
} */
#main_container.reports.company_report .active.analytics h3.period_title {
  font-family: "futura-pt-light", sans-serif;
  text-transform: uppercase;
  font-size: 18px;
  margin-bottom: 55px;
}
#main_container.reports.company_report .active.analytics h2.main_title.no_nav {
  margin-bottom: 40px;
}
.company_report #nav.data_filter {
  z-index: 5;
  width: calc(50% - 200px);
  transition: top 300ms ease-in-out;
  position: fixed;
  right: 100px;
  top: 120px;
}

#date-range-bar,
#data-filter,
#corep-type-bar {
  text-align: right;
  width: 100%;
  display: block;
}
#date-range-bar button,
#corep-type-bar button {
  background-color: #fff;
  border: 1px solid #000;
  color: #000;
  margin-left: 20px;
}
#date-range-bar button:hover,
#date-range-bar button.active,
#corep-type-bar button:hover,
#corep-type-bar button.active {
  background-color: #000;
  color: #fff;
}

/* #corep-type-bar #filter-select {
  background-color: #000;
  color: #fff;
} */
#displayed-date,
#active-filter {
  position: absolute;
  left: 0;
  padding-top: 10px;
}
#data-filter-container {
  position: relative;
  display: inline-flex;
}
#filter-select,
#active-filter span {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  padding: 0 20px;
}
#data-filter {
  padding: 0px 0 10px 0;
  /* border-bottom: 1px solid #e5e5ea; */
  margin-bottom: 30px;
}
#active-filter {
  padding-top: 0;
}
#filter-options,
#data-filter-options {
  position: absolute;

  background-color: white;
  color: #342838;
  padding: 10px 20px;
  box-sizing: border-box;
  z-index: 998;
  left: 0;
  text-align: left;
  border-radius: 15px;
  margin-top: 50px;
  /* border: solid 1px black */
}

.filter-overlay #filter-options {
  width: 750px;
  left: calc(50% - 350px);
  top: 82px;
  height: 80%;
}
.filter-overlay #filter-options #scrollable {
  height: 82%;
  overflow-y: scroll;
}

#scrollable::-webkit-scrollbar {
  width: 35px;
}
#scrollable::-webkit-scrollbar-thumb {
  border: 12px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.15);
}

.closed #filter-options,
.closed #data-filter-options {
  display: none;
}
.filter-option {
  cursor: pointer;
  margin-bottom: 5px;
}
.inactive.filter-option {
  color: grey;
  cursor: default;
}

.filter-overlay .filter-option {
  padding: 5px 5px 5px 0;
}
#active-filter span {
  position: relative;
  background-color: #e92364;
  color: #fff;
  padding: 5px 10px;
  box-sizing: border-box;
  border-radius: 12px;
  height: 24px;
}
#active-filter span .icon {
  margin-left: 5px;
  font-size: 14px;
}
#corep-type-bar #data-filter-container {
  position: absolute;
  right: 140px;
  display: inline-block;
}
#corep-type-bar #data-filter-options {
  width: 250px;
  left: 0;
  margin-top: 10px;
}

#corep-type-bar #data-filter-options .icon {
  color: #c7becd;
  margin-right: 6px;
}

#corep-type-bar #data-filter-options .icon.checked {
  color: #e92364;
}

#corep-type-bar #filter-select,
#data-filter-container #filter-select {
  padding: 8.25px 20px 8.25px 20px;
  text-align: center;
  border-radius: 25px;
  /* font-family: "futura-pt-demi", sans-serif; */
  cursor: pointer;
  /* font-weight: 600; */
  outline: none;
  /* min-width: 120px; */
  justify-content: space-between;
}

#filter-options,
#data-filter-options,
#data-filter-container #filter-select.right,
#corep-type-bar #filter-select {
  border: #c7becd 1px solid;
  margin-right: 20px;
}

#data-filter-container #filter-select.right.active {
  border: solid 1px #a81758;
}

#data-filter-container #filter-select.right.active .filter-number {
  font-size: 11px;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  color: white;
  background-color: #a81758;
  position: absolute;
  top: -5px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "futura-pt-medium";
}

#filter-select .corep-icon {
  margin: 0 8px 0 0;
  color: #342838;
  height: 20px;
  width: auto;
}

.corep-filter-main {
  font-size: 20px;
  font-family: "futura-pt-medium";
}

/* #filter-select .corep-chevron {
  margin: 0 0 0 10px;
  color: black;
} */
.report-section #usage-statistics .clear.full {
  flex: 0 0 100%;
}
.report-section #usage-statistics .clear::after {
  padding-bottom: 0;
}
.report-section.active.analytics {
  display: flex;
  flex-direction: column;
}
.chart_content {
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
}
.chart_content h3 {
  color: #332938;
}
.company_report .report-section .square.wide.wide_square {
  padding-bottom: 32%;
}
.company_report .report-section .clear .square_content .square {
  padding: 20px 30px;
}
.report_box.clear .square {
  margin-bottom: 10%;
}
.company_report .report-section .square_content .square_content {
  position: relative;
}
.company_report .report-section .clear.report_box .square::after {
  padding-bottom: 0;
}
.company_report #statistics-container .square_content .square_content .icon {
  top: 0;
  right: 0;
}
.company_report
  #statistics-container
  #health-report
  .square_content
  .square_content
  .modal_wrapper
  .icon {
  top: 20px;
  right: 20px;
}
.company_report .results-content h3.results-content-title,
.company_report .results-content h3 {
  color: #332938;
}
.company_report .glowrm_slider .swiper-button-prev,
.company_report .glowrm_slider .swiper-button-next {
  color: #290041;
}
.company_report .glowrm_slider .swiper:before {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
}
.company_report .glowrm_slider .swiper:after {
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
}

.company_report .report-section.leadership_page h2 {
  margin-bottom: 20px;
  color: #a81758;
}
#main_container.reports.company_report h2.main_title {
  color: #290041;
  margin-bottom: 50px;
}
#main_container.reports.company_report .analytics h2.main_title {
  color: #290041;
  margin-bottom: 20px;
}
.company_report.reports p {
  font-family: "futura-pt-book";
  margin-top: 0;
}
.company_report.reports h2 {
  margin-top: 0;
}
.company_report .leadership_page .clear.wide::after {
  padding-bottom: 64%;
}
.clear .centred {
  display: flex;
  flex-direction: column;
  align-content: center;
  margin: auto 0;
}
.leadership_page .square_content {
  display: flex;
}
.leadership_page .square_content h3 {
  text-transform: none;
  font-family: "futura-pt-medium";
  font-size: 20px;
  letter-spacing: normal;
  margin-bottom: 15px;
}
.leadership_page .square_content button {
  margin-bottom: 10px;
}
.report-section .clear_downloads a {
  color: #332938;
  margin-bottom: 10px;
}
.report-section .clear_downloads a:hover {
  color: #a81758;
}
.report-section .clear_downloads a .svg-inline--fa {
  color: #a81758;
  margin-right: 10px;
}

.company_report #action {
  width: 100%;
  margin-top: 20px;
}
.company_report #action .drop_down {
  color: #fff;
  background-color: #64596b;
  flex: 0 0 100%;
  margin-left: 0;
}
.company_report #action .drop_down .drop_down_shield {
  display: none;
}
.company_report #action .drop_down .icon,
.company_report #action .drop_down p {
  color: #fff;
}
ul.partners_list {
  padding: 0;
  list-style: none;
}
ul.partners_list li {
  margin: 0 0 0px 0;
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
  width: 100%;
  font-size: 20px;
}
#official_partner {
  position: absolute;
  right: 0;
  margin-top: -50px;
  text-transform: uppercase;
  font-weight: 500;
  font-family: "futura-pt-medium";
  letter-spacing: 1px;
  font-size: 20px;
}
#partners_list_container {
  position: relative;
  width: 100%;
}
#partners_list_container h3 {
  margin: 30px 0 20px 0;
  color: #a81758;
}
ul.partners_list li .provider_cat {
  width: 35%;
  display: inline-block;
}
ul.partners_list li .provider {
  margin-right: 20px;
}
ul.partners_list li .provider a,
#official_partner .svg-inline--fa {
  color: #e92263;
}
ul.partners_list li .provider a .svg-inline--fa,
#official_partner .svg-inline--fa {
  margin: 0 5px;
}

.company_report .glowrm_slider h1 {
  color: #290041;
  margin: 0px 0px -10px 0px;
}
#leaders_lab_sliders {
  margin-top: 40px;
}

.community .report-section.leadership_page p,
.community .report-section.leadership_page h2,
.community .report-section.leadership_page a {
  text-align: center;
  color: #fff;
  width: 100%;
  box-sizing: border-box;
  padding: 0 50px;
}
.company_report.community .report-section.leadership_page h2 {
  color: #fff;
  margin: 40px auto 40px auto;
}
.community img.com_logo {
  max-width: 500px;
  display: block;
  margin: 0 auto;
}
.reports.company_report.community #nav_container {
  background-image: none;
}
.company_report.community .report-section.leadership_page button {
  margin-top: 20px;
}
.reports.company_report.community #menu_button .icon {
  color: #fff;
}
.top_margin_30 {
  margin-top: 30px;
}
.company_report .report-section .nav_slider .swiper-slide {
  color: #290041;
  margin-right: 30px;
  font-size: 25px;
  font-family: "futura-pt-demi", sans-serif;
  font-weight: 600;
  height: auto;
}
.company_report .report-section .nav_slider .swiper-slide.active,
.company_report .report-section .nav_slider .swiper-slide:hover {
  color: #e92263;
}
.company_report .nav_slider .swiper-slide {
  padding: 0 25px 0 5px;
  background-color: transparent;
  text-transform: none;
}
.company_report .nav_slider .swiper-slide.active,
.company_report .nav_slider .swiper-slide:hover {
  background-color: transparent;
}

.company_report .nav_slider .swiper-wrapper {
  margin-left: 0px;
}
.company_report #nav.scrolled {
  position: fixed;
  top: 0px;
  padding-top: 50px; /*100*/
  box-sizing: border-box;
  /*background-image: linear-gradient(to bottom, rgba(255, 255, 255, 1) 60%, rgba(255,255,255,0) 100%);*/
  width: 70%;
  z-index: 56;
}
.company_report #nav.data_filter.scrolled {
  padding-top: 100px;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 75%,
    rgba(255, 255, 255, 0) 100%
  );
  z-index: 5;
}

#main_container .filter-overlay {
  width: 100vw;
  height: 100vh;
  z-index: 995;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgb(62, 53, 68, 0.7);
}
.restricted_height {
  width: 355px;
}
@media (max-width: 1570px) {
  .restricted_height {
    height: 355px;
    width: 800px;
    display: flex;
    flex-direction: row;
  }
}

@media (max-width: 1400px) {
  .company_report .report-section .square.wide {
    height: 300px;
  }
}

@media (max-width: 1370px) {
  .restricted_height {
    display: flex;
    flex-direction: row;
  }
}

@media (max-width: 1200px) {
  .company_report #nav.scrolled,
  .company_report #nav.data_filter.scrolled {
    width: 100%;
  }
  .company_report #nav,
  .company_report #nav.data_filter {
    width: 50%;
  }
  .restricted_height {
    max-height: 100%;
    max-width: 100%;
  }
}
#main_container.reports {
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  display: block;
  background-color: hsl(258, 7%, 27%);
}
.reports #left_container {
  text-align: center;
  position: fixed;
  top: 0;
  width: 20%;
  height: 100vh;
  z-index: 5;
  overflow: hidden;
  clip-path: ellipse(100% 100% at 0 50%);
  margin-left: 0px;
  /*background-color: rgb(2, 2, 20, .7);*/
  background-image: linear-gradient(
    to top,
    rgba(38, 28, 44, 1),
    rgba(38, 28, 44, 0)
  );
  display: flex;
  padding-left: 25px;
  box-sizing: border-box;
}
.reports #right_container {
  vertical-align: top;
  position: relative;
  padding-left: 20%;
  padding-bottom: 180px;
  min-height: 100vh;
}
.report-section {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 100px;
  display: flex;
  flex-wrap: wrap;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  visibility: hidden;
  opacity: 0;
  height: 0;
  position: absolute;
  color: #fff;
  margin-left: 100px;
}
.report-section a {
  color: #fff;
}

.report-section .overview-h2 {
  margin-bottom: 120px;
}
.report-section.active {
  visibility: visible;
  opacity: 1;
  height: auto;
  position: relative;
}
.report-section.active:first-of-type {
  padding-top: 110px;
}
.report-section h2 {
  font-size: 50px;
  line-height: 110%;
  margin: 0;
  font-family: "futura-pt-demi";
  font-weight: 600;
  display: inline-block;
}
.report-section .return-arrow {
  font-size: 30px;
  margin: 15px 40px 0 0;
}
.report-section p {
  font-size: 18px;
  /* margin: 10px 0 30px 0; */
  /* font-family: "futura-pt-medium", sans-serif; */
  font-weight: 500;
  /* color: #c3c2c5; */
  line-height: 26px;
}

.scrollLinks {
  display: flex;
  flex-direction: row;
  margin: 0 0 40px 0;
  padding: 0;
  list-style: none;
  width: 100%;
}
.scrollLinks.scrolled {
  position: fixed;
  z-index: 20;
  top: 40px;
  width: auto;
  max-width: 65%;
}
.scrollLinks li {
  width: auto;
  display: inline-flex;
  margin-right: 50px;
  font-size: 25px;
  font-family: "futura-pt-demi", sans-serif;
  font-weight: 600;
  color: #c3c2c5;
  cursor: pointer;
}
.scrollLinks li.active {
  color: #fff;
}

.results-content {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
/* activity sliders */
.results-content-sliders {
  display: flex;
  width: 50%;
  flex-wrap: wrap;
}
.results-content h3.results-content-title,
.results-content h3 {
  font-size: 30px;
  font-family: "futura-pt-demi", sans-serif;
  font-weight: 600;
  color: #fff;
  margin-top: 0px;
  width: 100%;
}
.results-content h3.results-content-title:first-of-type {
  margin-top: 50px;
  margin-bottom: 10px;
}
.results-content-copywrap {
  width: 100%;
}
h3.results-content-no-graph {
  margin: 0;
}

.results-page-title {
  display: block;
  width: 100%;
}

.report-section .square,
.report-section .clear,
.report-section .restricted_height .square {
  position: relative;
  flex: 0 0 31%;
  margin: 0 2% 2% 0;
  max-width: 350px;
  min-width: 300px;
}
.report-section .square {
  /* background-color: #2a202f; */
  border-radius: 30px;
}

.report-section .square:after,
.report-section .clear:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
.report-section .clear.single_row:after {
  content: "";
  display: block;
  padding-bottom: 50%;
}
.report-section .square_content {
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 20px 30px;
  flex-wrap: wrap;
  /*align-items: center;*/
  /*display: flex;*/
}
.report-section .clear .square_content {
  padding: 0;
}
.report-section .square.wide {
  position: relative;
  flex: 0 0 64%;
  background-color: #2a202f;
  border-radius: 30px;
  margin: 0 2% 2% 0;
  max-width: 700px;
}
.report-section .clear.wide {
  position: relative;
  flex: 0 0 64%;
  margin: 0 2% 2% 0;
  max-width: 700px;
}
.report-section .square.wide:after,
.report-section .clear.wide:after {
  content: "";
  display: block;
  padding-bottom: 48%;
}

.report-section .square.full {
  position: relative;
  flex: 0 0 98%;
  max-width: 1050px;
}
.report-section .square.full:after {
  content: "";
  display: block;
  padding-bottom: 40%;
}

.report-section .half_square {
  flex: 0 0 48%;
  max-width: 525px;
}
.report-section .square.half_square:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.square.half_square {
  cursor: pointer;
}

.report-section .half {
  position: relative;
  flex: 0 0 48%;
  background-color: #2a202f;
  border-radius: 30px;
  margin: 0 2% 2% 0;
  max-width: 525px;
}

.report-section .half:after {
  content: "";
  display: block;
  padding-bottom: 38%;
}
.clear.half_row {
  flex: 0 0 48%;
}
.square_content h3 {
  font-family: "futura-pt-medium";
  font-weight: 500;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 0;
  padding-right: 10px;
  box-sizing: border-box;
}
.square_content h2 {
  font-family: "futura-pt-demi";
  font-weight: 600;
  font-size: 25px;
  margin-bottom: 0;
}
.square_content .icon,
.how_it_works {
  position: absolute;
  right: 25px;
  top: 25px;
}
.how_it_works {
  border: solid 1px #c3c2c5;
  border-radius: 14px;
  padding: 3px 12px 3px 14px;
}
.square_content h2.large {
  position: relative;
  z-index: 1;
  font-size: 50px;
  font-family: "futura-pt-demi", sans-serif;
  font-weight: 600;
}
.report-section .msk-pain .square_content.grid_container {
  position: relative;
  height: auto;
}
.report-section .msk-pain .square.full::after {
  padding-bottom: 0;
}

/*drop downs*/
#action .drop_down {
  display: none;
  border-radius: 25px;
  height: auto;
  box-sizing: border-box;
  flex: 0 0 98%;
  margin-bottom: 30px;
  padding: 12px 50px 8px 30px;
  cursor: pointer;
  position: relative;
  margin-left: 70px;
  background-color: #2a202f;
}
#action .drop_down.show {
  display: block;
}
#action .drop_down.closed {
  min-height: 50px;
}

.results-content > div > p {
  padding-bottom: 35px;
}

.drop_down .icon {
  color: #e92263;
  right: 25px;
  font-size: 20px;
}
.drop_down .drop_down_content {
  display: block;
  height: auto;
  margin-top: 15px;
  padding-top: 10px;
  border-top: 1px solid #c3c2c5;
}
.drop_down.closed .drop_down_content {
  display: none;
  height: 0;
}

#action .drop_down span {
  font-family: "futura-pt-medium";
  font-weight: 500;
  font-size: 19px;
}
#action .drop_down .drop_down_content p {
  font-family: "futura-pt-book";
  font-weight: 400;
  font-size: 22px;
}
#action .drop_down .drop_down_content p:last-of-type {
  margin-bottom: 20px;
}
#action .drop_down .icon.drop_down_shield {
  background-color: #62bf92;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 10px;
  box-sizing: border-box;
  color: #000;
  position: absolute;
  left: -70px;
  top: 0;
}

/*value boxes*/
.value_box {
  flex: 0 0 100%;
  background-color: #2a202f;
  border-radius: 30px;
  margin: 0 0% 7% 0;
  position: relative;
  height: 43%;
  display: flex;
}
.report-section .clear.single_row .value_box {
  height: 93%;
}
.value_box_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 100%;
  flex-direction: column;
  height: 100%;
  text-align: center;
}
.centre {
  text-align: center;
  display: flex;
  flex: 0 0 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.centre.row {
  flex-direction: row;
}
.value_box span {
  font-family: "futura-pt-light";
  font-weight: 300;
  font-size: 20px;
  margin: 5px 0;
  display: flex;
  justify-content: center;
}
.value_box h2 {
  padding: 0px 60px;
  box-sizing: border-box;
  width: 100%;
}
.value_box .icon {
  position: absolute;
  top: 50%;
  margin-top: -12px;
  right: auto;
  margin-left: -40px;
}
.value_box .icon.target {
  position: absolute;
  right: 20px;
  font-size: 16px;
  margin-left: 0px;
}
.value_box .large .icon {
  margin-top: -25px;
  margin-left: -50px;
}
.pink {
  color: #e92364;
}
.icon.green {
  color: #62bf92;
}
.icon.red {
  color: #ef545a;
}
.icon.yellow {
  color: #fcc954;
}
.icon.blue {
  color: #3b95a7;
}
.clear.single_row .value_box {
  flex-direction: column;
}

.report-section .clear .square_content {
  display: inline-block;
}
.clear_row.report_box.clear .square {
  margin-bottom: 5%;
}

/*recommended content*/
#recommended {
  /*padding-left: 100px;*/
  box-sizing: border-box;
  width: 100%;
}

/*overview top areas*/
.square_content.area_box {
  background-size: cover;
  border-radius: 25px;
  padding: 0;
  overflow: hidden;
  display: inline-block;
  background-position: center;
}

.area_box .overlay {
  background-image: linear-gradient(
    to bottom,
    rgba(38, 28, 44, 0) 20%,
    rgba(38, 28, 44, 1) 100%
  );
  height: 100%;
  vertical-align: bottom;
}
.overview-boxes .icon {
  bottom: 25px;
  top: auto;
}
.overview-boxes .square_content h3 {
  position: absolute;
  bottom: 20px;
  left: 20px;
}
.overview.report-section .half_square {
  display: flex;
  align-items: center;
  align-content: center;
}
.overview.report-section .auto .square_content {
  height: auto;
}

.overview.report-section .cal_drop_down_wrapper {
  margin-top: 10px;
}

/*EXPANDABLES*/
.expandable_container {
  position: relative;
  margin-bottom: 50px;
}
.expandable_container.open {
  margin-bottom: 0px;
}
.expandable_content {
  overflow: hidden;
  height: auto;
  transition: height 1s ease;
}
.closed .expandable_content {
  -webkit-mask-image: linear-gradient(
    to top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(0, 0, 0, 1) 100%
  );
  mask-image: linear-gradient(
    to top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 01) 50%,
    rgba(0, 0, 0, 1) 100%
  );
}
.small.closed .expandable_content {
  height: 60px;
}
.large.closed .expandable_content {
  height: 200px;
}
.expandable_container a.expander {
  margin-left: -25px;
  margin-top: -15px;
  left: 50%;
  position: absolute;
  z-index: 3;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-color: #e92263;
  text-align: center;
}
.expandable_container a.expander .icon {
  margin-top: 5px;
  font-size: 20px;
}

.ap-view-more-button {
  width: 312px;
  height: 60px;
  background-color: #62bf92;
  color: black;
  border: none;
  border-radius: 30px;
  font-size: 17px;
  margin-left: calc(50% - 156px);
}

.apicon_button {
  margin-right: 15px;
}

.ap-view-more-button:hover {
  color: black;
}
/*bounce*/
.report-section .square,
.value_box {
  top: 0;
  transition: top 0.2s ease-in-out;
}
.report-section .square:hover,
.value_box:hover {
  top: -5px;
}

.company_report .report-section .square_content.disabled .ttg_fill {
  background-color: #eaeaea;
}
.company_report .report-section .square_content.disabled .ttg_container {
  background-color: #f7f7f7;
}

.company_report .report-section .square.wide.disabled h4 {
  color: #eaeaea;
}

.company_report .report-section .square.wide.disabled h3 {
  color: #303030;
}

.company_report .report-section .square_content.disabled h3 {
  color: #303030;
}

.company_report .report-section .square_content.disabled .row span {
  color: #eaeaea;
}
.company_report .square_content.disabled .bar-chart .column {
  background: #eaeaea;
}
.company_report .square_content.disabled .bar-chart .column span {
  color: #eaeaea;
}
.company_report .report-section .square_content.disabled .top_three_row,
.company_report .report-section .square_content.disabled .top_three_row span {
  color: #eaeaea;
}
.company_report .report-section .square_content.disabled .top_three_row {
  border: 1px solid #f7f7f7;
}
.company_report
  .report-section
  .square_content.disabled
  .bar-chart
  .row::after {
  border: 1px solid #f7f7f7;
}
.company_report .report-section .square_content.disabled .top_three .sub_head {
  color: #b5b5b5;
}

.company_report .report-section .square_content.disabled .large {
  color: #eaeaea;
}
.company_report .square_content.disabled .chart_circle_colour2 .circle,
.company_report
  .report-section
  .square_content.disabled
  .chart_circle_colour1
  .circle {
  stroke: #eaeaea;
}

.company_report .report-section .square_content.disabled .chart_circle {
  background: #f7f7f7;
}
.company_report .report-section .square_content.disabled {
  background-color: #fcfcfc;
}
.company_report .square_content.disabled .icon.switch_icon.active {
  background-color: #f7f7f7;
  border-color: #f7f7f7;
}
.company_report .square_content.disabled .icon.switch_icon {
  color: #eaeaea;
  border: #f7f7f7;
  cursor: not-allowed;
}
.company_report .square_content.disabled .yaxis {
  color: #eaeaea;
}
.company_report .square_content.disabled .chart_circle::after {
  background-color: #fcfcfc;
}
.company_report .report-section .square_content.disabled .graph_disabled_text {
  font-size: 20px;
  font-weight: 450;
  line-height: 25.64px;
  letter-spacing: 0.035em;
  color: #000;
  position: absolute;
  top: 50%;
  left: calc(50% - 93px);
  z-index: 2;
}
.company_report .report-section .square_content.disabled .info_icon {
  background-color: #c5c5c5;
}

@media (max-width: 1200px) {
  .report-section {
    padding-right: 0;
    margin-left: 0;
  }
  .reports #right_container {
    padding-left: 7%;
    padding-right: 5%;
  }
  #recommended {
    padding-left: 0px;
  }
  .reports #left_container {
    display: none;
  }
  .report-section .square,
  .report-section .clear,
  .report-section .restricted_height .square {
    position: relative;
    flex: 0 0 48%;
  }
  #action .drop_down {
    flex: 0 0 85%;
  }
}
@media (max-width: 850px) {
  .report-section .square.wide,
  .report-section .square.half,
  .report-section .half_square,
  .report-section .square,
  .report-section .clear,
  .report-section .restricted_height .square {
    position: relative;
    flex: 0 0 98%;
  }
  .scrollLinks {
    flex-direction: column;
  }
  .scrollLinks.scrolled {
    max-width: 85%;
  }
  .report-section .square.full:after {
    padding-bottom: 0%;
  }
  .report-section .square.wide::after {
    padding-bottom: 100%;
  }
  .report-section h2 {
    font-size: 40px;
  }
}

#side_nav {
  color: #fff;
  margin: auto 0;
  text-align: left;
  display: flex;
  font-family: "futura-pt-medium", sans-serif;
  font-weight: 500;
  font-size: 20px;
}
.vertical-nav {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.vertical-nav li span {
  padding: 10px 30px;
  display: inline-block;
  cursor: pointer;
  margin: 2px 0;
  border-radius: 25px;
}
.vertical-nav li.active span,
.vertical-nav li span:hover {
  background-color: var(--pink-primary-color);
  border-radius: 25px;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
  transition: all 0.5s ease;
  outline: none;
}
#side_nav a {
  color: #fff;
  outline: none;
}

#side_nav .vertical-nav .app_link_wrap {
  padding: 30px 0 0 30px;
}

.app_link_wrap img.app_link.google_play {
  margin-bottom: 5px;
}
#side_nav .vertical-nav .app_link_wrap a {
  vertical-align: top;
}

#main_container.foryou {
  padding: 0 0 200px 0;
  background-color: #291e2e;
  min-height: 100vh;
  display: block;
  flex-direction: column;
  align-items: center;
}

#main_container.foryou .heading {
  position: fixed;
  z-index: 50;
  top: 40px;
  left: 0;
  right: 0;
  width: fit-content;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#main_container.foryou .heading h2 {
  font-family: "futura-pt-demi";
  margin: 0;
  color: white;
  font-size: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

#main_container.foryou .page-container .feature {
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  min-height: 100vh;
  position: relative;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
#main_container.foryou .page-container .feature.fade-in {
  animation: fadeIn 0.7s ease-in-out;
}

.hero-contents .feature-btn {
  margin-bottom: 120px;
  cursor: pointer;
  padding: 10px 25px;
  font-family: "futura-pt-medium", sans-serif;
  font-weight: 500;
  font-size: 20px;
  border-radius: 25px;
  transition: background-color 0.5s ease;
  text-transform: capitalize;
}

#main_container.foryou .page-container .other-content {
  margin-top: -20vh;
}
#main_container.foryou .heading .icon {
  color: white;
  margin-right: 35px;
}

#main_container.foryou .heading .icon.home {
  height: 22px;
}

#main_container.foryou .heading h2 {
  display: inline-block;
  text-transform: uppercase;
}

#main_container.foryou .nav_slider {
  list-style: none;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-inline-start: 0;
}

#main_container.foryou .nav_slider li {
  padding: 10px 25px;
  font-family: "futura-pt-medium", sans-serif;
  font-weight: 500;
  font-size: 20px;
  border-radius: 25px;
  background-color: #463b4c;
  transition: background-color 0.5s ease;
  cursor: pointer;
  margin: 7.5px;
  text-transform: capitalize;
}

#main_container.foryou .nav_slider li:hover,
#main_container.foryou .nav_slider li.active {
  background-image: var(--pink-primary-gradient);
}

#main_container.foryou .nav_slider li:focus {
  background-image: var(--pink-primary-gradient);
  outline: var(--light-outline);
}

#main_container.foryou .nav_slider.goals li:hover {
  background-color: #463b4c;
}

#main_container.foryou .page-container .results-container {
  padding: 0 50px;
}

.results-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: white;
  flex-wrap: wrap;
  row-gap: 44px;
}

.results-nav .results-toggle {
  display: flex;
  flex-direction: row;
  width: 550px;
  height: 65px;
}

.results-nav .results-toggle div {
  width: 50%;
  border: solid 1px #615768;
  height: calc(100% - 2px);
  text-align: center;
  line-height: 63px;
  background-color: #281d2d;
  color: #ddcedb;
  font-family: "futura-pt-medium";
  font-size: 1rem;
  text-transform: uppercase;
  cursor: pointer;
}
.results-nav .results-toggle div.active {
  background-color: #615768;
  color: #ffffff;
}

.results-nav .results-toggle .assessment {
  border-radius: 34px 0 0 34px;
}

.results-nav .results-toggle .tracker {
  border-radius: 0 34px 34px 0;
}

.results-nav .dates-toggle {
  display: flex;
  flex-direction: row;
  border: solid 1px #615768;
  align-items: center;
  padding: 20px 43px;
  background-color: #281d2d;
  color: #fff;
  font-family: "futura-pt-medium";
  font-size: 1rem;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 34px;
  position: relative;
  /* height: 26px; */
}

.results-nav .dates-toggle.with-dd {
  flex-wrap: wrap;
  height: auto;
}

.results-nav .dates-toggle div {
  padding: 0 45px;
}

.results-nav .dates-toggle .period-dd {
  /* display: flex;
  flex-direction: row; */
  border: solid 1px #615768;
  align-items: center;
  padding: 20px 43px;
  text-align: center;
  background-color: #281d2d;
  color: #fff;
  font-family: "futura-pt-medium";
  font-size: 1rem;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 34px;
  top: 55px;
  right: 0;
  list-style: none;
  position: absolute;
  z-index: 2;
  width: 230px;
}

.results-nav .dates-toggle .period-dd li {
  padding: 10px 0;
}

.results-nav .dates-toggle .period-dd li:first-child,
.results-nav .dates-toggle .period-dd li:nth-child(2) {
  border-bottom: solid 1px #ffffff70;
}

#main_container.foryou .page-container .feature .fade {
  position: absolute;
  width: 100%;
  height: 30vh;
  bottom: 0px;
  background-image: linear-gradient(
    to top,
    rgba(38, 28, 44, 1) 20%,
    rgba(38, 28, 44, 0)
  );
}

.glowrm {
  min-height: 100vh;
  overflow: auto;
}
.glowrm h1,
.glowrm h2,
.glowrm h1 a {
  color: #fff;
  position: relative;
  z-index: 1;
}
.glowrm h1 {
  margin: 30px 0px -10px 50px;
  text-transform: capitalize;
}
.platform {
  padding: 200px 0 200px 0;
}

.platform #nav_container {
  background: rgba(40, 29, 45, 0.7);
  backdrop-filter: blur(6px);
  z-index: 49;
}

.promo_rectangle_row {
  display: flex;
  padding-left: 50px;
  height: 33em;
}
.promo_rectangle_row .modal_wrapper {
  height: 100%;
}

.glowrm .rectangle,
.glowrm .clear {
  position: relative;
  flex: 0 0 22%;
  margin: 0 2% 2% 0;
}
.glowrm .promo_rectangle_row .rectangle:last-of-type {
  margin-left: 0;
}
.glowrm .rectangle {
  background-color: #2a202f;
  border-radius: 30px;
}

.glowrm .rectangle:after,
.glowrm .clear:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
.glowrm .rectangle_content {
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0px;
  flex-wrap: wrap;
}
.glowrm .clear .rectangle_content {
  padding: 0;
}

.glowrm .rectangle.full {
  position: relative;
  flex: 0 0 98%;
}
.glowrm .rectangle.full:after {
  content: "";
  display: block;
  padding-bottom: 40%;
}
.glowrm .overlay {
  padding: 20px 30px;
}
.glowrm .overlay h3 {
  color: #fff;
}
.glowrm .rectangle_content.promo_box .icon {
  color: #fff;
  position: absolute;
  right: 25px;
  top: 25px;
}

.rectangle_content.promo_box {
  background-position: 50%;
  background-size: cover;
  border-radius: 25px;
  display: inline-block;
  overflow: hidden;
}

.glowrm .rectangle_content.promo_box#individual-report-overview {
  background-image: url("../platform/pages/images/health-report.jpg");
}
.glowrm .rectangle_content.promo_box#track-mood-tracker {
  background-image: url("../platform/pages/images/mood-tracker-coming-soon1.jpg");
}
.glowrm .rectangle_content.promo_box#track-sleep-tracker {
  background-image: url("../platform/pages/images/sleep-tracker-coming-soon.jpg");
}
.glowrm .promo_box .overlay {
  background-image: linear-gradient(
    to top,
    rgba(38, 28, 44, 0) 0%,
    rgba(38, 28, 44, 1) 100%
  );
  height: 100%;
}
.glowrm h1.section_title {
  font-size: 40px;
  margin: 0 0 20px 0;
  text-align: center;
}
.authors,
.author_with a {
  color: #fff;
}
.authors .video_container,
.authors .author_desc_container {
  max-width: 90%;
  width: 600px;
  margin: 30px auto;
}
.authors .author_photo {
  width: 60px;
  height: 60px;
  display: inline-block;
  margin-right: 20px;
  border-radius: 50%;
  background-position: center center;
  background-size: 60px;
  top: -5px;
  position: absolute;
  margin-left: -80px;
}
.authors h1.section_title {
  padding-left: 80px;
  position: relative;
}
.breadcrumb {
  text-align: center;
  color: #fff;
  margin-top: -25px;
  padding-bottom: 25px;
}
.breadcrumb a {
  color: #fff;
  text-decoration: underline;
  text-transform: capitalize;
}
.breadcrumb span {
  font-size: 30px;
  margin: 0 10px;
}
a.back-btn {
  display: inline-block;
  margin-left: 50px;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  background-color: #64596b;
  text-align: center;
  padding-top: 15px;
  box-sizing: border-box;
  position: absolute;
  z-index: 20;
}
.icon.return-arrow.back {
  color: #fff;
  font-size: 30px;
}

.glowrm.track_page h1.section_title {
  margin: -70px auto 70px auto;
}

@media (max-width: 1050px) {
  .glowrm h1.section_title,
  .glowrm h1 {
    font-size: 24px;
  }
  .promo_rectangle_row {
    padding: 0 30px 50px 30px;
  }
}
@media (max-width: 800px) {
  .promo_rectangle_row {
    flex-wrap: wrap;
  }
  .promo_rectangle_row .rectangle {
    position: relative;
    flex: 0 0 48%;
  }
  /* .promo_rectangle_row .rectangle.wide {
      position: relative;
      flex: 0 0 98%;
    } */
}

@media (max-width: 750px) {
  .platform #nav_container {
    padding: 25px 0 75px 20px;
  }
}

@media (max-width: 650px) {
  #main_container.foryou .heading {
    transform: scale(0.8);
  }
  #main_container.foryou .heading .icon {
    margin-right: 20px;
  }
}

@media (max-width: 500px) {
  .glowrm h1 {
    margin-top: 10px;
  }
  .glowrm h1.section_title {
    margin-bottom: 40px;
  }
  .platform {
    padding-top: 160px;
  }
}

@media (max-width: 450px) {
  .platform #nav_container {
    padding: 25px 0 100px 20px;
  }

  #nav_container.no-submenu {
    padding: 25px 50px;
  }
}

@media (max-width: 400px) {
  #main_container.foryou .nav_slider.na {
    transform: translate(-50%, 0) scale(0.8);
  }
  #main_container.foryou .nav_slider li {
    margin: 5px;
  }
}

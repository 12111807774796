.feedback-container{
    animation-name:slideup;
    animation-duration:1.2s;
    animation-fill-mode:forwards;
    animation-timing-function:ease-out;
    position: fixed;
    bottom: -180px;
    height: 140px;
    width: 100%;
    background-color: #281d2d;
    z-index: 12;
    padding: 20px;
    margin: 0;
    display: flex;
    flex-direction: row;
}
@keyframes slideup {
    0%{bottom:-180px}
    100%{bottom:0;}
  }

@-moz-keyframes slideup {
    0%{bottom:-180px}
    100%{bottom:0;}
  }

@-webkit-keyframes slideup {
    0%{bottom:-180px}
    100%{bottom:0;}
  }

@-ms-keyframes slideup {
    0%{bottom:-180px}
    100%{bottom:0;}
  }

 .feedback-container.slide-down{
    animation-name:slidedown;
    animation-duration:0.5s;
    animation-fill-mode:backwards;
    animation-timing-function: ease-in;
    bottom: 0;
 }

 @keyframes slidedown {
    0%{bottom:0}
    100%{bottom:-180px;}
  }

@-moz-keyframes slidedown {
    0%{bottom:0}
    100%{bottom:-180px;}
  }

@-webkit-keyframes slidedown {
    0%{bottom:0}
    100%{bottom:-180px;}
  }

@-ms-keyframes slidedown {
    0%{bottom:0}
    100%{bottom:-180px;}
  }

.feedback-left-box{
    width: 30%;
    border-right: solid 2px rgba(255, 255, 255, 0.5);
    padding: 0;
    display: inline-block;
    margin-left: 55px;
    height: 140px;
}
.feedback-container h1 { 
    text-transform: none;
    margin: 10px 0 0 0;
    font-size: 1.875rem;
    line-height: 2.5rem;
}

.feedback-left-box h5 { 
    font-size: 0.625rem;
    letter-spacing: 0.2rem;
    font-family: "futura-pt-demi";
    text-transform: uppercase;
    margin: 23.5px 0 0 0; 
    color: white;
}

.feedback-right-box{
    width: 70%;
    padding: 0 70px 0 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.feedback-right-box .nps-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 830px;
}

.feedback-right-box .nps-number {
    font-family: "futura-pt-medium";
    height: 48px;
    border-radius: 25px;
    width: 48px;
    border: solid 1.5px rgba(255, 255, 255, 0.7);
    color: rgba(255, 255, 255, 0.5);
    text-align: center;
    line-height: 48px;
    font-size: 25px;
    cursor: pointer;
}
.feedback-right-box .nps-number.selected, .feedback-right-box .nps-number:hover {
    height: 58px;
    border-radius: 29px;
    width: 58px;
    border: none;
    background-color: white;
    font-size: 30px;
    line-height: 58px;
    color: #281d2d;
    margin: -3.7px;
}


.feedback-right-box .button-container {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.feedback-container .button-container div {
    color: rgba(255, 255, 255, 0.5);
    font-family: "futura-pt-book";
    font-size: 12px;
    cursor: pointer;
}

.feedback-right-box .button-container button{
    margin-bottom: 10px;
    width: 165px;
}

.nps-submitted{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.nps-submitted p{
    color: white;
    font-family: 'futura-pt-demi';
 }

 .nps-submitted .button-container {
    display: flex;
    flex-direction: row;
}

.nps-submitted .button-container a {
    width: 130px;
    padding: 10px 0;
    text-align: center;
    border-radius: 25px;
    font-family: "futura-pt-demi", sans-serif;
    cursor: pointer;
    border: 1px solid #e92263;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 20px;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
    transition: all 0.5s ease;
    outline: none;
    background-color: #e92263;
    color: #fff;
  }
  .nps-submitted .button-container a:hover {
    background-color: #fff;
    color: #e92263;
  }

.nps-submitted .button-container div {
   margin: 15px 0 0 20px;
}

@media (max-width: 1660px){
    .feedback-right-box{
        justify-content: space-between;
    }
    
    .feedback-right-box .nps-container {
        width: 80%;
    }
}

@media (max-width: 1400px){
    .feedback-right-box .button-container button{
        width: 140px;
    }
}

@media (max-width: 1260px){
    .feedback-container h1 { 
        font-size: 1.5rem;
        line-height: 2rem;
    }
    .feedback-right-box .nps-number {
        height: 36px;
        border-radius: 19px;
        width: 36px;
        line-height: 36px;
        font-size: 20px;
    }
    .feedback-right-box .nps-number:hover {
        height: 46px;
        border-radius: 24px;
        width: 46px;
        font-size: 25px;
        line-height: 46px;
    }
    .feedback-right-box .button-container button {
        padding: 8px 20px;
    }
}

@media (max-width: 1060px){
    .feedback-container h1 { 
        font-size: 1.25rem;
        line-height: 1.5rem;
    }

    .feedback-right-box .nps-container {
      flex-wrap: wrap;
      width: 70%;
      justify-content: space-evenly;
    gap:15px
    }
}


@media(max-width: 750px){
    .feedback-left-box{
        margin-left: 15px;
    }

    .feedback-right-box .button-container button {
        font-size: 12px;
    }

    .feedback-right-box .button-container div {
        font-size: 10px;
    }
}

@media (max-width: 680px){
    .feedback-container h1 { 
        font-size: 1rem;
        line-height: 1.25rem;
    }}

@media (max-width: 630px){
    .feedback-left-box h5 { 
        font-size: 0.4rem;
        font-family: "futura-pt-demi";
    }

    .feedback-right-box{
        padding: 0 20px 0 10px;
        margin: 0 20px 0 0;
    }
    
    .feedback-right-box .nps-container {
       gap:10px
    }

    .feedback-right-box .nps-number {
        height: 30px;
        border-radius: 16px;
        width: 30px;
        line-height: 30px;
        font-size: 15px;
    }
    .feedback-right-box .nps-number:hover {
        height: 38px;
        border-radius: 19px;
        width: 38px;
        font-size: 20px;
        line-height: 38px;
    }
    .feedback-right-box .button-container button {
        padding: 8px 10px;
    }

}
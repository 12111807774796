.popup-overlay .dark-container {
  background: rgb(41, 30, 46);
  max-width: 613px;
  min-height: 308px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 70px 250px;
}

.popup-overlay .dark-container .icon.award {
  font-size: 100px;
  padding-bottom: 35px;
}

.popup-overlay .dark-container img {
  width: 420px;
  padding-bottom: 35px;
}

.popup-overlay .dark-container h1 {
  font-size: 30px;
  text-align: center;
}

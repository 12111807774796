.popup-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(41, 30, 46, 0.75);
  z-index: 60;
}

.popup-overlay.offline {
  z-index: 999;
}

.popup-overlay .popup-container {
  background: white;
  max-width: 613px;
  min-width: 400px;
  min-height: 308px;
  /* max-height: 60vh; check workout pop up if this needs to be commented in */
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 25px;
  color: #290140;
}

.popup-overlay .popup-container .popup-title {
  text-transform: uppercase;
  text-align: center;
  font-family: "futura-pt-medium";
  font-size: 20px;
  padding: 40px 0;
  color: #290140;
  border-bottom: 2px solid rgba(100, 89, 107, 0.1);
  position: relative;
}

.icon-offline {
  height: 50px;
  color: #e92364;
  margin-bottom: 15px;
}

/* from figma - goals */
.popup-overlay .popup-container.results.goals {
  z-index: 10;
}

.popup-overlay .popup-container.goals .popup-title {
  font-size: 16px;
  letter-spacing: 2px;
  padding: 30px 0;
}

.popup-overlay .popup-container .popup-title .icon {
  position: absolute;
  right: 33px;
  cursor: pointer;
}

.popup-overlay .popup-container .popup-body {
  padding: 40px 33px 20px 50px;
}

.popup-overlay .popup-container.results .popup-body {
  padding: 40px 33px 20px 50px;
  max-height: 30vh;
  min-height: 120px;
  overflow-y: scroll;
  font-family: "futura-pt-book";
}

.popup-overlay .popup-container.results .popup-body::-webkit-scrollbar {
  width: 4px;
}

.popup-overlay .popup-container.results .popup-body::-webkit-scrollbar-track {
  border-radius: 2.5px;
  width: 4px;
  background: transparent;
}

.popup-overlay .popup-container.results .popup-body::-webkit-scrollbar-thumb {
  width: 4px;
  border-radius: 2.5px;
  background: #291e2e;
}

.popup-overlay .popup-container.results.goals {
  min-height: 625px;
  max-height: 625px;
  min-width: 690px;
  max-width: 690px;
  left: 50%;
  top: 65%;
  transform: translate(-50%, -65%);
}

.popup-overlay .popup-container.results.goals .popup-body {
  min-height: 365px;
  max-height: 365px;
}

.popup-overlay .popup-container .popup-body .popup-heading {
  font-family: "futura-pt-demi";
  font-size: 31px;
  color: #41c0d3;
}

.popup-overlay .popup-container .popup-body .popup-heading.dark {
  color: #290140;
  margin-bottom: 15px;
}

.popup-overlay .popup-container .popup-body .popup-text-container.center {
  text-align: center;
}

.popup-overlay .popup-container .popup-body .popup-text-container.center div {
  padding-bottom: 10px;
}

.popup-overlay .popup-container .popup-body .popup-text-container p {
  font-family: "futura-pt-medium";
  font-size: 20px;
}

.popup-overlay .popup-container .popup-body .popup-text-container .mid-heading {
  font-family: "futura-pt-demi";
  font-size: 20px;
  padding: 15px 0;
}

.popup-overlay .popup-container .popup-body .popup-text-container .sub-heading {
  font-family: "futura-pt-demi";
  font-size: 20px;
}

.popup-overlay .popup-container .popup-cta {
  /* padding: 0 33px 10px 50px; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 25px 33px 0 33px;
  border-top: 2px solid rgba(100, 89, 107, 0.1);
}

.popup-overlay.offline .popup-container .popup-cta {
  justify-content: center;
}

.popup-overlay .popup-container .popup-cta.single {
  justify-content: center;
  /* padding: 0 33px 0 33px; */
}

.popup-overlay .popup-container.results .popup-cta.single {
  justify-content: flex-end;
}

.popup-overlay .popup-container.results .popup-cta.single .cta-icon {
  margin-left: 10px;
}

.popup-overlay .popup-container .popup-body .popup-btns-container {
  display: flex;
  flex-direction: column;
  column-gap: 1.25em;
  width: 50%;
  height: 345px;
  flex-wrap: wrap;
}

.popup-body .popup-btns-container .goal-btn {
  padding: 10px 10px 10px 20px;
  width: 260px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  line-height: 30px;
  font-family: "futura-pt-demi";
  border: solid 1px #ddcedb;
  border-radius: 26px;
  color: #64596b;
  margin-bottom: 10px;
}

.popup-body .popup-btns-container .goal-btn:hover {
  border: solid 1px #f69358;
}

.popup-body .popup-btns-container .goal-btn .icon {
  height: 30px;
  color: #f1ebf4;
}

.popup-body .popup-btns-container .goal-btn.active .icon {
  color: #f69358;
}

.popup-btns-container .goal-error {
  font-size: smaller;
  margin-bottom: 10px;
  margin-top: -10px;
  color: #ef545a;
  width: 292px;
}

.popup-body .popup-btns-container .goal-btn.error {
  border: solid 1px #ef545a;
}

.popup-container.results.goals .goalsrw {
  height: 482px;
  padding: 30px 15px 15px 30px;
  margin: 0 15px 15px 0;
  overflow-y: scroll;
  flex-direction: column;
  align-items: center;
}

.popup-container.results.goals .goalsrw::-webkit-scrollbar {
  width: 4px;
}

.popup-container.results.goals .goalsrw::-webkit-scrollbar-track {
  border-radius: 2.5px;
  width: 4px;
  background: transparent;
}

.popup-container.results.goals .goalsrw::-webkit-scrollbar-thumb {
  width: 4px;
  border-radius: 2.5px;
  background: #291e2e;
}

.goalsrw .swiper-button-next,
.goalsrw .swiper-button-prev,
.goalsrw .swiper-button-next::after,
.goalsrw .swiper-button-prev::after {
  color: #281d2d;
  width: 15px;
  height: 30px;
  text-align: center;
  padding: 5px 15px;
  border-radius: 50%;
  background-color: #fff;
}

.goalsrw .swiper-button-next::after,
.goalsrw .swiper-button-prev::after {
  font-size: 30px;
}

.goalsrw .swiper-button-next {
  right: -15px;
}

.goalsrw .swiper-button-prev {
  left: -14px;
}

.goalsrw .swiper-pagination-bullet {
  width: 15px;
  height: 15px;
}

.goalsrw .swiper-pagination-bullet-active {
  background-color: #64596b;
}

.orange-box {
  border-radius: 20px;
  background: linear-gradient(109deg, #f69358 0%, #ef545a 100%);
  display: flex;
  width: calc(100% - 60px);
  padding: 25px 30px 0 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.orange-box .ob-title {
  color: #fff;
  font-family: "Futura PT";
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.orange-box .ob-text {
  color: #fff;
  text-align: center;
  font-family: "Futura PT";
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 20px; /* 125% */
}

@media (max-width: 750px) {
  .popup-overlay .popup-container .popup-title {
    font-size: 18px;
    padding: 30px 0;
  }

  .popup-overlay .popup-container .popup-body {
    padding: 40px 33px 20px 50px;
  }

  .popup-overlay .popup-container .popup-body .popup-heading {
    font-size: 25px;
  }

  .popup-overlay .popup-container .popup-body .popup-text-container p {
    font-size: 18px;
  }

  .popup-overlay .popup-container.results.goals {
    min-width: 400px;
    max-width: 400px;
  }

  .popup-overlay .popup-container .popup-body .popup-btns-container {
    width: 100%;
    overflow: scroll;
    flex-wrap: nowrap;
  }
}

@media (max-width: 650px) {
  .popup-overlay .popup-container .popup-cta {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
  }
}

@media (max-width: 420px) {
  .popup-overlay .popup-container.results.goals {
    transform: scale(0.8);
    left: -5%;
    top: 17%;
  }

  .popup-overlay .popup-container {
    max-width: 90%;
    min-width: 90%;
  }
}

@media (max-width: 350px) {
  .popup-overlay .popup-container.results.goals {
    transform: scale(0.7);
    left: -20%;
    top: 17%;
  }
}

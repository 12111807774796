.tracker-container .ind-tracker .title.sub {
  font-size: 22.5px;
}

.tracker-container .ind-tracker .title.sub .title {
  font-size: 22.5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.tracker-container .ind-tracker .influencers-cont .title.sub {
  padding-top: 1em;
}

.influencers-cont {
  margin-bottom: 1em;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 60px;
  align-items: stretch;
}
.influencers-cont .list {
  flex-grow: 1;
}
.influencers-cont .list ul {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}

.influencers-cont .list.two-col ul {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.influencers-cont .list ul li {
  width: 100%;
  border: solid 2px #ddcedb;
  padding: 20px 0;
  border-radius: 35px;
  margin-bottom: 10px;
  cursor: pointer;
  text-align: center;
}

.influencers-cont .list ul li p {
  text-transform: none;
}

.influencers-cont .list.two-col ul li {
  width: 47%;
}

.influencers-cont .list.pos ul li.active,
.influencers-cont .list.pos ul li:hover {
  background-color: #62bf92;
  border-color: #62bf92;
}

.influencers-cont .list.pos.two-col ul li.active,
.influencers-cont .list.pos.two-col ul li:hover {
  background-color: #41c0d3;
  border-color: #41c0d3;
}

.influencers-cont .list.neg ul li.active,
.influencers-cont .list.neg ul li:hover {
  background-color: #ef545a;
  border-color: #ef545a;
}

.influencers-cont .list.neg ul li.alc.active,
.influencers-cont .list.neg ul li.alc:hover {
  background-color: #f69358;
  border-color: #f69358;
}

.influencers-cont .list ul li.active p,
.influencers-cont .list ul li:hover p {
  color: #fff;
}

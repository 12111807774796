#profile_tab_navigation.tabs_container {
  position: fixed;
  top: 90px;
  left: 50%;
  z-index: 49;

  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-inline-start: 0;

  overflow: auto;
  transform: translate(-50%, 0);
  width: 100%;

  color: #fff;
  transition: all 0.5sease 0s;
}

#profile_tab_navigation button.tab {
  padding: 10px 25px;
  margin: 7.5px;
  border-radius: 25px;
  cursor: pointer;

  background-color: #463b4c;
  transition: background-color 0.5sease;

  font-family: "futura-pt-medium", sans-serif;
  font-weight: 500;
  font-size: 20px;
  text-transform: capitalize;
}

#profile_tab_navigation button.tab:focus {
  outline: var(--dark-outline);
  transition: none;
}

#profile_tab_navigation button.tab:hover,
#profile_tab_navigation button.tab.active {
  color: white;
  background-image: var(--pink-primary-gradient);
}

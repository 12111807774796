.chart-container.tile {
  color: white;
}

.chart-container.tile .chart-box .circle_chart {
  width: 180px;
  height: 180px;
}

.chart-container.tile .chart-box .circle_chart h3 span {
  font-size: 20px;
}

.tile-value {
  font-size: 4.5rem;
  font-family: "futura-pt-demi";
}

.tile-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.tile-content p {
  /* position: absolute;
  bottom: 0; */
  text-transform: uppercase;
  font-family: "futura-pt-medium";
  color: #ddcedb;
  margin: 0;
}

.chart-container.tile .chart-box .value-descr {
  font-family: "futura-pt-medium";
  font-size: 20px;
  color: #ddcedb;
  width: 70%;
  align-self: center;
}

.chart-container.tile .chart-box .value-descr.caps {
  text-transform: uppercase;
}

.circle_chart .icon.tile {
  height: 2.5rem;
  width: 2.5rem;
  align-self: center;
}

@media (max-width: 820px) {
  .tile-content {
    font-size: 90%;
  }
}

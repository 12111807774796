.hor-slider-scale {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: -30px;
}

.hor-slider {
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 40px;
}
.bar {
  height: 50px;
  width: 20px;
  cursor: pointer;
  position: relative;
}

.color-bar {
  height: 50px;
  border-radius: 6px;
  margin: 0 5px;
}

.hor-slider-tooltip {
  position: absolute;
  width: 110px;
  top: 62px;
  left: -58px;
  text-align: center;
  display: inline-block;
  padding: 15px 8px;
  background-color: #290140;
  color: #fff;
  font-family: "futura-pt-medium";
  font-size: 22.5px;
  line-height: 50px;
  border-radius: 12px;
  line-height: 25px;
}

.aa:after {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
  border-bottom: 10px solid #290140;
  border-top: 10px solid transparent;
  left: 49px;
  top: -19px;
}

.workout {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 80px;
  column-gap: 50px;
}

.workout.no-equipment {
  grid-template-columns: 1fr;
}

.workout .container {
  background-color: #372c3d;
  color: #ddceda;
  height: 500px;
  padding: 40px;
  border-radius: 12px;
}

.workout.no-equipment .container {
  height: auto;
}

.workout .equipment.container,
.workout .exercises.container {
  display: flex;
  flex-direction: column;
}

.workout .equipment.container .body,
.workout .exercises.container .list {
  font-family: "futura-pt-book";
  font-size: 20px;
}

.workout .container .title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
}

.workout .container .title h3 {
  color: white;
  font-family: "futura-pt-demi";
  font-size: 40px;
}

.workout .container .body {
  overflow-y: auto;
}

.workout .container .list {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.workout .exercises.container.stretched .list {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  overflow: auto;
  gap: 10px;
  /* display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px; */
}

.workout .exercises.container .list {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.workout .container .list .column {
  width: 50%;
}

.workout .container .list .first.column {
  border-right: 1px solid #64596b;
}

.workout .equipment.container .list .item {
  padding-left: 25px;
  height: 24.75%;
  border-bottom: 1px solid #64596b;
}

.workout .exercises.container .list .item {
  padding-left: 25px;
  border: 1px solid #64596b;
  border-radius: 12px;
  margin: 0 10px 20px 10px;
}

.workout .exercises.container .list .item:hover {
  border-color: #41c0d3;
}

.workout .exercises.container .list .item .step {
  width: 100%;
  text-align: center;
}

.workout .container .list .item {
  min-height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}

.workout .equipment.container .list .item:nth-child(4) {
  border-bottom: none;
}

.workout .equipment.container .list .item .number {
  align-self: center;
  color: #41c0d3;
  font-family: "futura-pt-demi";
  font-size: 30px;
  margin-right: 35px;
}

.workout .exercises.container .list .item .number {
  font-family: "futura-pt-demi";
  font-size: 100px;
  position: absolute;
  color: rgba(91, 80, 98, 0.3);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.workout .exercises.container .list .item {
  padding: 34px 0 34px 0;
}

.workout.no-equipment .exercises.container .list .item {
  width: 188px;
}

.workout .equipment.container .body {
  border-radius: 12px;
  border: solid 1px #64596b;
  height: 100%;
}

.workout .exercises.container .body::-webkit-scrollbar {
  width: 4px;
}

.workout .exercises.container .body::-webkit-scrollbar-track {
  border-radius: 2.5px;
  width: 4px;
  background: transparent;
}

.workout .exercises.container .body::-webkit-scrollbar-thumb {
  width: 4px;
  border-radius: 2px;
  background: #291e2e;
}

@media (max-width: 1300px) {
  .workout .exercises.container .list {
    grid-template-columns: 1fr 1fr;
  }
  .workout .container .title h3 {
    font-size: 35px;
    text-align: center;
  }
}

@media (max-width: 1290px) {
  .workout.no-equipment .container {
    height: auto;
  }
}

@media (max-width: 1060px) {
  .workout .container .list {
    flex-direction: column;
  }
  .workout .container .list .column {
    width: 100%;
  }

  .workout .equipment.container .list:last-child {
    border-bottom: none;
  }
  .workout .equipment.container .list .item:nth-child(4) {
    border-bottom: 1px solid #64596b;
  }

  .workout .container .list .first.column {
    border-right: none;
  }

  .workout .equipment.container .list .item {
    height: 80px;
  }
}

@media (max-width: 850px) {
  .workout .container .title h4 {
    font-size: 16px;
  }

  .workout {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    row-gap: 50px;
  }

  .workout.no-equipment .exercises.container .list .item {
    width: 160px;
  }
}

@media (max-width: 768px) {
  .workout.no-equipment .exercises.container .list .item {
    width: 150px;
  }
}

@media (max-width: 485px) {
  .workout .exercises.container .list .item .number {
    font-size: 72px;
  }

  .workout .exercises.container .list .item {
    padding: 20px 0 20px 0;
  }

  .workout .equipment.container .body,
  .workout .exercises.container .list {
    font-family: "futura-pt-book";
    font-size: 16px;
  }

  .workout .container .title h3 {
    font-size: 30px;
  }
}

@media (max-width: 425px) {
  .workout {
    padding: 0 20px;
  }

  .workout.no-equipment .exercises.container .list .item {
    width: 130px;
  }
}

@media (max-width: 375px) {
  .workout.no-equipment .exercises.container .list .item {
    width: 105px;
  }
}

@media (max-width: 320px) {
  .workout.no-equipment .exercises.container .list .item {
    width: 160px;
  }
}

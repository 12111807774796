.mm-line {
  display: flex;
  flex-direction: row;
  width: 100%;
  column-gap: 30px;
  height: 70px;
  justify-content: space-between;
}

.mm-line .first-col,
.mm-line .second-col {
  width: 50%;
}

.mm-line .first-col {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-family: "futura-pt-demi";
  font-size: 22.5px;
}

.mm-line .second-col {
  border-bottom: 2px solid #ddcedb;
  display: flex;
  flex-direction: row;
  align-items: self-end;
  width: 280px;
}

.mm-line .icon {
  height: 30px;
}
.mm-line p {
  padding-bottom: 3px;
}
.mm-line .icon.clock {
  padding: 0 10px 5px 0;
}

.mm-line input[type="number"] {
  border: none;
  color: #41c0d3;
  font-size: 40px;
  font-family: "futura-pt-demi";
  text-align: center;
  width: 200px;
  height: 42px;
  outline-style: none;
}

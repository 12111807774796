.goals .rm-box {
  display: flex;
  width: 274px;
  height: 327px;
  padding: 20px 30px;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  row-gap: 24px;
  flex-shrink: 0;
  flex-wrap: wrap;
  border-radius: 20px;
  border: 1px solid #ddcedb;
}

.goals .profile-name.left {
  height: 70px;
  color: #281d2d;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 0;
}

.goals .streaks-cont {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.goals .streaks-cont .streak-clmn {
  display: flex;
  flex-direction: column;
  width: 45%;
}

.goals .streaks-cont .streak-clmn .streak-circle {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  border: 1px solid #ddcedb;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.goals .streaks-cont .streak-clmn .streak-circle .icon {
  text-align: center;
  height: 40px;
  width: 40px;
}

.goals .streaks-cont .streak-name {
  padding-top: 0;
  color: #281d2d;
  font-family: "futura-pt-medium";
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;
}

.goals .profile-name.left .icon.blue,
.goals .streaks-cont .streak-clmn .streak-circle.grey .icon,
.goals .streaks-cont .streak-clmn .streak-circle.grey .profile-name,
.goals .streaks-cont .streak-clmn .streak-circle.grey .bold-sm-txt {
  color: rgba(100, 89, 107, 0.5);
}

.goals .streaks-cont .streak-clmn .streak-circle.green {
  border-color: #62bf92;
}
.goals .profile-name.left .icon.green,
.goals .streaks-cont .streak-clmn .streak-circle.green .icon,
.goals .streaks-cont .streak-clmn .streak-circle.green .profile-name,
.goals .streaks-cont .streak-clmn .streak-circle.green .bold-sm-txt {
  color: #62bf92;
}

.goals .streaks-cont .streak-clmn .streak-circle.red {
  border-color: #ef545a;
}
.goals .profile-name.left .icon.red,
.goals .streaks-cont .streak-clmn .streak-circle.red .icon,
.goals .streaks-cont .streak-clmn .streak-circle.red .profile-name,
.goals .streaks-cont .streak-clmn .streak-circle.red .bold-sm-txt {
  color: #ef545a;
}

.goals .streaks-cont .streak-clmn .streak-circle.blue {
  border-color: #41c0d3;
}

.goals .streaks-cont .streak-clmn .streak-circle.blue .icon,
.goals .streaks-cont .streak-clmn .streak-circle.blue .profile-name,
.goals .streaks-cont .streak-clmn .streak-circle.blue .bold-sm-txt {
  color: #41c0d3;
}

.goals .streaks-cont .streak-clmn-full {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: start;
}

.goals .streaks-cont .streak-clmn-full .streak-line {
  margin-bottom: 20px;
}

#main_container.foryou.explore {
  background-image: url("../shared/images/search_bg.png");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  padding: 0;
  display: grid;
  overflow-x: clip;
}

#main_container.foryou.explore .wrap-screen {
  background-color: rgba(40, 29, 45, 0.9);
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-self: stretch;
  align-self: stretch;
}

/* #main_container.foryou.explore #nav_container {
  background-color: #281d2d;
  height: 135px;
} */

.search_media_container {
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 20px 0px;
  padding-bottom: 150px;
}

.search_media_container .innerSlide {
  margin: 12px;
  height: 300px;
  width: 268px;
  cursor: pointer;
}

.search_media_container .innerSlide:hover {
  margin-top: 5px;
  margin-bottom: -5px;
  transition: margin 300ms;
}

.search_tile_container {
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 40px 40px;
  padding-top: 40px;
  padding-bottom: 180px;
}

.search_tile_container .page-tile {
  padding: 40px;
  color: #fff;
  border-radius: 25px;
  transition: margin 200ms;
}

.search_tile_container .page-tile:hover {
  margin-top: -5px;
  margin-bottom: 5px;
}

.search_tile_container .page-tile:first-child {
  background-image: linear-gradient(30deg, #38c0d5 0%, #62bf96 100%);
  -webkit-animation: fade-in-bottom 0.7s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: fade-in-bottom 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation-delay: 500ms;
}

.search_tile_container .page-tile:nth-child(2) {
  background-image: linear-gradient(30deg, #f15256 0%, #fdca47 100%);
  -webkit-animation: fade-in-bottom 0.7s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: fade-in-bottom 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation-delay: 750ms;
}
.search_tile_container .page-tile:nth-child(3) {
  background-image: linear-gradient(30deg, #281d2d 0%, #38c0d5 100%);
  -webkit-animation: fade-in-bottom 0.7s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: fade-in-bottom 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation-delay: 1000ms;
}
.search_tile_container .page-tile:nth-child(4) {
  background-image: linear-gradient(30deg, #57034a 0%, #fdca47 100%);
  -webkit-animation: fade-in-bottom 0.7s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: fade-in-bottom 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation-delay: 1250ms;
}
.search_tile_container .page-tile:nth-child(5) {
  background-image: linear-gradient(30deg, #5ec091 0%, #281d2d 100%);
  -webkit-animation: fade-in-bottom 0.7s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: fade-in-bottom 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation-delay: 1500ms;
}
.search_tile_container .page-tile:nth-child(6) {
  background-image: linear-gradient(30deg, #57034a 0%, #ddcedb 100%);
  -webkit-animation: fade-in-bottom 0.7s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: fade-in-bottom 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation-delay: 1750ms;
}
.search_tile_container .page-tile:nth-child(7) {
  background-image: linear-gradient(125deg, #38c0d5 0%, #57034a 100%);
  -webkit-animation: fade-in-bottom 0.7s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: fade-in-bottom 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation-delay: 2000ms;
}
.search_tile_container .page-tile:nth-child(8) {
  background-image: linear-gradient(30deg, #5ec091 0%, #fdca47 100%);
  -webkit-animation: fade-in-bottom 0.7s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: fade-in-bottom 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation-delay: 2250ms;
}
.search_tile_container .page-tile:nth-child(9) {
  background-image: linear-gradient(180deg, #f15256 0%, #fdca47 100%);
  -webkit-animation: fade-in-bottom 0.7s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: fade-in-bottom 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation-delay: 2500ms;
}

@-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.no-results {
  margin-top: 4.7rem;
  text-align: center;
}

.search_tile_container .page-tile .tile-title,
.no-results .tile-title {
  font-size: 2rem;
  font-family: "futura-pt-demi";
  text-transform: capitalize;
  color: #fff;
}

.search_tile_container .page-tile .tile-subtitle,
.no-results .tile-subtitle {
  font-size: 1rem;
  font-family: "futura-pt-medium";
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #fff;
}

.no-results .tile-subtitle {
  margin-top: 2.5rem;
}

@media screen and (max-width: 1460px) {
  .search_media_container {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 1200px) {
  .search_tile_container {
    grid-template-columns: 1fr 1fr;
  }
  .search_media_container {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 900px) {
  .search_media_container .innerSlide {
    height: 210px;
    width: 187px;
  }
}

@media screen and (max-width: 768px) {
  .search_tile_container {
    gap: 20px 20px;
  }

  .search_tile_container .page-tile {
    padding: 40px 20px 40px 40px;
  }

  .search_tile_container .page-tile .tile-title {
    font-size: 1.5rem;
  }

  .search_tile_container .page-tile .tile-subtitle {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 650px) {
  .search_media_container {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 580px) {
  .search_tile_container {
    grid-template-columns: 1fr;
  }

  .search_tile_container .page-tile {
    padding: 40px 20px;
  }
}

@media screen and (max-width: 425px) {
  .search_media_container .innerSlide {
    height: 147px;
    width: 130px;
  }
}

@media screen and (max-width: 375px) {
  .search_tile_container {
    padding: 40px 20px 150px 20px;
  }
}

@media screen and (max-width: 320px) {
  .search_media_container {
    grid-template-columns: 1fr;
  }
}

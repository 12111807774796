.dot_radio.iconselect {
  display: flex;
  flex-wrap: wrap;
}
.dot_radio.iconselect .input_wrap {
  display: inline-block;
  text-align: center;
  width: 35px;
  margin-right: 0px;
  color: #7C7283;
  cursor: pointer;
}
.spaced .dot_radio.iconselect .input_wrap {
  width: 45px;
}
.dot_radio.iconselect input[type="radio"] + label {
  width: 40px;
  height: 40px;
  background-size: contain;
  transition: margin 0.5s ease;
  background-color: transparent;
  border-radius: 0;
  margin: 0 0 0px 0;
}
.dot_radio.iconselect .input_wrap:hover label{
  margin: -5px 0px 5px 0px;
  background-color: transparent;
}
.dot_radio.iconselect input[type="radio"].selected + label,
.dot_radio.iconselect input[type="radio"]:checked + label {
  width: 40px;
  height: 40px;
  background-size: contain;
  background-color: transparent;
}

/*Specify icons*/
/*hydration*/
.dot_radio.hydration input[type="radio"] + label {
  background-image: url("./images/water.svg");
}
.dot_radio.hydration input[type="radio"].selected + label,
.dot_radio.hydration input[type="radio"]:checked + label,
.dot_radio.hydration input[type="radio"]:hover + label {
  background-image: url("./images/water_selected.svg");
}
.dot_radio.hydration input[type="radio"]#hydration0:checked + label {
  background-image: url("./images/water_selected_none.svg");
  background-repeat: no-repeat;
}
.dot_radio.hydration.iconselect .input_wrap:hover label{
  background-image: url("./images/water_selected.svg");
}
/*caffeine*/
.dot_radio.caffeine input[type="radio"] + label {
  background-image: url("./images/bean.svg");
}
.dot_radio.caffeine input[type="radio"].selected + label,
.dot_radio.caffeine input[type="radio"]:checked + label,
.dot_radio.caffeine input[type="radio"]:hover + label {
  background-image: url("./images/bean_selected.svg");
}
.dot_radio.caffeine input[type="radio"]#caffeine0:checked + label {
  background-image: url("./images/bean_selected_none.svg");
  background-repeat: no-repeat;
}
.dot_radio.caffeine.iconselect .input_wrap:hover label{
  background-image: url("./images/bean_selected.svg");
}

@media (max-width: 500px) {
  .dot_radio.iconselect .input_wrap,
  .spaced .dot_radio.iconselect .input_wrap {
    width: 30px;
  }
  .dot_radio.iconselect input[type="radio"] + label,
  .dot_radio.iconselect input[type="radio"].selected + label,
  .dot_radio.iconselect input[type="radio"]:checked + label {
    width: 30px;
    height: 30px;
  }
}

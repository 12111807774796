.nav_slider {
  overflow: auto;
  color: #fff;
  position: fixed;
  top: 90px;
  z-index: 49;
  transition: all 0.5s ease 0s;
}

#nav.nav_slider {
  width: 80%;
  margin: 0 7.5%;
}

.nav_slider.na {
  top: 75px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
}

.nav_slider a {
  color: white;
}

.nav_slider .swiper {
  margin: 0px;
  padding: 0;
  height: 50px;
  padding: 0px 14px;
  display: flex;
  -webkit-mask-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 1) 2%,
    rgba(0, 0, 0, 1) 98%,
    rgba(255, 255, 255, 0) 100%
  );
  mask-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 1) 2%,
    rgba(0, 0, 0, 1) 98%,
    rgba(255, 255, 255, 0) 100%
  );
}
.nav_slider .swiper-wrapper {
  display: inline-flex;
  margin: 0 auto;
  width: auto;
}

.nav_slider .swiper-slide {
  padding: 10px 25px;
  box-sizing: border-box;
  width: auto;
  font-family: "futura-pt-medium", sans-serif;
  font-weight: 500;
  font-size: 20px;
  border-radius: 25px;
  background-color: #463b4c;
  transition: background-color 0.5s ease;
  cursor: pointer;
  text-transform: capitalize;
  margin: 0px;
}

.nav_slider .swiper-slide:hover {
  background-color: #e92263;
}
.nav_slider .swiper:before,
.nav_slider .swiper:after {
  content: "";
  position: absolute;
  z-index: 10;
  width: 50px;
  top: 0;
  height: 100%;
  pointer-events: none;
}
.nav_slider .swiper:before {
  left: 0;
}
.nav_slider .swiper:after {
  right: 0;
}

.nav_slider .swiper-pagination {
  text-align: right;
}
.nav_slider .swiper-pagination-fraction,
.nav_slider .swiper-pagination-custom,
.nav_slider .swiper-horizontal > .swiper-pagination-bullets {
  top: 0px;
  right: 10%;
  bottom: auto;
  left: auto;
}
.nav_slider .swiper-pagination-bullet {
  width: 20px;
  height: 4px;
  border-radius: 2px;
  background-color: #b1abaf;
}
.nav_slider .swiper-pagination-bullet-active {
  background-color: #ea2364;
}
.nav_slider .swiper-button-prev,
.nav_slider .swiper-button-next {
  color: #fff;
  z-index: 11;
  position: fixed;
  top: 115px;
  transition: margin 300ms;
}

.nav_slider .swiper-button-next {
  right: 5%;
}
.nav_slider .swiper-button-prev {
  left: 5%;
}
.nav_slider .swiper-button-prev:hover {
  margin-left: -5px;
  padding-right: 5px;
}
.nav_slider .swiper-button-next:hover {
  margin-right: -5px;
  padding-left: 5px;
}
.report-section .nav_slider {
  width: auto;
  margin: 0 0 0 0;
  top: 200px;
}
.report-section .nav_slider .swiper-slide {
  color: #c3c2c5;
  line-height: 26px;
}
/* .report-section .nav_slider .swiper-slide.active, */
.report-section .nav_slider .swiper-slide:hover {
  color: #fff;
}

@media (max-width: 1266px) {
  #nav.nav_slider {
    width: 75%;
  }
}
@media (max-width: 650px) {
  #nav.nav_slider .swiper {
    height: 35px;
  }

  #nav.nav_slider .swiper-slide {
    padding: 8px 25px;
    font-size: 15px;
  }

  #nav.nav_slider .swiper-button-next,
  #nav.nav_slider .swiper-button-prev {
    --swiper-navigation-size: 35px;
    top: 107px;
  }
}

@media (max-width: 550px) {
  #nav.nav_slider {
    display: none;
  }
}

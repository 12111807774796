.main-background {
  background-color: #281d2d;
  width: 100vw;
  height: 100vh;
}

.media-player {
  width: 100vw;
  height: 100vh;
  position: relative;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}

.media-player.video .background-blur {
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(10px);
  display: flex;
}

.media-player .back-button {
  z-index: 5;
}

.media-player video {
  display: block;
  align-self: center;
  width: 100%;
  height: 100%;
  object-fit: contain; /* can also try cover */
}

/* :not(:root):fullscreen::backdrop {
  background-image: url("../platform/pages/images/health-report.jpg");
} */

.media-player .container-ref {
  height: 100%;
  width: 100%;
}

.heading {
  grid-area: heading;
}
.player-buttons {
  grid-area: player-buttons;
}
.action-container {
  grid-area: action-container;
}

.media-player .controls.container {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: "heading player-buttons action-container";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40%;
  background-image: linear-gradient(
    to top,
    rgb(41, 30, 46, 0.8) 20%,
    rgba(41, 30, 46, 0)
  );
  box-sizing: border-box;
  color: #fff;
  align-content: end;
  padding: 0 75px 45px 75px;
  column-gap: 100px;
}

.controls.container .heading,
.controls.container .player-buttons,
.controls.container .action-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.controls.container .heading {
  align-items: flex-start;
}
.controls.container .action-container {
  align-items: stretch;
}

.controls.container .player-buttons .top-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.controls.container .player-buttons .top-row .captions {
  display: flex;
}

.media-player .controls .play {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-content: center;
}

.media-player .controls .volume,
.mcplayer .controls .volume {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: relative;
}

.media-player .controls h3 {
  font-family: "futura-pt-demi";
  font-size: 22.5px;
  margin-bottom: 0;
}

.media-player .controls p {
  font-family: "futura-pt-book";
  font-size: 15px;
}
.media-player .controls button.player,
.fullscreen-player {
  background-color: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 40px;
  padding: 0;
  margin-bottom: 0;
}

.media-player .controls button.cc.player {
  justify-self: flex-start;
}

.media-player .controls button.player .icon,
.fullscreen-player .icon {
  margin: 0;
  transition: none;
}

.media-player .controls button.play-pause {
  font-size: 80px;
  margin: 0 10px 0 10px;
}

button.mute.player {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

button.mute.player .mute-icon {
  padding-left: 5px;
}

svg.svg-inline--fa.fa-cirlce-play,
svg.svg-inline--fa.fa-cirlce-pause {
  vertical-align: text-top;
}

.media-player .controls .mimicButton {
  font-size: 20px;
  margin-bottom: 0;
  height: 47px;
  width: 100%;
  margin: 7px 0 7px 0;
  border-radius: 25px;
  display: flex;
  justify-content: center;
} 

.media-player .controls .mimicButton{
  align-self: center;
}

.media-player .controls .mimicButton .icon {
  margin: 0 5px 0 0;
  transition: none;
}

.media-player .controls .mimicButton:hover,
.fullscreen-player:hover {
  color: #e92263;
}

.fullscreen-player:hover {
  border: none;
  background-color: transparent;
}

.media-player .controls .volume-container,
.mcplayer .controls .volume-container {
  position: absolute;
  bottom: 13px;
  right: 50px;
  border-radius: 200px;
  background-color: transparent;
  z-index: 1;
  padding: 220px 7px 22px 7px;
  transition: background-color 0.5s ease;
}

.mcplayer .controls .volume-container {
  bottom: 10px;
}

.media-player .controls .volume-container.audio,
.mcplayer .controls .volume-container.audio {
  right: 0px;
}

.media-player .controls .progress {
  display: flex;
  align-items: center;
  width: 100%;
}

.media-player .controls .progress progress[value] {
  appearance: none;
  border: none;
  width: 100%;
  height: 6px;
  background-color: rgba(255, 255, 255, 0.16);
  border-radius: 3px;
  box-shadow: 0 0.5px 1px rgba(0, 0, 0, 0.5) inset;
  color: #e92263;
  position: relative;
}

progress[value]::-webkit-progress-bar {
  background-color: rgba(255, 255, 255, 0.16);
  border-radius: 3px;
  box-shadow: 0 0.5px 1px rgba(0, 0, 0, 0.5) inset;
}

progress[value]::-webkit-progress-value {
  position: relative;
  border-radius: 3px;
  background-color: #e92263;
}

progress[value]::-moz-progress-bar {
  background-color: rgba(255, 255, 255, 0.16);
  border-radius: 3px;
  box-shadow: 0 0.5px 1px rgba(0, 0, 0, 0.5) inset;
}

progress[value]::-moz-progress-bar {
  position: relative;
  border-radius: 3px;
  background-color: #e92263;
}

.media-player .controls .progress .time-current,
.media-player .controls .progress .time-total {
  color: #fff;
  font-size: 12px;
  margin: 0 5px;
}

.media-player .controls .volume-bar,
.mcplayer .controls .volume-bar {
  bottom: 82px;
  right: 43px;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  width: 1px;
  height: 1px;
  transform: rotate(-90deg);
  transition: opacity 0.2s ease-in-out;
}

.media-player .controls .volume-container:hover,
.mcplayer .controls .volume-container:hover {
  background-color: rgba(100, 89, 107, 0.8);
}

.media-player .controls .volume-container:has(.volume-bar:focus-within),
.mcplayer .controls .volume-container:has(.volume-bar:focus-within) {
  background-color: rgba(100, 89, 107, 0.8);
  outline: var(--dark-thick-outline);
  outline-offset: var(--outline-offset);
}

.media-player .controls .volume-container:hover .volume-bar,
.media-player .controls .volume-bar:focus-within,
.mcplayer .controls .volume-container:hover .volume-bar,
.mcplayer .controls .volume-bar:focus-within {
  opacity: 1;
  pointer-events: all;
}

.media-player .controls .volume-bar:focus-within,
.mcplayer .controls .volume-bar:focus-within {
  opacity: 1;
  pointer-events: all;
}

.media-player .controls .volume-bar input[type="range"],
.mcplayer .controls .volume-bar input[type="range"] {
  width: 160px;
  height: 7px;
  -webkit-appearance: none;
  /* background-color: #64596b; */
  background: linear-gradient(
    to right,
    #e92263 0%,
    #e92263 var(--volume-percentage),
    #281d2d var(--volume-percentage),
    #281d2d 100%
  );
  border-radius: 6px;
  outline: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.media-player .controls .volume-bar input[type="range"]::-webkit-slider-thumb,
.mcplayer .controls .volume-bar input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 7px;
  height: 7px;
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
}

.media-player.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 998;
}

.subtitles-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  text-wrap: wrap;
  height: 75px;
  font-size: 30px;
  font-family: "futura-pt-book";
  color: white;
  position: fixed;
  bottom: 30px;
  width: 100%;
  background-color: rgb(41, 30, 46, 0.8);
  z-index: 10000;
}

.subtitles-container.raised {
  bottom: 180px;
}

.media-cont .mcplayer {
  position: relative;
}

.media-cont .mcplayer .subtitles-container {
  position: absolute;
}

@media (max-width: 1640px) {
  .media-player .controls .mimicButton {
    font-size: 18px;
  }
}

@media (max-width: 1590px) {
  .media-player .controls.container {
    column-gap: 75px;
  }
}

@media (max-width: 1510px) {
  .media-player .controls.container {
    column-gap: 50px;
  }
}

@media (max-width: 1410px) {
  .media-player .controls.container,
  .mcplayer .controls.container {
    column-gap: 25px;
  }
}

@media (max-width: 1300px) {
  .media-player .controls h3 {
    font-size: 18px;
  }

  .media-player .controls .volume-container {
    bottom: 4px;
    right: 40px;
    padding: 210px 3px 22px 3px;
  }

  .media-player .controls .volume-bar {
    bottom: 68px;
    right: 33px;
  }

  .media-player .controls button.player,
  .fullscreen-player {
    font-size: 30px;
  }

  .media-player .controls button.play-pause {
    font-size: 60px;
  }

  .media-player .controls .mimicButton {
    font-size: 16px;
  }
  .media-player .mute.player .mute-icon {
    padding-left: 4px;
  }
  .media-player .controls.container {
    column-gap: 25px;
  }
}

@media (max-width: 1200px) {
  .media-player video {
    align-self: flex-start;
  }

  .media-player .controls.container {
    height: 60%;
    background-image: linear-gradient(
      to top,
      rgba(41, 30, 46, 0.8) 55%,
      rgba(41, 30, 46, 0)
    );
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      "heading action-container"
      "player-buttons player-buttons";
  }
  .media-player .controls button.cc.player {
    margin-right: 0px;
  }

  .media-player .controls .volume-container {
    position: absolute;
    bottom: 19px;
    right: 50px;
    border-radius: 30px;
    background-color: transparent;
    z-index: 1;
    padding: 7px 10px 7px 220px;
    transition: background-color 0.5s ease;
  }

  .media-player .controls .volume-bar {
    transform: rotate(-180deg);
    bottom: 9px;
    right: 67px;
  }
}

@media (max-width: 1000px) {
  .media-player .controls .volume-container {
    bottom: 23px;
    right: 40px;
    padding: 3px 8px 3px 190px;
  }

  .media-player .controls .volume-bar {
    bottom: 6px;
    right: 57px;
  }
}

@media (max-width: 900px) {
  .media-player .controls .volume-container {
    bottom: 23px;
    right: 40px;
    padding: 3px 8px 3px 140px;
  }

  .media-player .controls .volume-bar input[type="range"] {
    width: 111px;
  }
}

@media (max-width: 700px) {
  .media-player .controls .volume-container {
    padding: 3px 8px 3px 80px;
  }

  .media-player .controls .volume-bar input[type="range"] {
    width: 50px;
  }
}

@media (max-width: 620px) {
  /* .media-player .controls h3 {
    font-size: 16px;
  } */

  .media-player .back-button {
    height: 40px;
    width: 40px;
  }

  .icon.return-arrow.back {
    font-size: 25px;
    user-select: none;
  }

  .media-player .controls button.player,
  .fullscreen-player {
    font-size: 25px;
  }

  .media-player .controls button.play-pause {
    font-size: 40px;
  }

  .media-player .controls .mimicButton {
    font-size: 14px;
    line-height: 14px;
  }

  .media-player .controls button.cc.player {
    align-self: flex-end;
  }

  .media-player .controls .volume-container {
    bottom: 0px;
    right: 24px;
  }

  .media-player .controls .volume-bar {
    bottom: 3px;
    right: 52px;
  }

  .media-player .controls .mimicButton {
    height: 35px;
  }

  .mcplayer .controls .mimicButton {
    align-self: flex-start;
  }

  .media-player .controls .fullscreen-player {
    align-self: flex-end;
  }

  .media-player .controls button.player,
  .fullscreen-player {
    align-self: flex-end;
  }
}

@media (max-width: 590px) {
  .media-player .controls.container {
    padding: 0 20px 45px 20px;
  }
}

@media (max-width: 490px) {
  .media-player .controls .mimicButton {
    font-size: 12px;
    line-height: 12px;
  }
}

@media (max-width: 440px) {
  /* .media-player .controls h3 {
    font-size: 14px;
  } */
  /* .media-player .controls button.player {
    font-size: 15px;
  } */

  .media-player .controls button.play-pause {
    font-size: 25px;
  }
  .media-player .controls.container {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      "heading "
      "action-container"
      "player-buttons";
  }

  .media-player .controls .volume-bar {
    bottom: 3px;
    right: 46px;
  }
}

@media (max-width: 390px) {
  .controls.container .player-buttons .top-row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .media-player .controls .volume-container.audio {
    position: initial;
    z-index: 0;
    background-color: rgba(100, 89, 107, 0.5);
  }

  .media-player .controls .volume-container.audio .volume-bar {
    opacity: 1;
  }
}

@media (max-width: 340px) {
  .media-player .controls .volume-bar {
    width: 40px;
  }
  .media-player .controls .volume-bar {
    bottom: 3px;
    right: 44px;
  }
}

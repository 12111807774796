.cal_drop_down_wrapper {
  position: absolute;
  margin: 75px 10% 0 70%;
  z-index: 5;
}

.delete_account .drop_down_title_wrapper{
  background-color: #f6f5f7;
  border-radius: 25px;
}
.delete_account .drop_down_title_wrapper .profile_title{
 color:#a81758
}
.profile_drop_down_wrapper {
  margin-top: 5px;
}

.drop_down.assessDates {
  border-radius: 26px;
  cursor: pointer;
  padding: 10px;
  height: 25px;
  width: 12em;
  border: solid 1px white;
  transition: all 0.5s ease 0s;
}
.drop_down_title_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.profile.drop_down_title_wrapper {
  justify-content: space-between;
}

.drop_down.assessDates:hover {
  margin-bottom: 5px;
  margin-top: -5px;
}

.drop_down.assessDates.closed, .drop_down.deleteAccount.closed {
  transition: all 0.5s ease 0s;
} 

.drop_down.profile.closed {
  padding: 0px;
  height: 44.5px;
  width: 100%;
}

.drop_down.assessDates.open {
  transition: all 0.5s ease 0s;
  height: auto;
  background-color: #fff;
  color: #290140;
}

.drop_down.profile.open {
  /* padding: 12px 25px; */
  transition: all 0.5s ease 0s;
  padding: 0px;
  height: 44.5px;
  width: 100%;
  position: relative;
}

.icon.fa-chevron-down.calendar,
.icon.fa-chevron-up.calendar {
  margin: 0 7px 0 7px;
  color: white;
}

.profile.icon.fa-chevron-down {
  padding: 12px 25px;
}
.profile.icon.fa-chevron-up {
  padding: 12px 25px;
}

.icon.fa-chevron-up.calendar {
  color: #e92263;
}
.icon.fa-calendar-alt {
  margin: 0 7px 0 7px;
}

.drop_down_content.dates {
  text-align: center;
}

.drop_down_title {
  font-family: "futura-pt-book";
  font-size: 1.25rem;
  margin-right: 0.8em;
}

.profile_title {
  padding: 12px 25px;
}

.drop_down.profile.closed .profile_suggestions, .drop_down.deleteAccount.closed .profile_suggestions {
  display: none;
}
.drop_down.profile.open .profile_suggestions{
  position: absolute;
  top: 50px;
  right: 0px;
  max-height: 250px;
  overflow-y: auto;

}

.drop_down.profile.open .profile_suggestions, .drop_down.deleteAccount.open .profile_suggestions {
  display: block;
  background-color: #f6f5f7;
  color: #e92263;
  border-radius: 20px;
}

.drop_down.profile.open .profile_suggestions {
  width: 100%;
}
.drop_down.deleteAccount.open .profile_suggestions {
  width: 97%;
  padding-left: 3%;
}

.drop_down.deleteAccount.open .profile_suggestions{
  margin-top: 5px;
  overflow-y: scroll;
  height: 150px;
}

.drop_down.deleteAccount.open .profile_suggestions::-webkit-scrollbar {   width: 35px; } 
.drop_down.deleteAccount.open .profile_suggestions::-webkit-scrollbar-thumb {   border: 12px solid rgba(0, 0, 0, 0);   background-clip: padding-box;   -webkit-border-radius: 20px; border-radius: 20px;  background-color: rgba(0, 0, 0, 0.15);;  }

.profile_drop_down_content {
  color: #a81758;
  padding: 12px 25px;
}

.profile_drop_down_content:hover {
  border-radius: 20px;
  background-color: #e92263;
  color: white;
}

/* .drop_down.profile.open .profile_drop_down_content {
  display: block;
  border-bottom: #290140 1px;
} */

/* @media (max-width: 850px) {
  .drop_down.assessDates {
    margin: 1% 2% 0 22em;
  }
} */
@media (max-width: 650px) {
  .cal_drop_down_wrapper {
    position: relative;
    margin: 1% 2% 0 2%;
  }
}
@media (max-width: 470px) {
  .overview.report-section .cal_drop_down_wrapper {
    margin-bottom: 15px;
  }
}

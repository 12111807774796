.support-cont {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  justify-content: center;
  padding-top: 175px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.support-frame {
  align-items: center;
  /* background: linear-gradient(
    180deg,
    rgba(100, 89, 107) 0%,
    rgb(65, 192, 211) 100%
       rgb(65, 192, 211) 100%
  ); */
  background: linear-gradient(
    30deg,
    #fdca47 0%,

    rgb(98, 191, 146) 50%,
    rgb(65, 192, 211) 100%
  );
  border-radius: 20px;
  display: inline-flex;
  flex-direction: column;
  gap: 15px;
  padding: 25px 30px;
  width: 80%;
}

.support-frame:first-child {
  -webkit-animation: fade-in-bottom 0.7s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: fade-in-bottom 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation-delay: 250ms;
}
.support-frame:nth-child(2) {
  -webkit-animation: fade-in-bottom 0.7s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: fade-in-bottom 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation-delay: 500ms;
}
.support-frame:nth-child(3) {
  -webkit-animation: fade-in-bottom 0.7s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: fade-in-bottom 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation-delay: 750ms;
}

@-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.support-frame:hover {
  /* align-items: flex-start; */
  background: linear-gradient(
    30deg,
    rgb(98, 191, 146) 0%,
    rgb(246, 147, 88) 50%,
    #fdca47 100%
  );
}

.life-ring-crisis {
  color: #ffffff;
  line-height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 25px;
}

.span {
  font-weight: 300;
}

.text-wrapper-7 {
  font-family: "futura-pt-demi";
}

.p {
  color: #ffffff;
  font-family: "futura-pt-medium";
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
  width: 250px;
}

@media screen and (max-width: 340px) {
  .support-cont {
    transform: scale(0.8);
  }
}
